import React from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/addVideo.css";

const AddVideo = () => {
  const { id } = useParams();
  return (
    <div className="videowrapper">
      <div className="innervideowrap">
        <p className="videopara"> Add Video</p>
      </div>
      <div className="videocard">
        <Link to={`/student/${id}/record-video`}>
          <div className="recordwrap">
            <img src="/images/student/record.svg" alt="record" />
            <p>Record</p>
          </div>
        </Link>
        <Link to={`/student/${id}/upload-video`}>
          <div className="recordwrap">
            <img src="/images/student/upload.svg" alt="record" />
            <p>Upload</p>
          </div>
        </Link>
      </div>
      <div className="videobtnwrap">
        <Link to={`/student/craeteAssesment/${id}`}>
          <button type="button" className="btn btn-light">
            Back
          </button>
        </Link>
        <Link to={`/student/${id}/add-video`}>
          <button type="button" className="btn btncontinue">
            Continue
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AddVideo;
