import { useEffect, useState } from "react";
import "../../styles/Summary.css";
import KnobEducator from "./KnobEducator";
import StudentDetail from "./StudentDetail";
import image1 from "../../utils/Images/rating.svg";
import image2 from "../../utils/Images/language.svg";
import image3 from "../../utils/Images/voice.svg";
import image4 from "../../utils/Images/score.svg";
import image5 from "../../utils/Images/facial.svg";
import image6 from "../../utils/Images/body.svg";
import Language from "./Language";
import Voice from "./Voice";
import Score from "./Score";
import Facial from "./Facial";
import BodyExpression from "./BodyExpression";
import {
  getColor,
  getColorByQuality,
  localDateFormat,
} from "../../utils/utils";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircularGraph from "../common/CircularGraph";

const Summary = ({ report }) => {
  const headerone = "Rating Criteria";
  const headertwo = "Language";
  const headerthree = "Voice";
  const headerfour = "Your Score Break";
  const headerfive = "Facial Expression";
  const headersix = "Body Language";

  // const [value, setValue] = useState(50);
  // console.log(typeof Math.floor(report[0]?.totalAverage).toString());

  console.log(report);

  return (
    <div className="summarycontainer">
      <div className="summaryinner"></div>
      {/* 1st div */}
      <div className="reportonecon">
        <div className="levelone">
          <p>
            Purpose: <span>Speaking</span>
          </p>
          <p>
            Level: <span>Intermediate</span>
          </p>
          {/* <p>
            Assessment Name:<span>Ranjeet</span>
          </p> */}

          <p>
            Date:<span> {localDateFormat(report[0]?.created_at)}</span>
          </p>
        </div>
        <div className="leveltwo">
          <div className="cardwrap">
            <div className="aivideo">
              {report[0]?.videoUrl && (
                <video
                  width="320"
                  height="240"
                  controls
                  style={{ borderRadius: "6%" }}
                >
                  <source src={report[0]?.videoUrl} />
                  Your browser does not support the video tag.
                </video>
              )}
              {/* <img
                className="studentimg"
                src="/images/student/studentphoto.svg"
                alt="student"
              /> */}
            </div>
            <div className="outerdiv">
              <p className="desc">
                You are beginning to demonstrate your p5 Skills, Deepanshu.
                Targeted Practice of the lessons learned will lead to
                improvement.
              </p>
              <div className="resemble">
                <div className="teachcon">
                  <img
                    className="teachimg"
                    src={
                      report[0]?.imageUrl || "/images/icons/profilePicture.png"
                    }
                  />
                  <p className="resem">Resembles</p>
                </div>
                <p className="detail">
                  You are beginning to demonstrate your p5 Skills, Deepanshu.
                  Targeted Practice of the lessons learned will lead to
                  improvement.
                </p>
              </div>
            </div>
          </div>
          <div className="range">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularGraph value={Math.floor(report[0]?.totalAverage)} />
            </div>
          </div>
        </div>
      </div>
      {/* 2nd div */}
      <div className="cardknob">
        <div className="avgcon">
          <div className="avginner">
            <p>Your Average</p>
          </div>
          <div className="knobtwo">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularGraph value={Math.floor(report[0]?.totalAverage)} />
            </div>
          </div>
        </div>

        <div className="avgcon">
          <div className="avginner">
            <p>Industry Average</p>
          </div>
          <div className="knobtwo">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularGraph value={report[0]?.aiReport.industry_avg} />
            </div>
          </div>
        </div>

        <div className="avgcon">
          <div className="avginner">
            <p>Video Quality</p>
          </div>
          <div className="knobtwo">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularProgressbar
                value={report[0]?.aiReport?.voice.energy || 0}
                maxValue={100}
                text={`${
                  report[0]?.aiReport?.vid_qlty[0]?.toUpperCase() || "NA"
                }`}
                styles={buildStyles({
                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Colors
                  pathColor: getColorByQuality(
                    report[0]?.aiReport?.vid_qlty[0]
                  ),
                  textColor: getColorByQuality(
                    report[0]?.aiReport?.vid_qlty[0]
                  ),
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </div>
          </div>
        </div>
        <div className="avgcon">
          <div className="avginner">
            <p>Video Length</p>
          </div>
          <div className="knobtwo">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularGraph value={report[0]?.aiReport.duration} />
            </div>
          </div>
        </div>
        <div className="avgcon">
          <div className="avginner">
            <p>Public Vote</p>
          </div>
          <div className="knobtwo">
            <div style={{ width: "200px", height: "200px" }}>
              <CircularGraph value={0} />
            </div>
          </div>
        </div>
      </div>
      {/* 5th div */}
      <div className="repwrap">
        <p className="repara reparatwo">Your Report Criterias</p>
        <img
          className="studyimg"
          src="/images/student/studying.png"
          alt="study"
        />
        <img className="line" src="/images/student/grey.png" alt="lines" />
        <div
          className="introimg"
          style={{
            background: getColor(
              Math.floor(report[0]?.intro_outro?.speech_percentage_start) || 0
            ),
          }}
        >
          <img src="/images/student/Intro.svg" />
        </div>
        <p className="intropara"> INTRO</p>

        <div
          className="bodyimg"
          style={{
            background: getColor(
              Math.floor(
                report[0]?.aiReport?.body_language?.body_movement_percentage
              ) || 0
            ),
          }}
        >
          <img src="/images/student/Bodylanguage.svg" />
        </div>
        <p className="bodypara"> BODY LANGUAGE</p>

        <div
          className="facialimg"
          style={{
            background: getColor(
              Math.floor(report[0]?.aiReport?.emotion?.average) || 0
            ),
          }}
        >
          <img src="/images/student/Facialexpression.svg" />
        </div>
        <p className="facialpara"> FACIAL EXPRESSION</p>

        <div
          className="langimg"
          style={{
            background: getColor(
              Math.floor(100 - report[0]?.aiReport?.language?.grammar_errors)
            ),
          }}
        >
          <img src="/images/student/Language.svg" />
        </div>
        <p className="languagepara"> LANGUAGE</p>

        <div
          className="voiceimg"
          style={{
            background: getColor(Math.floor(report[0]?.aiReport?.voice.energy)),
          }}
        >
          <img src="/images/student/Voice.svg" />
        </div>
        <p className="voicepara"> VOICE</p>

        <div
          className="outroimg"
          style={{
            background: getColor(
              Math.floor(report[0]?.intro_outro?.speech_percentage_end) || 0
            ),
          }}
        >
          <img src="/images/student/Outro.svg" />
        </div>
        <p className="outropara"> OUTRO</p>
      </div>
      {/* 3rd div */}
      <div className="levelwrapper">
        <div className="levelthree">
          <div className="innerintro">
            <p>Intro</p>
          </div>
          <p className="innerintrodesc">
            You have tried to make a report between you and your listeners.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={Math.floor(
                report[0]?.aiReport?.intro_outro?.speech_percentage_start || 0
              )}
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Body Language</p>
          </div>
          <p className="innerintrodesc">
            Needs tremendous improvement in this section.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={
                Math.floor(
                  report[0]?.aiReport?.body_language?.body_movement_percentage
                ) || 0
              }
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Facial Expression</p>
          </div>
          <p className="innerintrodesc">
            Facial expressions are important feature of any presentation, you
            need to work in this regard.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={Math.floor(report[0]?.aiReport?.emotion?.average) || 0}
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Language</p>
          </div>
          <p className="innerintrodesc">
            The level of the language should be according to the level of the
            people's understanding present in the room.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={Math.floor(
                100 - report[0]?.aiReport?.language?.grammar_errors || 0
              )}
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Voice</p>
          </div>
          <p className="innerintrodesc">
            You have a persuasive and a powerful voice tone throughout your
            presentation.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={Math.floor(report[0]?.aiReport?.voice?.voice_avg) || 0}
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Presence</p>
          </div>
          <p className="innerintrodesc">
            You really need to improve in this part
          </p>
          <div className="knobthree">
            <CircularGraph
              value={Math.floor(report[0]?.aiReport?.presence) || 0}
            />
          </div>
        </div>
        <div className="levelthree">
          <div className="innerintro">
            <p>Outro</p>
          </div>
          <p className="innerintrodesc">
            Moderate ay of concluding spotted. Work in this regard.
          </p>
          <div className="knobthree">
            <CircularGraph
              value={
                Math.floor(
                  report[0]?.aiReport.intro_outro?.speech_percentage_end
                ) || 0
              }
            />
          </div>
        </div>
      </div>
      {/* 4th div */}
      <div className="cardContainer">
        <StudentDetail headerValue={headerone} image1={image1} />
        <Language headerValue={headertwo} image1={image2} />
        <Voice headerValue={headerthree} image1={image2} />
        <Score headerValue={headerfour} image1={image4} />
        <Facial headerValue={headerfive} image1={image5} />
        <BodyExpression headerValue={headersix} image1={image6} />
      </div>
    </div>
  );
};

export default Summary;
