export const indianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Goa",
  "Gujurat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "NCT of Delhi",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];

export const galleryImages = [
  { id: 11, url: "/images/galleryImages/gallery12.jpg" },
  { id: 10, url: "/images/galleryImages/gallery11.jpg" },
  { id: 8, url: "/images/galleryImages/gallery9.jpg" },
  { id: 9, url: "/images/galleryImages/gallery10.jpg" },
  { id: 12, url: "/images/galleryImages/gallery13.jpg" },
  { id: 2, url: "/images/galleryImages/gallery3.jpg" },
  { id: 7, url: "/images/galleryImages/gallery8.jpg" },
  { id: 13, url: "/images/galleryImages/gallery14.jpg" },
  { id: 14, url: "/images/galleryImages/gallery15.jpg" },
  { id: 3, url: "/images/galleryImages/gallery4.jpg" },
  { id: 4, url: "/images/galleryImages/gallery5.jpg" },
  { id: 0, url: "/images/galleryImages/gallery1.jpg" },
  { id: 1, url: "/images/galleryImages/gallery2.jpg" },
  { id: 5, url: "/images/galleryImages/gallery6.jpg" },
  { id: 6, url: "/images/galleryImages/gallery7.jpg" },
  { id: 15, url: "/images/galleryImages/gallery16.jpg" },
  { id: 16, url: "/images/galleryImages/gallery17.jpg" },
  { id: 17, url: "/images/galleryImages/gallery18.jpg" },
  { id: 18, url: "/images/galleryImages/gallery19.jpg" },
  { id: 19, url: "/images/galleryImages/gallery20.jpg" },
  { id: 20, url: "/images/galleryImages/gallery21.jpg" },
  { id: 21, url: "/images/galleryImages/gallery22.jpg" },
  { id: 22, url: "/images/galleryImages/gallery23.jpg" },
];
