import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../Loader";

const StudentCourseTab = ({
  course,
  tab,
  setTab,
  index,
  setSingleCourse,
  setIsError,
  disableTab,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const [content, setContent] = useState([]);
  const { getCourseDetail } = useSelector((state) => state);

  async function callApi() {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://developers.teachable.com/v1/courses/${id}/lectures/${course}`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            ApiKey: `HX6GGtrLCIkmpPXUye4xgqzHndxC21oe`,
          },
        }
      );
      const processedData = await response.json();
      if (response.status !== 200) {
        // setError(true);
      }
      setContent(processedData.lecture);
      tab === index && setSingleCourse(processedData.lecture);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      return error.message;
    }
  }

  // Getting Lecture here
  useEffect(() => {
    getCourseDetail.success && callApi();
  }, [getCourseDetail.success]);

  return (
    <div
      onClick={() => {
        !disableTab && setTab(index);
        !disableTab && setSingleCourse(content);
      }}
      className={`tab ${tab === index ? "active" : ""} ${
        disableTab && tab < index ? "disabledTab" : ""
      }`}
    >
      <div className="innercirc"></div>
      <div className="textwrap">
        <div>
          {isLoading ? <Loader /> : <p className="inpara">{content?.name}</p>}
        </div>
        {/* <div className="pdfwrap">
          <img src="/images/student/text.svg" alt="quiz" className="icons" />
          <p className="inpara">Text</p>
        </div> */}
      </div>
    </div>
  );
};

export default memo(StudentCourseTab);

//   switch (content?.attachments?.kind) {
//     case "text":
//       return (
//         <div
//           onClick={() => setTab(index)}
//           className={`tab ${tab === index && "active"}`}
//         >
//           <div className="innercirc"></div>
//           <div className="textwrap">
//             <div>
//               <p className="inpara">
//                 1. English in Mongolian & Multilingual Contexts
//               </p>
//             </div>
//             <div className="pdfwrap">
//               <img
//                 src="/images/student/text.svg"
//                 alt="quiz"
//                 className="icons"
//               />
//               <p className="inpara">Text</p>
//             </div>
//           </div>
//         </div>
//       );
//       break;

//     case "survey":
//       return (
//         <div
//           onClick={() => setTab(1)}
//           className={`tab ${tab === 1 && "active"}`}
//         >
//           <div className="innercirc"></div>
//           <div className="textwrap">
//             <div>
//               <p className="inpara">
//                 2. Why do I want to Communicate in English?
//               </p>
//             </div>
//             <div className="pdfwrap">
//               <img
//                 src="/images/student/survey.svg"
//                 alt="quiz"
//                 className="icons"
//               />
//               <p className="inpara">SURVEY 2 QUESTIONS</p>
//             </div>
//           </div>
//         </div>
//       );
//       break;

//     case "pdf":
//       return (
//         <div
//           onClick={() => setTab(2)}
//           className={`tab ${tab === 2 && "active"}`}
//         >
//           <div className="innercirc"></div>
//           <div className="textwrap">
//             <div>
//               <p className="inpara">3. Reading Skills Strategy Summary</p>
//             </div>
//             <div className="pdfwrap">
//               <img
//                 src="/images/student/pdfone.svg"
//                 alt="quiz"
//                 className="icons"
//               />
//               <p className="inpara">Pdf</p>
//             </div>
//           </div>
//         </div>
//       );
//       break;

//     case "mp4":
//       return (
//         <div
//           onClick={() => setTab(3)}
//           className={`tab ${tab === 3 && "active"}`}
//         >
//           <div className="innercirc"></div>
//           <div className="textwrap">
//             <div>
//               <p className="inpara">4. SHIVE STEPS - LANGUAGE LAB</p>
//             </div>
//             <div className="pdfwrap">
//               <img
//                 src="/images/student/videocourse.svg"
//                 alt="quiz"
//                 className="icons"
//               />
//               <p className="inpara">Video</p>
//             </div>
//           </div>
//         </div>
//       );
//       break;
//     case "mp4":
//       return (
//         <div
//           onClick={() => setTab(4)}
//           className={`tab ${tab === 4 && "active"}`}
//         >
//           <div className="innercirc"></div>
//           <div className="textwrap">
//             <div>
//               <p className="inpara">4. Informative Speaking</p>
//             </div>
//             <div className="pdfwrap">
//               <img
//                 src="/images/student/quiz.svg"
//                 alt="quiz"
//                 className="icons"
//               />
//               <p className="inpara">Quiz</p>
//             </div>
//           </div>
//         </div>
//       );
//       break;
//     default:
//       return null;
//       break;
//   }
