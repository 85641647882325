import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { removeUserSession } from "../../../utils/utils";

export const getDepartmentsAction = createAsyncThunk(
  "getDepartments",
  async (token, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/vol/department`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            "x-access-token": `${token}`,
          },
        }
      );
      const processedData = await response.json();
      if (response.status === 401) {
        removeUserSession();
        localStorage.removeItem("departments");
        return rejectWithValue(processedData);
      } else if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const getDepartmentsSlice = createSlice({
  name: "getDepartments",
  initialState: {
    departments: [],
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // Get Users Request Handling
    [getDepartmentsAction.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },

    [getDepartmentsAction.fulfilled]: (state, { payload }) => {
      state.departments = payload.data.dataItems;
      state.loading = false;
      state.success = true;
      localStorage.setItem(
        "departments",
        JSON.stringify(payload.data.dataItems)
      );
    },

    [getDepartmentsAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
      console.log("Error : ", payload);
    },
  },
});

export default getDepartmentsSlice.reducer;
