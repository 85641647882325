import ReactECharts from "echarts-for-react";

export default function PieChart() {
  const option = {
    // color: [
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#1158E2"
    //         },
    //         {
    //             offset: 1,
    //             color: "#42B5F2"
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#BC1FD7",
    //         },
    //         {
    //             offset: 1,
    //             color: "#7F1DD1",
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#E8A618",
    //         },
    //         {
    //             offset: 1,
    //             color: "#EB6B36",
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#D131BE",
    //         },
    //         {
    //             offset: 1,
    //             color: "#BF1575",
    //         }
    //     ]),
    // ],
    // title: {
    //   text: 'Referer of a Website',
    //   subtext: 'Fake Data',
    //   left: 'center'
    // },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: [
          { value: 1048, name: "Above 80" },
          { value: 735, name: "Between 61 and 80" },
          { value: 580, name: "Between 31 and 60" },
          { value: 484, name: "Below 30" },
          // { value: 300, name: "Video Ads" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    // series: [
    //     {
    //         name: "Item",
    //         type: "pie",
    //         radius: ["60%", "80%"],
    //         avoidLabelOverlap: false,
    //         itemStyle : {
    //             borderRadius: 50,
    //             borderColor: "black",
    //             borderWidth: 5,
    //         },
    //         label: {
    //             show: false,
    //             position: "center"
    //         },
    //         emphasis : {
    //             label: {
    //                 show: true,
    //                 fontSize: 20,
    //                 fontWeight: "bold"
    //             }
    //         },
    //         data: [
    //             { value: 2190, name: "Search Engine" },
    //             { value: 735, name: "Direct" },
    //             { value: 580, name: "Email" },
    //             { value: 484, name: "Union Ads" },
    //         ]
    //     }
    // ]
  };
  return (
    <div className="pieinner">
      <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
    </div>
  );
}
