import React from "react";
import "../../styles/findingWrap.css";
import LineChart from "../educator/LineChart";
import KnobEducator from "./KnobEducator";
import { getColor } from "../../utils/utils";

import CircularGraph from "../common/CircularGraph";

const Findings = ({ report }) => {
  // console.log(report);
  return (
    <div className="findingwrap">
      <div className="findinginner"></div>
      <div className="outsidefre">
        <div className="freqwrap">
          <div className="freqhead">
            <div className="freqinner">
              <p className="freqpara">Frequently Used Words</p>
            </div>
            <div className="innerbodyfreq">
              <div className="lanvide">
                {report[0]?.aiReport?.language?.top_words?.map(
                  (word, index) => {
                    return <p className="langfre">{word}</p>;
                    {
                      /* <p className="videofre">video</p> */
                    }
                  }
                )}
              </div>

              {(report[0]?.aiReport?.language?.top_words?.length === 0 ||
                !report[0]?.aiReport?.language?.top_words) && (
                <div className="lanvide">
                  <p className="langfre">No Word</p>
                </div>
              )}
              {/* <div className="lanvide">
                <p className="littlefre">little</p>
                <p className="tellfre">tell</p>
                <p className="cafefre">cafe</p>
              </div>
              <div className="lanvide">
                <p className="lordfre">lord</p>
                <p className="champfre">champlain</p>
              </div> */}
            </div>
          </div>
          <div className="freqhead">
            <div className="freqinner">
              <p className="freqpara">Filter Words Used</p>
            </div>
            <div className="innerbodyfreq">
              {report[0]?.aiReport?.language?.filler_words?.length == "0"
                ? "No Word"
                : "Error"}

              {/* Uncomment this code after backend update to array */}

              {/* {report[0]?.aiReport?.language?.filler_words?.map(
                (word, index) => {
                  return (
                    <div className="lanvide">
                      <p className="langfre">{word}</p>
                    </div>
                  );
                }
              )} */}
              {/* {(report[0]?.aiReport?.language?.filler_words?.length === 0 ||
                !report[0]?.aiReport?.language?.filler_words) && (
              )} */}
              {/* <div className="lanvide">
                <p className="langfre">little</p>
                <p className="videofre">a</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="outsidefre">
        <div className="freqwrap">
          <div className="freqhead">
            <div className="freqinner">
              <p className="freqpara">Words per minute</p>
            </div>
            <div className="innerbodyfreq">
              <div className="circular-graph">
                <CircularGraph value={report[0]?.voice?.speech_rate} />
              </div>
            </div>
          </div>
          <div className="freqhead">
            <div className="freqinner">
              <p className="freqpara">voice energy level</p>
            </div>
            <div className="innerbodyfreq">
              <div className="circular-graph">
                <CircularGraph value={Math.floor(report[0]?.voice?.energy)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sentimentwrap">
        <div className="sentiinner">
          <p className="sentipara">Sentiment Over Time</p>
        </div>
        <div className="chartwrap">
          <LineChart
            data={report[0]?.aiReport?.neutral_percentages}
            videoLength={report[0]?.aiReport?.duration}
          />
        </div>
      </div>
    </div>
  );
};

export default Findings;
