import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const SUPPORTED_FORMATS = ["jpg", "jpeg", "png"];
const csvFormat = ["csv"];

const MAX_FILE_SIZE = 2 * 1024 * 1024; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "webp"],
  csvFormat: ["csv"],
  video: ["mp4"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

// Login Schema

export const loginSchema = Yup.object({
  email: Yup.string()
    .trim("The Email/Phone number cannot include space(s) at start and end.")
    .strict(true)
    .required("Please enter Email/Phone Number.")
    .matches(emailRegex, "Please enter your valid email"),

  password: Yup.string()
    .trim("Password cannot include start and end spaces")
    .strict(true)
    .required("Please enter password.")
    .min(6, "Password must be atleast 6 characters long."),
});

export const assessmentSchema = Yup.object({
  name: Yup.string().required("Please enter assessment name."),
  responseType: Yup.string().required(
    "Please select assessment response type."
  ),
  purpose: Yup.string().required("Please select purpose type."),
  aiLevel: Yup.string().required("Please select AI level."),
  // statusType: Yup.string().required("Please select assessment status type."),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Please enter your old password."),
  newPassword: Yup.string().required("Please enter your new password."),
  // .notOneOf(
  //   [Yup.ref("oldPassword"), null],
  //   "New password can't be same as old password."
  // ),
  confirmPassword: Yup.string()
    .required("Please Confirm new password.")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

// Create Assessment Schemas
export const assessmentType = Yup.object({
  assessmentType: Yup.number().required("Please select assessment type."),
});

export const assessmentDetailsSchema = Yup.object({
  assessmentName: Yup.string().required("Please enter assessment name."),
  // assessmentDesc: Yup.string().required("Please enter assessment description."),
  startDate: Yup.date().required("Start date is required field."),
  endDate: Yup.date().required("End date is required field."),
  assessmentPurpose: Yup.string().required("Please select assessment purpose."),
  aiLevel: Yup.string().required("Please select AI level"),
  assessmentResponseType: Yup.string().required("Please select response type"),
}).shape({
  startDate: Yup.date().required("Please enter start date."),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .required("Please enter end date."),
});

export const questionSchema = Yup.object().shape({
  questions: Yup.array()
    .min(1)
    .of(Yup.string().required("There must be atleast One Question."))
    .required("There must be atleast One Question."),
});

export const participantsSchema = Yup.object().shape({
  participants: Yup.array()
    .min(1)
    .of(Yup.string().required("There must be atleast One Participants."))
    .required("There must be atleast One Participants."),
});

export const levelSchema = Yup.object({
  level: Yup.string().required("Please choose a level."),
});

// export const weightageSchema = Yup.object({
//   intro :
// })

// Add Student Schema
export const addStudentSchema = Yup.object({
  fname: Yup.string().required("Please enter first name."),
  // lname:  Yup.string().required("Please enter lname name."),
  email: Yup.string()
    .trim("The email name cannot include space(s) at start and end.")
    .strict(true)
    .required("Please enter email address")
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Please enter your valid email."
    ),

  mnumber: Yup.string()
    .trim("Phone number cannot include start and end spaces")
    .strict(true)
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be atleast 10 characters long")
    .max(13, "Phone number must be less than 13 characters.")
    .required("Please enter your number."),
  gender: Yup.string().required("Please select user's gender."),
  dob: Yup.string()
    .test("dob", "Date of birth cannot be of future.", function (value, ctx) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getTime() > dob.getTime();
      return !valid ? ctx.createError() : valid;
    })
    .required("Please select date of birth."),
  departmentName: Yup.string().required("Please select department."),
  // country: Yup.string().required("Please enter country's name."),
  // state: Yup.string().required("Please enter user's state."),
  // city: Yup.string().required("Please enter user's city."),
  pincode: Yup.string().required("Please enter user's pincode."),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", (value) => {
      if (!value) {
        return true;
      } else {
        return isValidFileType(value && value.name.toLowerCase(), "image");
      }
    })
    .test("is-valid-size", "Max allowed size is 2MB", (value) => {
      if (!value) {
        return true;
      } else {
        return value && value.size <= MAX_FILE_SIZE;
      }
    }),
});

export const updateStudentSchema = Yup.object({
  fname: Yup.string().required("Please enter first name."),
  // lname:  Yup.string().required("Please enter lname name."),
  email: Yup.string()
    .trim("The email name cannot include space(s) at start and end.")
    .strict(true)
    .required("Please enter email address")
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Please enter your valid email."
    ),

  mnumber: Yup.string()
    .trim("Phone number cannot include start and end spaces")
    .strict(true)
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be atleast 10 characters long")
    .max(13, "Phone number must be less than 13 characters.")
    .required("Please enter your number."),
  gender: Yup.string().required("Please select user's gender."),
  // dob: Yup.string().required("Please enter user's age."),
  // department: Yup.string().required("Please select department."),
  // country: Yup.string().required("Please enter country's name."),
  // state: Yup.string().required("Please enter user's state."),
  // city: Yup.string().required("Please enter user's city."),
  // pincode: Yup.string().required("Please enter user's pincode."),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", (value) => {
      if (!value) {
        return true;
      } else {
        return isValidFileType(value && value.name.toLowerCase(), "image");
      }
    })
    .test("is-valid-size", "Max allowed size is 2MB", (value) => {
      if (!value) {
        return true;
      } else {
        return value && value.size <= MAX_FILE_SIZE;
      }
    }),
});

export const addMultipleUserSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("Please select csv file.")
    .test("is-valid-type", "Not a valid file type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "csvFormat")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 2MB",
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
});

export const addQuestionSchema = Yup.object({
  questionName: Yup.string()
    .max(200, "The question is too long.")
    .required("Please enter question."),
  departments: Yup.string().required("Please select department."),
  level: Yup.string().required("Please select level."),
}).shape({
  questionImgUrl: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 600KB",
      (value) => value && value.size <= 600 * 1024
    ),
});

export const contactUsSchema = Yup.object({
  fullName: Yup.string().required("Please enter your full name."),
  email: Yup.string()
    .email("Please enter valid email.")
    .required("Please enter your email."),
  mnumber: Yup.string()
    .trim("Phone number cannot include start and end spaces")
    .strict(true)
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be atleast 10 characters long")
    .max(13, "Phone number must be less than 13 characters.")
    .required("Please enter your number."),
});

export const uploadVideoSchema = Yup.object({}).shape({
  video: Yup.mixed()
    .required("Please select a video.")
    .test("is-valid-type", "Not a valid video type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "video")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 20MB",
      (value) => value && value.size <= 20 * 1024 * 1024
    ),
});
