import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteQuestionAction } from "../../redux/slice/question/deleteQuestionSlice";
import { getToken } from "../../utils/utils";

const QuestionCard = ({ questionName, level, questionImgUrl, id }) => {
  const token = getToken();
  const dispatch = useDispatch();

  const handleDelete = () => {
    window.confirm("Are you sure?") &&
      dispatch(deleteQuestionAction({ id, token }));
  };
  let levelColor;
  if (level == "Beginner") {
    levelColor = "#ffc200";
  } else if (level == "Intermediate") {
    levelColor = "#BE6DB7";
  } else {
    levelColor = "#C04A82";
  }
  return (
    <Card className="qnwholecard">
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={questionImgUrl}
          alt="questionbank"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary" className="qnpara" sx={{wordWrap:"break-word"}}>
            {questionName}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <div className="deleteicon" onClick={handleDelete}>
          <img src="/images/educator/delete.svg" />
        </div>
      </CardActions>
      <div className="level" style={{ backgroundColor: levelColor }}>
        <p>{level}</p>
      </div>
    </Card>
  );
};

export default QuestionCard;


// .qncard {
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   column-gap: 30px;
//   padding: 15px 84px 15px 90px;
//   justify-content: space-between;
// }
// .qncard::after {
//   content: "";
//   width: 100%;
//   /* justify-content: flex-start; */
// } 