import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import AddQuestionModal from "./AddQuestionModal";
import useCheckSize from "../../../hooks/useCheckSize";
import Paginate from "../../common/Paginate";
import { getQuestionsAction } from "../../../redux/slice/question/getQuestionsSlice";
import { getSerielNumber, getToken } from "../../../utils/utils";

const AddQuestions = ({
  handleChange,
  errors,
  touched,
  handleBlur,
  values,
}) => {
  const [questionsList, setQuestionsList] = useState({});
  const { getQuestions } = useSelector((state) => state);
  const [pageNumber, setPageNumber] = useState(0);

  const dispatch = useDispatch();
  const size = useCheckSize();

  useEffect(() => {
    dispatch(getQuestionsAction({ token: getToken(), pageNumber }));
  }, [pageNumber]);

  useEffect(() => {
    setQuestionsList(getQuestions.questions);
  }, [getQuestions.success]);

  return (
    <div className="add-questions">
      <div className="table-container">
        <table className="table table-striped table-hover" role="group">
          <thead className="table-light">
            <tr>
              <th scope="col"></th>
              <th scope="col">S.No</th>
              <th scope="col">Question</th>
              {/* <th scope="col">Question Type</th> */}
              <th scope="col">Level</th>
              {size > 768 ? (
                <th scope="col">Question Preview</th>
              ) : (
                <th scope="col">Q.Preview</th>
              )}

              {/* <th scope="col">Duration</th> */}
            </tr>
          </thead>

          {getQuestions.loading ? (
            <Loader />
          ) : (
            questionsList?.dataItems?.map((question, index) => {
              return (
                <tbody>
                  <tr className="align-middle" key={index}>
                    <th scope="row">
                      <input
                        type="checkbox"
                        name="questions"
                        id=""
                        checked={
                          values.questions?.find((val) => {
                            return val == question.id;
                          }) == question.id
                        }
                        value={question.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </th>
                    <th scope="row">{getSerielNumber(index, pageNumber)}</th>
                    <td style={{ width: "30%" }}>{question.questionName}</td>
                    {/* <td>Video</td> */}
                    <td>{question.level}</td>
                    <td>
                      <img
                        src={question.questionImgUrl}
                        alt=""
                        className="preview"
                      />
                    </td>
                    {/* <td>
                      <img
                        src="/images/educator/Clock icon.png"
                        alt=""
                        className="duration"
                      />
                      30 Seconds
                    </td> */}
                  </tr>
                </tbody>
              );
            })
          )}
        </table>
      </div>
      <Paginate
        totalPages={questionsList?.totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  );
};

export default AddQuestions;
