import React from "react";

const LevelForm = ({ handleChange, handleBlur, values }) => {
  return (
    <div className="level-form" role="group">
      <div className="input">
        <input
          type="radio"
          name="level"
          id="basic"
          value="0"
          checked={values.level === "0"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor="basic">Beginner</label>
      </div>

      <div className="input">
        <input
          type="radio"
          name="level"
          id="intermediate"
          checked={values.level === "1"}
          value="1"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor="intermediate">Intermediate</label>
      </div>

      <div className="input">
        <input
          type="radio"
          name="level"
          id="expert"
          checked={values.level === "2"}
          value="2"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor="expert">Expert</label>
      </div>
    </div>
  );
};

export default LevelForm;
