import React from "react";

const MainContextForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="intro-form">
      <div className="upper-part">
        <div className="left">
          {/* Voice */}
          <div className="input">
            <input type="checkbox" name="voice" id="voice" />
            <label htmlFor="voice">Voice</label>
            <div className="radios">
              <input
                type="radio"
                name="voiceContext"
                id="pace"
                checked={values.voiceContext === "pace"}
                value="pace"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="pace">Pace</label>
              <br />
              <input
                type="radio"
                name="voiceContext"
                id="quality"
                checked={values.voiceContext === "quality"}
                value="quality"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="quality">Quality</label>
            </div>
          </div>
        </div>

        {/* Environment Context */}
        <div className="right">
          <div className="input">
            <input type="checkbox" name="environmentContext" id="environment" />
            <label htmlFor="environmentContext">Environment</label>
            <div className="radios">
              <input
                type="radio"
                name="environmentContext"
                id="clothing"
                checked={values.environmentContext === "clothing"}
                value="clothing"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="clothing">Clothing</label>
              <br />
              <input
                type="radio"
                name="environmentContext"
                id="objectDetection"
                checked={values.environmentContext === "objectDetection"}
                value="objectDetection"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="objectDetection">Object Detection</label>
            </div>
          </div>
        </div>
      </div>

      {/* Body Language */}
      <div className="upper-part">
        <div className="left">
          <div className="input">
            <input type="checkbox" name="greeting" id="greeting" />
            <label htmlFor="greeting">Body Language</label>
            <div className="radios">
              <input
                type="radio"
                name="bodyLangaugeContext"
                id="posture"
                checked={values.bodyLangaugeContext === "posture"}
                value="posture"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="posture">Posture</label>
              <br />
              <input
                type="radio"
                name="bodyLangaugeContext"
                id="greetingWord"
                checked={values.bodyLangaugeContext === "greetingWord"}
                value="greetingWord"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="greetingWord">Greeting Word</label>
              <br />
              <input
                type="radio"
                name="bodyLangaugeContext"
                id="movement"
                checked={values.bodyLangaugeContext === "movement"}
                value="movement"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="movement">Movement</label>
            </div>
          </div>
        </div>

        {/* Facial Expression */}
        <div className="right">
          <div className="input">
            <input type="checkbox" name="greeting" id="greeting" />
            <label htmlFor="greeting">Facial Expression</label>
            <div className="radios">
              <input
                type="radio"
                name="facialExpressionContext"
                id="neutral"
                checked={values.facialExpressionContext === "neutral"}
                value="neutral"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="neutral">Neutral</label>
              <br />
              <input
                type="radio"
                name="facialExpressionContext"
                id="emotions"
                checked={values.facialExpressionContext === "emotions"}
                value="emotions"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="emotions">Emotions</label>
              <br />
              <input
                type="radio"
                name="facialExpressionContext"
                id="smile"
                checked={values.facialExpressionContext === "smile"}
                value="smile"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="smile">Smile</label>
              <br />
              <input
                type="radio"
                name="facialExpressionContext"
                id="smile"
                checked={values.facialExpressionContext === "expressiveness"}
                value="expressiveness"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="expressiveness">Expressiveness</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContextForm;
