import React from 'react'

const ContactCard = ({image}) => {
    return (
        <div className="card contactbox">
            <img src={image} className="card-img-top" alt="..."/>
                <div className="card-body cardinnerbody">
                    <h5 className="card-title">For General Questions and Support</h5>
                    <p className="card-text">Support@hivesteps.com</p>
                </div>
        </div>
    )
}

export default ContactCard