import React from "react";
import VideoPreview from "../VideoPreview";
import { useState } from "react";

const PhotoCard = ({ url }) => {
  const [cartIsShown, setCartIsShown] = useState(false);
  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };
  return (
    <>
      <div className="photoimg" onClick={showCartHandler}>
        <img src={url} />

        {/* <img src='/images/student/lady.svg'/>
    <img src='/images/student/lady.svg'/>
    <img src='/images/student/lady.svg'/>
    <img src='/images/student/lady.svg'/> */}
      </div>
      {cartIsShown && (
        <VideoPreview
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
          url={url}
        />
      )}
    </>
  );
};

export default PhotoCard;
