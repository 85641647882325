import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import Signup from "./component/Signup";
import Otp from "./component/Otp";
import Dashboard from "./dashboard/Dashboard";
import Courses from "./dashboard/Courses";
import Completed from "./dashboard/Courses";
import Assessment from "./dashboard/Assessment";
import ProfileDetails from "./dashboard/ProfileDetails";
import Educator from "./dashboard/Educator";
import PrivateRoutes, {
  EducatorRoutes,
  PublicRoutes,
  StudentRoutes,
} from "./utils/ControlledRoutes";
import CourseDetail from "./dashboard/CourseDetail";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesAction } from "./redux/slice/course/getCoursesSlice";
import { Fragment, useEffect } from "react";
import { getToken, getUserLocal } from "./utils/utils";
import { getUserAction } from "./redux/slice/user/getUserSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EducatorCourseDetail from "./pages/educator/EducatorCourseDetail";
import TeacherDashboard from "./dashboard/TeacherDashboard";
import ChangePassword from "./pages/ChangePassword";
import EducatorAssessment from "./pages/educator/EducatorAssessment";
import CreateAssessment from "./pages/educator/CreateAssessment";
import QuestionBank from "./pages/educator/QuestionBank";
import AssessmentDetail from "./pages/educator/AssessmentDetail";
import AddQuestion from "./pages/educator/AddQuestion";
import ManageUser from "./pages/ManageUser";
import AddUser from "./pages/educator/AddUser";
import Department from "./pages/educator/Department";
import ManageCourses from "./pages/educator/ManageCourses";
import CoursesDetail from "./pages/educator/CoursesDetail";
import EditUser from "./pages/educator/EditUser";
import CreateAssesment from "./pages/student/CreateAssesment";
import AddVideo from "./pages/student/AddVideo";
import UploadVideo from "./pages/student/UploadVideo";
import RecordVideo from "./pages/student/RecordVideo";
import { getStudentsAction } from "./redux/slice/user/getStudentsSlice";
import Insight from "./pages/student/Insight";
import { getDepartmentsAction } from "./redux/slice/department/getDepartmentsSlice";
// import { getAllAssessmentsAction } from "./redux/slice/teacherAssessment/getAllAssessmentsSlice";
import { getQuestionsAction } from "./redux/slice/question/getQuestionsSlice";
import { getAllTeacherAsmtsAction } from "./redux/slice/teacherAssessment/getAllTeacherAsmtsSlice";
import { getPracticeTeacherAsmtsAction } from "./redux/slice/teacherAssessment/getPraticeTeacherAsmtsSlice";
import { getAssignedTeacherAsmtsAction } from "./redux/slice/teacherAssessment/getAssignedTeacherAsmtsSlice";
import StudentCourse from "./dashboard/StudentCourse";
import StudentCourseDetail from "./dashboard/StudentCourseDetail";
import { getAllReportsAction } from "./redux/slice/assessment/getAllReportsSlice";
import { getAllAIReportsAction } from "./redux/slice/aiReport/getAllAIReportSlice";
import Blog from "./component/FooterSection/Blog";
import BlogDetails from "./component/FooterSection/BlogDetails";
import ContactUs from "./component/FooterSection/ContactUs";
import Gallery from "./component/FooterSection/Gallery";
import TermCondition from "./component/FooterSection/TermCondition";
import Privacy from "./component/FooterSection/Privacy";
import EducatorLayout from "./component/educator/EducatorLayout";
import StudentEvent from "./pages/student/StudentEvent";
import CenteralLibrary from "./pages/student/CenteralLibrary";
import { participatAction } from "./redux/slice/assessment/participantSlice";
import ReportDetail from "./pages/educator/ReportDetail";
import EducatorChangePassword from "./pages/educator/EducatorChangePassword";
import StudentLayout from "./component/student/StudentLayout";
import StudentLayoutRightBar from "./component/student/StudentLayoutRightBar";

function App() {
  const {
    updateUser,
    addDepartment,
    deleteQuestion,
    addQuestion,
    addUser,
    updateCurrentUser,
    addAsmtTeacher,
    login,
    deleteDepartment,
    updateDepartment,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const currentUser = getUserLocal();
  const token = getToken();

  useEffect(() => {
    dispatch(getCoursesAction());
    if (token) {
      dispatch(getUserAction(token));
      if (currentUser?.roles[0] == "ROLE_TEACHER") {
        dispatch(getStudentsAction({ token, pageNumber: 0 }));
        dispatch(getAllTeacherAsmtsAction(token));
        dispatch(getPracticeTeacherAsmtsAction(token));
        dispatch(getAssignedTeacherAsmtsAction(token));
        dispatch(participatAction(token));
        dispatch(getDepartmentsAction(token));
        // dispatch(getAllAIReportsAction(token));
        dispatch(getQuestionsAction({ token, pageNumber: 0 }));
      } else if (currentUser?.roles[0] == "ROLE_STUDENT") {
        dispatch(getAllReportsAction({ token, pageNumber: 0 }));
      }
    }
  }, [login.success]);

  // Getting User Detail
  useEffect(() => {
    if (token) {
      updateCurrentUser.success && dispatch(getUserAction(token));

      if (currentUser?.roles[0] == "ROLE_TEACHER") {
        (updateUser.success || addUser.success) &&
          dispatch(getStudentsAction({ token, pageNumber: 0 }));

        (deleteQuestion.success || addQuestion.success) &&
          dispatch(getQuestionsAction({ token, pageNumber: 0 }));
        // dispatch(getAllTeacherAsmtsAction(token));
        addAsmtTeacher.success &&
          dispatch(getPracticeTeacherAsmtsAction(token));

        addAsmtTeacher.success &&
          dispatch(getAssignedTeacherAsmtsAction(token));
        (addDepartment.success ||
          deleteDepartment.success ||
          updateDepartment.success) &&
          dispatch(getDepartmentsAction(token));
      } else if (currentUser?.roles[0] == "ROLE_STUDENT") {
        addAsmtTeacher.success && dispatch(getAllAIReportsAction(token));
        // dispatch(getAllReportsAction({ token, pageNumber: 0 }));
      }
    }
  }, [
    token,
    updateCurrentUser.success,
    updateUser.success,
    addUser.success,
    deleteQuestion.success,
    addQuestion.success,
    addDepartment.success,
    addAsmtTeacher.success,
    deleteDepartment.success,
    updateDepartment.success,
  ]);

  return (
    <Fragment>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/details/:id/:title" element={<BlogDetails />} />
          <Route path="/contactus" element={<ContactUs />} />

          <Route element={<PublicRoutes />}>
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/otp" element={<Otp />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<StudentRoutes />}>
              <Route element={<StudentLayout />}>
                <Route element={<StudentLayoutRightBar />}>
                  <Route path="/student/dashboard" element={<Dashboard />} />
                  <Route path="/student/courses" element={<Courses />} />
                  <Route
                    path="/student/coursesdata"
                    element={<StudentCourse />}
                  />
                  <Route path="/student/event" element={<StudentEvent />} />
                  <Route
                    path="/student/centeral-library"
                    element={<CenteralLibrary />}
                  />
                  {/* <Route path="/educator/courses" element={<EducatorCourses />} /> */}
                  <Route path="/change-password" element={<ChangePassword />} />

                  <Route
                    path="/student/courses/:id/detail"
                    element={<CourseDetail />}
                  />
                  <Route
                    path="/educator/courses/:id/detail/:id"
                    element={<EducatorCourseDetail />}
                  />

                  <Route path="/student/completed" element={<Completed />} />
                  <Route path="/educator/completed" element={<Completed />} />

                  <Route path="/student/assessment" element={<Assessment />} />

                  <Route
                    path="/student/craeteAssesment/:id"
                    element={<CreateAssesment />}
                  />

                  <Route
                    path="/student/profileDetails"
                    element={<ProfileDetails />}
                  />
                </Route>

                {/* Without side bar */}
                <Route path="/student/:id/add-video" element={<AddVideo />} />
                <Route
                  path="/student/:id/upload-video"
                  element={<UploadVideo />}
                />
                <Route
                  path="/student/:id/record-video"
                  element={<RecordVideo />}
                />
                <Route
                  path="/student/craeteAssesment/:id/insight/:id2"
                  element={<Insight />}
                />
                <Route path="/student/add-video" element={<AddVideo />} />
                <Route
                  path="/student/:id/record-video"
                  element={<RecordVideo />}
                />
                <Route
                  path="/student/:id/upload-video"
                  element={<UploadVideo />}
                />
              </Route>
            </Route>

            <Route
              path="/student/coursesdata/:id/detail"
              element={<StudentCourseDetail />}
            />

            {/* Educator Routes */}
            <Route exact path="/educator" element={<EducatorLayout />}>
              <Route
                path="/educator/assessment"
                element={<EducatorAssessment />}
              />

              <Route
                path="/educator/change-password"
                element={<EducatorChangePassword />}
              />

              <Route
                path="/educator/assessment-detail/:id"
                element={<AssessmentDetail />}
              />

              <Route
                path="/educator/report-detail/:studentId/:reportId"
                element={<ReportDetail />}
              />

              <Route
                path="/educator/create-assessment"
                element={<CreateAssessment />}
              />

              <Route
                path="/educator/question-bank"
                element={<QuestionBank />}
              />
              <Route
                path="/educator/question-bank/add-question"
                element={<AddQuestion />}
              />
              <Route path="/educator/Manage" element={<ManageUser />} />
              {/* <Route path="/eductor/Manage/User" element={<ManageUseData />} /> */}
              <Route
                path="/educator/Manage/User/add-user"
                element={<AddUser />}
              />
              <Route
                path="/educator/manage/user/courses"
                element={<ManageCourses />}
              />

              <Route
                // path="/educator/manage/user/courses/:id"
                element={<CoursesDetail />}
              />

              <Route
                path="/educator/Manage/User/department"
                element={<Department />}
              />
              <Route
                path="/educator/question-bank/add-question"
                element={<AddQuestion />}
              />
              <Route path="/educator/Manage" exact element={<ManageUser />} />
              <Route
                path="/educator/Manage/User/add-user"
                element={<AddUser />}
              />
              <Route
                path="/educator/manage/user/courses"
                element={<ManageCourses />}
              />
              <Route
                path="/educator/Manage/User/edit-user/:id"
                element={<EditUser />}
              />
              <Route
                path="/educator/Manage/User/department"
                element={<Department />}
              />

              <Route
                path="/educator/profileDetails"
                element={<ProfileDetails />}
              />

              <Route element={<EducatorRoutes />}>
                <Route
                  path="/educator/dashboard"
                  element={<TeacherDashboard />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
