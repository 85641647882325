import React, { useEffect, useState } from "react";
import ParticipantsTab from "../../component/educator/ParticipantsTab";
import QuestionsTab from "../../component/educator/QuestionsTab";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserLocal, localDateFormat } from "../../utils/utils";
import { getAsmtDetailByIdAction } from "../../redux/slice/teacherAssessment/getAsmtDetailByIdSlice";

const AssessmentDetail = () => {
  const [tab, setTab] = useState(0);
  const [allAssessmentsList, setAllAssessmentsList] = useState([]);
  const currentUser = getUserLocal();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getAsmtDetailById, getAllTeacherAsmts } = useSelector(
    (state) => state
  );

  useEffect(() => {
    dispatch(getAsmtDetailByIdAction({ token: getToken(), id: id }));
  }, [getAllTeacherAsmts.success]);

  useEffect(() => {
    setAllAssessmentsList(getAsmtDetailById.assessment?.assessmentDetails);
  }, [getAsmtDetailById.success]);

  console.log(getAsmtDetailById);

  return (
    <div className="edu-right-side">
      <div className="teacherboard">
        <div className="teacherinnerbox">
          {/* <p>Ongoing Assessments</p> */}
        </div>
        <div className="asmt-detail-container">
          {/* Content Box */}
          <div className="content-box">
            <div className="content">
              <h2>{allAssessmentsList?.assessmentName}</h2>
              <p>
                <span> Date : </span>
                {/* March 09, 2023 - March 13, 2023{" "} */}
                {localDateFormat(allAssessmentsList?.startDate)} -{" "}
                {localDateFormat(allAssessmentsList?.endDate)}
              </p>
              <p>
                <span>Description : </span> {allAssessmentsList?.description}
              </p>
              <p>
                <span>Host : </span> {currentUser.email}
              </p>
              {/* <p>
          <span> Active Participants : </span> 0
        </p> */}
              <p>
                <span> Total Participants :</span>{" "}
                {getAsmtDetailById.assessment?.allStudentDetails?.length}
              </p>
            </div>

            <div className="img">
              <img src="/images/educator/asmt-detail-lady.svg" alt="" />
            </div>
          </div>
        </div>
        {/* Table Container */}
        <div className="table-container">
          <div className="table-tabs">
            <div
              className={`left center ${tab === 0 && "active"}`}
              onClick={() => setTab(0)}
            >
              PARTICIPANTS
            </div>
            <div
              className={`left center ${tab === 1 && "active"}`}
              onClick={() => setTab(1)}
            >
              QUESTIONS
            </div>
          </div>

          {tab === 0 && (
            <ParticipantsTab
              participants={getAsmtDetailById.assessment?.allStudentDetails}
            />
          )}
          {tab === 1 && (
            <QuestionsTab
              questions={getAsmtDetailById.assessment?.questionDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentDetail;
