import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashContainer from "../../dashboard/DashContainer";
import LeftBar from "../../dashboard/LeftBar";
import RightBar from "../../dashboard/RightBar";
import "../../styles/createAssesment.css";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllAIReportsAction } from "../../redux/slice/aiReport/getAllAIReportSlice";
import { getToken, localDateFormat } from "../../utils/utils";
import Loader from "../../component/Loader";

const CreateAssesment = () => {
  const token = getToken();
  const dispatch = useDispatch();
  const { getUser, toggleMenu } = useSelector((state) => state);
  const { id } = useParams();
  const { getAllAIReports } = useSelector((state) => state);
  const [reportsList, setReportsList] = useState([]);

  // useEffect(() => {
  //   getUser.success && setUserData(getUser?.userData);
  // }, [getUser.success]);

  useEffect(() => {
    if (getUser.success) {
      dispatch(
        getAllAIReportsAction({
          studentId: getUser.userData.id,
          assignmentId: id,
          token,
        })
      );
    }
  }, [getUser.success]);

  useEffect(() => {
    setReportsList(getAllAIReports?.reports.report);
  }, [getAllAIReports.success]);

  return (
    <div className="asseswrapper">
      <p className="assesmentpara">{localStorage.getItem("assesment")}</p>
      <hr />
      <div className="btnwrapper">
        <Link to="/student/assessment">
          <button type="button" className="btn btn-warning takesub">
            <img src="/images/student/back.svg" />
            Back
          </button>
        </Link>
        <Link to={`/student/${id}/add-video`}>
          <button type="button" className="btn submitbtn">
            Take Assessment
          </button>
        </Link>
      </div>
      <div className="assescard">
        {getAllAIReports.loading ? (
          <Loader />
        ) : reportsList?.length == 0 ? (
          <div className="assessment-box no-completed-assessment w-100">
            No Report Available
          </div>
        ) : (
          reportsList?.map((r, i) => {
            return (
              <div className="assesmentdetail" key={i}>
                <div>
                  <div className="percentage">
                    <p>{Math.floor(r?.totalAverage)}%</p>
                  </div>
                  <p className="datedetail">Created On:</p>
                  {/* <p className="timestamp"> 14 March, 2023</p> */}
                  <p className="timestamp"> {localDateFormat(r?.created_at)}</p>
                </div>
                <div className="detailbtn">
                  <div className="reactstar">
                    <ReactStars
                      count={5}
                      value={2}
                      size={24}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#F29924"
                      edit={false}
                    />
                    <p>(0)</p>
                  </div>
                  <Link to={`/student/craeteAssesment/${id}/insight/${r.id}`}>
                    <button type="button" className="btn btn-warning view">
                      View Insight
                    </button>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default CreateAssesment;

{
  /* <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div>
            <div className="assesmentdetail">
              <div>
                <div className="percentage">
                  <p>45%</p>
                </div>
                <p className="datedetail">Created On:</p>
                <p className="timestamp"> 14 March, 2023</p>
              </div>
              <div className="detailbtn">
                <button type="button" className="btn btn-warning view">
                  View Insight
                </button>
              </div>
            </div> */
}
