import React from "react";
import WeightagePieChartForm from "./WeightagePieChartForm";

const WeightageForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="weightage-form">
      <WeightagePieChartForm values={values} />

      <div className="range-form">
        <div className="range-input ">
          <div className="left">
            <div className="introCircle"></div>
            <span>Intro</span>
          </div>
          <div className="right intro">
            <input
              type="range"
              name="intro"
              id="intro"
              min="0"
              max="100"
              value={values.intro}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="intro">{values.intro}</label>
          </div>
        </div>
        {errors.intro && touched.intro ? (
          <div className="invalid-msg">{errors.intro}</div>
        ) : null}

        <div className="range-input ">
          <div className="left">
            <div className="mainContextCircle"></div>
            <span>Main Context</span>
          </div>
          <div className="right mainContext">
            <input
              type="range"
              name="mainContext"
              id="mainContext"
              min="0"
              max="100"
              value={values.mainContext}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="mainContext">{values.mainContext}</label>
          </div>
        </div>

        {errors.mainContext && touched.mainContext ? (
          <div className="invalid-msg">{errors.mainContext}</div>
        ) : null}
        <div className="range-input ">
          <div className="left">
            <div className="environmentCircle"></div>
            <span>Environment</span>
          </div>
          <div className="right environment">
            <input
              type="range"
              name="environment"
              id="environment"
              min="0"
              max="100"
              value={values.environment}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="environment">{values.environment}</label>
          </div>
        </div>
        {errors.environment && touched.environment ? (
          <div className="invalid-msg">{errors.environment}</div>
        ) : null}

        <div className="range-input">
          <div className="left">
            <div className="outroCircle"></div>
            <span>Outro</span>
          </div>
          <div className="right outro">
            <input
              type="range"
              name="outro"
              id="outro"
              min="0"
              max="100"
              value={values.outro}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="outro">{values.outro}</label>
          </div>
        </div>
        {errors.outro && touched.outro ? (
          <div className="invalid-msg">{errors.outro}</div>
        ) : null}
      </div>
    </div>
  );
};

export default WeightageForm;
