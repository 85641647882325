import React, { useEffect, useState, Fragment } from "react";
import "../../styles/addQuestion.css";
import { Link, useNavigate } from "react-router-dom";
import QuestionAdded from "../../component/QuestionAdded";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addQuestionSchema } from "../../utils/schema";
import { addQuestionAction } from "../../redux/slice/question/addQuestionSlice";
import { getToken } from "../../utils/utils";
import Paginate from "../../component/common/Paginate";

const AddQuestion = () => {
  const { getDepartments, addQuestion } = useSelector((state) => state);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [routeFlag, setRouteFlag] = useState(false);
  const [cartIsShown, setCartIsShown] = useState(false);
  const navigate = useNavigate();
  const token = getToken();
  const dispatch = useDispatch();

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  useEffect(() => {
    setDepartmentsList(getDepartments.departments);
    routeFlag && navigate("/educator/question-bank");
  }, [getDepartments.success, addQuestion.success, routeFlag]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      questionName: "",
      departments: "",
      questionImgUrl: "",
      level: "",
      status: "1",
    },
    validationSchema: addQuestionSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("questionName", values.questionName);
      formData.append("level", values.level);
      formData.append("questionImgUrl", values.questionImgUrl);
      formData.append("departments", values.departments);
      formData.append("status", "1");

      // showCartHandler();
      dispatch(addQuestionAction({ formData, token }));
      setRouteFlag(true);
    },
  });

  return (
    <Fragment>
      <form className="addqncontainer" onSubmit={handleSubmit}>
        <div className="innerboxqn">
          <p>Build Question</p>
        </div>
        <div className="allquestions">
          <label for="html" className="labelqn">
            Question <span className="qnrequired">*</span>{" "}
          </label>
          <input
            className="textfields addQuestionInput"
            type="text"
            placeholder="Enter question"
            name="questionName"
            value={values.questionName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.questionName && touched.questionName ? (
            <div className="invalid-msg">{errors.questionName}</div>
          ) : null}

          <label for="html" className="labelqn">
            Department <span className="qnrequired">*</span>{" "}
          </label>
          <select
            className="textfields addQuestionInput"
            id="level"
            name="departments"
            value={values.departments}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Select Department</option>

            {departmentsList.map((department, index) => {
              return (
                <option value={department.id} key={index}>
                  {department.departmentName}
                </option>
              );
            })}
          </select>
          {errors.departments && touched.departments ? (
            <div className="invalid-msg">{errors.departments}</div>
          ) : null}

          <label for="html" className="labelqn">
            Level <span className="qnrequired">*</span>{" "}
          </label>
          <select
            className="textfields addQuestionInput"
            id="level"
            name="level"
            value={values.level}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Select Level</option>
            <option className="1" value="0">
              Beginner
            </option>
            <option className="2" value="1">
              Intermediate
            </option>
            <option className="3" value="2">
              Expert
            </option>
          </select>
          {errors.level && touched.level ? (
            <div className="invalid-msg">{errors.level}</div>
          ) : null}

          <div className="uploadbox">
            <input
              className="uploadfile"
              type="file"
              accept="image/png, image/jpeg"
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("questionImgUrl", e.currentTarget.files[0]);
              }}
            />
            <div style={{display:"flex"}}>
            <p>Upload</p><span className="qnrequired">*</span>
            </div>
          </div>
          {errors.questionImgUrl && touched.questionImgUrl ? (
            <div className="invalid-msg">{errors.questionImgUrl}</div>
          ) : null}
          {/* <p className="supportfile">Only jpg or png file support</p> */}

          <div className="buttonbox">
            <Link to="/educator/question-bank">
              <button type="button" className="btn btn-light previousbtn">
                Previous
              </button>
            </Link>
            <button type="submit" className="btn btn-primary nextbtn">
              Submit
            </button>
          </div>
        </div>
      </form>
      {cartIsShown && (
        <QuestionAdded
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
          message="Question Added Successfully!"
          path="/educator/question-bank"
        />
      )}
    </Fragment>
  );
};

export default AddQuestion;
