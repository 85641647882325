import React, { useState } from "react";
import Banner from "./Banner";
import HomeContainer from "./HomeContainer";
import OurStakeholders from "./OurStakeholders";
import TrainingPartner from "./TrainingPartner";
import ContactModal from "./common/ContactModal";
import { AiOutlineArrowUp } from "react-icons/ai";

function Home() {
  const [cartIsShown, setCartIsShown] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };
  return (
    <HomeContainer>
      <Banner showCartHandler={showCartHandler} />
      {visible && (
        <button title="Go to top" className="scrollToTop" onClick={scrollToTop}>
          <AiOutlineArrowUp />
        </button>
      )}
      <section className="section-circle">
        {/* About Us */}
        <section className="section-wrap about-section" id="aboutSection">
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow left-heading">
                ABOUT SHIKSHMITR.CO
              </h2>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="page-heading">
                  Language Learning Platform Powered by Artificial Intelligence
                </h2>
                <p>
                  <strong>
                    {" "}
                    Shikshamitr.CO bridges the gap of learning a new language
                    with ease, practice, and real-time feedback through its
                    futuristic platform. Based on years of research from global
                    educators to help learn with confidence. It assists in
                    preparing for IELTS, SAT, or an interview.
                  </strong>
                </p>
                <ul>
                  <li>Includes Learning Courseware and Practice Modules</li>
                  <li>
                    Focused on Reading, Listening, Writing, and Speaking in the
                    correct order
                  </li>
                  <li>Create your content & Assessments easily</li>
                  <li>Scalable Cloud solutions to grow as your needs grow.</li>
                </ul>
                <div className="contact-btn">
                  <a onClick={showCartHandler}>Connect with us</a>
                </div>
              </div>
              <div className="col-md-6 round-img text-right mobile-center mobile-top">
                {/* <img src="images/Video.png" /> */}
                <video
                  width="320"
                  height="240"
                  controls
                  className="aboutVideoHome"
                >
                  <source src="./videos/about.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>

        {/* Our Stake holders */}
        <section
          className="section-wrap value-section ourStakeholders"
          id="ourValuesSection"
        >
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow text-center center-lines">
                <span>OUR VALUES</span>
              </h2>
              <div className="col-md-12">
                <h2 className="page-heading text-center">Our Stakeholders</h2>
                <OurStakeholders />
                <div className="contact-btn text-center">
                  <a onClick={showCartHandler}>Connect with us</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-wrap learning-section" id="journeySection">
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow left-lines">
                <span>LEARNER'S JOURNEY</span>
              </h2>
              <h2 className="col-md-12 page-heading">Road to Language Lab</h2>
            </div>
            <div className="row mobile-right-section">
              <div className="col-md-6">
                <p>
                Shikshamitr.CO into the metaverse with Language Lab to develop
                  communication skills most effectively. From phonetics to soft
                  skills courses, get a hold of the language with global experts
                  and AI.
                </p>
                <ul className="steps-wrap">
                  <li>
                    <span className="step">1</span>
                    <div className="num-wrap">
                      <h2>Perfect phonetics</h2>
                      <p>Robust your base foundation</p>
                    </div>
                  </li>
                  <li>
                    <span className="step">2</span>
                    <div className="num-wrap">
                      <h2>Focused training</h2>
                      <p>
                        Learn with global educators in the area of your interest
                        and needs
                      </p>
                    </div>
                  </li>
                  <li>
                    <span className="step">3</span>
                    <div className="num-wrap">
                      <h2>Practice with AI</h2>
                      <p>Enhance your skills with AI driven tests</p>
                    </div>
                  </li>
                  <li>
                    <span className="step">4</span>
                    <div className="num-wrap">
                      <h2>Assessment Scores</h2>
                      <p>Professional analysis to perfect in required areas</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 round-img text-right mobile-center mobile-bottom">
                <img src="images/girl-step.png" alt="img" />
              </div>
            </div>
          </div>
        </section>

        <section className="section-wrap learning-section">
          <div className="container">
            <div className="row flex-column align-items-end position-relative">
              <h2 className="col-md-6 heading-yellow right-lines position-static">
                <span>UNIVERSITY JOURNEY</span>
              </h2>
              <h2 className="col-md-6 page-heading mobile-text-right">
                Recruit Excellence
              </h2>
            </div>
            <div className="row right-section">
              <div className="col-md-6">
                <p>
                Shikshamitr.CO presents the future development platform to
                  develop communication skills and a strategic mind set to
                  bridge the professional gap between campus and corporate for
                  students.
                </p>
                <ul className="steps-wrap">
                  <li>
                    <span className="step">1</span>
                    <div className="num-wrap">
                      <h2>Soft Skills/ Technical Training</h2>
                      <p>Learn from global educators in your interest fields</p>
                    </div>
                  </li>
                  <li>
                    <span className="step">2</span>
                    <div className="num-wrap">
                      <h2>Practice with AI</h2>
                      <p>Sharpen your skills with AI driven insights</p>
                    </div>
                  </li>
                  <li>
                    <span className="step">3</span>
                    <div className="num-wrap">
                      <h2>Assessment Scores</h2>
                      <p>
                        Analyse and work on your soft skills on the basis of our
                        comprehensive assessments scores.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span className="step">4</span>
                    <div className="num-wrap">
                      <h2>Branding</h2>
                      <p>
                        Collaborate to get global recognition and increase your
                        institution's credibility.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="contact-btn step-btn">
                  <a onClick={showCartHandler}>Connect with us</a>
                </div>
              </div>
              <div className="col-md-6 round-img text-left mobile-center mobile-bottom">
                <img src="images/meeting.png" alt="img" />
              </div>
            </div>
          </div>
        </section>

        {/* our offerings */}
        <section
          className="section-wrap offerings-section"
          id="offeringSection"
        >
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow left-lines">
                <span>OUR OFFERINGS</span>
              </h2>
              <h2 className="col-md-12 page-heading">Explore Our Offerings</h2>
              <div className="col-md-6 col-sm-12">
                <img
                  src="./images/offering1.png"
                  alt="img"
                  className="offerings-image"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <img
                  src="./images/offering2.png"
                  alt="img"
                  className="offerings-image"
                />
              </div>
            </div>
            <ul className="steps-wrap steps-row">
              <li>
                <span className="step">1</span>
                <div className="num-wrap">
                  <h2>The LSRW Module</h2>
                  <p>
                    Highly effective listening, speaking, reading and writing
                    methodologies
                  </p>
                </div>
              </li>
              <li>
                <span className="step">2</span>
                <div className="num-wrap">
                  <h2>Lesson Studio</h2>
                  <p>Grab customised learning materials for optimum results</p>
                </div>
              </li>
              <li>
                <span className="step">3</span>
                <div className="num-wrap">
                  <h2>Skills Trainings &amp; Workshops</h2>
                  <p>Upskill and curate 21st-century skills</p>
                </div>
              </li>
              <li>
                <span className="step">4</span>
                <div className="num-wrap">
                  <h2>Global Educators</h2>
                  <p>Connect with experts around the globe</p>
                </div>
              </li>
              <li>
                <span className="step">5</span>
                <div className="num-wrap">
                  <h2>AI Assessment</h2>
                  <p>
                    Practice with AI, analyse your scores and generate your
                    global ranking
                  </p>
                </div>
              </li>
              <li>
                <span className="step">6</span>
                <div className="num-wrap">
                  <h2>User Friendly</h2>
                  <p>Learn with ease in the metaverse</p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="section-wrap training-section " id="whyUsSection">
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow text-center center-lines">
                <span>YOUR TRAINING PARTNER</span>
              </h2>
              <div className="col-md-12 text-center">
                <h2 className="page-heading">Why Choose Us?</h2>
                <p>
                  Language lab is a perfect solution to learning language from
                  foundation to advance. Our 21st-century, user-friendly
                  software enables learning with AI-driven communication
                  assessments and scores. Learn with Metaverse.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card">
                  <img src="./images/training1.png" alt="img" />
                  <h2>Global/ Industry Experts</h2>
                  <p>
                    Acquire the need-specific skills from all around the globe
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card">
                  <img src="./images/training2.png" alt="img" />
                  <h2>Lesson Studio</h2>
                  <p>Grab customised learning materials for optimum results</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card">
                  <img src="./images/training3.png" alt="img" />
                  <h2>The LSRW Module</h2>
                  <p>
                    Highly effective listening, speaking, reading and writing
                    methodologies
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card">
                  <img src="./images/training4.png" alt="img" />
                  <h2>AI Assessments</h2>
                  <p>Analyse your skills with AI and experts</p>
                </div>
              </div>
              <div
                className=" col-md-12 contact-btn text-center"
                style={{ paddingTop: 30 }}
              >
                <a onClick={showCartHandler}>Connect with us</a>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-wrap faq-section border-top-gray"
          id="faqSection"
        >
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow text-center center-lines">
                <span>WANT TO CLEAR YOUR DOUBTS?</span>
              </h2>
              <div className="col-md-12 text-center">
                <h2 className="page-heading">Frequently Asked Questions</h2>
                <p>
                Shikshamitr.CO bridges the gap between learning a new language
                  with ease, practice and real-time feedback through its
                  futuristic platform. Based on years of research from global
                  educators to help learn with confidence. Be it IELTS, SAT, or
                  any interview.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="faq-box">
                  <h2>
                    Q.How do I sign up for corporate training for my college
                    students?
                  </h2>
                  <p>
                    Click on Connect with us and sign up the futuristic training
                    platform.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="faq-box">
                  <h2>
                    Q.Will I receive any certificate for completing the
                    training?
                  </h2>
                  <p>
                    Yes, after completing the training course, you will receive
                    the certificate for the same.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="faq-box">
                  <h2>Q.How will I get access to my selected candidates?</h2>
                  <p>
                    After logging in your account, you can access the selected
                    candidates.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="faq-box">
                  <h2>
                    Q.What should I do to access the same questionnaires again?
                  </h2>
                  <p>
                    You can go the company's website to access the same
                    questionnaire again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-wrap img-slider-wrap border-bottom-yellow">
          <div className="container">
            <div className="row">
              <h2 className="col-md-12 heading-yellow left-lines">
                <span>YOUR TRAINING PARTNER</span>
              </h2>
              <h2 className="col-md-12 page-heading">Our Motivators</h2>
            </div>
            <TrainingPartner />
          </div>
        </section>
      </section>

      {cartIsShown && (
        <ContactModal
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
        />
      )}
    </HomeContainer>
  );
}

export default Home;
