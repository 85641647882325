import React, { useEffect, useState } from "react";
import "../../styles/ReportDetail.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../utils/utils";
import { getAIReportAction } from "../../redux/slice/aiReport/getAIReportSlice";
import Summary from "../../component/student/Summary";
import VideoAnalysis from "../../component/student/VideoAnalysis";
import Findings from "../../component/student/Findings";
import Comparative from "../../component/student/Comparative";
import Recomendations from "../../component/student/Recomendations";
import RatingIndex from "../../component/student/RatingIndex";

const ReportDetail = () => {
  const { getAIReport } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [report, setReport] = useState({});

  const { studentId, reportId } = useParams();
  const token = getToken();

  useEffect(() => {
    dispatch(
      getAIReportAction({
        studentId: studentId,
        id: reportId,
        token,
      })
    );
  }, []);

  useEffect(() => {
    getAIReport.success && setReport(getAIReport?.report?.report);
  }, [getAIReport.success]);

  return (
    <div className="reportwrap">
      <div className="create-report-container">
        <div className="tabss">
          <button
            type="button"
            onClick={() => setTab(0)}
            className={`tab ${tab === 0 && "active"}`}
          >
            Summary
          </button>

          <button
            type="button"
            onClick={() => setTab(1)}
            className={`tab ${tab === 1 && "active"}`}
          >
            Video Analysis
          </button>

          {/* <button
                  type="button"
                  onClick={() => setTab(2)}
                  className={`tab ${tab === 2 && "active"}`}
                >
                  Findings
                </button> */}

          <button
            type="button"
            className={`tab ${tab === 3 && "active"}`}
            onClick={() => setTab(3)}
          >
            Comparative
          </button>
          <button
            type="button"
            className={`tab ${tab === 4 && "active"}`}
            onClick={() => setTab(4)}
          >
            Recomendations
          </button>
          <button
            type="button"
            className={`tab ${tab === 5 && "active"}`}
            onClick={() => setTab(5)}
          >
            Rating Index
          </button>
        </div>
        <div className="reportpercentage">
          <div className="percentagereport">
            <div className="hundred"></div>
            <p> 70-100%</p>
            <div className="seventy"></div>
            <p> 50-70%</p>
          </div>
          <div className="percentagereport belowthirty">
            <div className="fifty"></div>
            <p> 30-50%</p>
            <div className="thirty"></div>
            <p> Below 30%</p>
          </div>
        </div>
      </div>
      <div>
        {tab === 0 && <Summary report={report} />}
        {tab === 1 && <VideoAnalysis report={report} />}
        {tab === 2 && <Findings report={report} />}
        {tab === 3 && <Comparative report={report} />}
        {tab === 4 && <Recomendations report={report} />}
        {tab === 5 && <RatingIndex report={report} />}
      </div>
    </div>
  );
};

export default ReportDetail;
