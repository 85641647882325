import React from "react";
import { useSelector } from "react-redux";

const Global = () => {
  const { getUser } = useSelector((state) => state);

  return (
    <div className="globtable">
      <table className="table table-striped">
        <thead>
          <tr className="rateglobe align-middle">
            <th scope="col">User Profile</th>
            <th scope="col">Name</th>
            {/* <th scope="col">Place</th> */}
            <th>Email</th>
            <th>Phone No.</th>
          </tr>
        </thead>
        <tbody>
          <tr className="align-middle">
            <td>
              {/* src="/images/student/userprofile.svg" */}
              <img
                src={
                  getUser.userData.profileImg ||
                  "/images/student/userprofile.svg"
                }
                alt="user"
              />
            </td>
            <td>
              {getUser.userData.fname} {getUser.userData.lname}
            </td>
            {/* <td>Uttar Pardesh, India</td> */}
            <td>{getUser.userData.email}</td>
            <td>{getUser.userData.mnumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Global;
