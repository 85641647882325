import React from "react";
import DashContainer from "../../dashboard/DashContainer";
import EducatorLeftSidebar from "./EducatorLeftSidebar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const EducatorLayout = () => {
  const { toggleMenu } = useSelector((state) => state);
  return (
    <DashContainer>
      <section className={toggleMenu.value ? "wrapper" : "wrapper wrap-new"}>
        <EducatorLeftSidebar />
        <Outlet />
      </section>
    </DashContainer>
  );
};

export default EducatorLayout;
