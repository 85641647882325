import React, { useState } from "react";

const IntroForm = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <div className="intro-form">
      <div className="upper-part">
        <div className="left">
          <div className="input">
            <input type="checkbox" />
            <label htmlFor="greeting">Greeting</label>
            <div className="radios">
              <input
                type="radio"
                name="greeting"
                id="posture"
                checked={values.greeting === "posture"}
                value="posture"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="posture">Posture</label>
              <br />
              <input
                type="radio"
                id="greetingWord"
                name="greeting"
                checked={values.greeting === "greetingWord"}
                value="greetingWord"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="greetingWord">Greeting Word</label>
              <br />
              <input
                type="radio"
                id="movement"
                name="greeting"
                checked={values.greeting === "movement"}
                value="movement"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="movement">Movement</label>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="input">
            <input type="checkbox" />
            <label htmlFor="greeting">Facial Expression</label>
            <div className="radios">
              <input
                type="radio"
                id="neutral"
                name="facialExpression"
                checked={values.facialExpression === "neutral"}
                value="neutral"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="neutral">Neutral</label>
              <br />
              <input
                type="radio"
                name="facialExpression"
                id="emotions"
                checked={values.facialExpression === "emotions"}
                value="emotions"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="emotions">Emotions</label>
              <br />
              <input
                type="radio"
                name="facialExpression"
                id="smile"
                checked={values.facialExpression === "smile"}
                value="smile"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="smile">Smile</label>
            </div>
          </div>
        </div>
      </div>

      <div className="lower-part">
        <div className="input">
          <div className="check">
            <input type="checkbox" name="gratitude" id="gratitude" />
            <label htmlFor="gratitude">Gratitude</label>
          </div>
          <input
            type="text"
            name="gratitude"
            id="gratitudeContent"
            placeholder="Press Enter To Add"
            value={values.gratitude}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="input">
          <div className="check">
            <input type="checkbox" name="customWord" id="customWord" />
            <label htmlFor="customWord">Custom Word</label>
          </div>
          <input
            type="text"
            name="customWord"
            id="customWordContent"
            placeholder="Press Enter To Add"
            value={values.customWord}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="input">
          <div className="check">
            <input type="checkbox" name="customSentence" id="customSentence" />
            <label htmlFor="customSentence">Custom Sentences</label>
          </div>
          <input
            type="text"
            name="customSentences"
            id="customSentenceContent"
            placeholder="Press Enter To Add"
            value={values.customSentences}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroForm;
