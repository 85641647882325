import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DashContainer from "../../dashboard/DashContainer";
import LeftBar from "../../dashboard/LeftBar";
import Video from "../../component/student/Video";
import "../../styles/recordVideo.css";

const RecordVideo = () => {
  return (
    <div className="videowrapper">
      <div className="innervideowrapper">
        <p>{/* Assessment Name: Saurabh kainth */}</p>
      </div>
      <Video />
    </div>
  );
};

export default RecordVideo;
