import React from "react";
import DashHeader from "./DashHeader";

function DashContainer(props) {
  return (
    <>
      <DashHeader />
      {props.children}
    </>
  );
}

export default DashContainer;
