import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/singleUser.css";
import UserAdded from "../UserAdded";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserLocal } from "../../utils/utils";
import { useFormik } from "formik";
import { addMultipleUserSchema } from "../../utils/schema";
import { addMultipleAction } from "../../redux/slice/user/addMultipleUserSlice";

const MultipleUser = () => {
  const [cartIsShown, setCartIsShown] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);
  const { getDepartments, addMultipleUser } = useSelector((state) => state);
  const [departmentsList, setDepartmentsList] = useState([]);
  const navigate = useNavigate();
  const currentUser = getUserLocal();
  const token = getToken();
  const dispatch = useDispatch();

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  useEffect(() => {
    setDepartmentsList(getDepartments.departments);
  }, [getDepartments.success]);

  useEffect(() => {
    routeFlag && navigate("/educator/Manage");
  }, [addMultipleUser.success, routeFlag]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      file: "",
      department: "",
    },
    validationSchema: addMultipleUserSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("teacherId", currentUser.id);
      // showCartHandler();
      dispatch(addMultipleAction({ token, formData }));
      setRouteFlag(true);
      // showCartHandler();
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="multdep">
        <div className="userdetail">
          <div className="userabt">
            <p>Upload</p>
            <p className="req">*</p>
          </div>
          <div
            className="inputstyle"
            style={{ marginTop: "-5px" }}
          >
            <input
              type="file"
              style={{ borderRadius: "10px", color: "#ccc" }}
              name="file"
              onChange={(e) => {
                setFieldValue("file", e.currentTarget.files[0]);
              }}
              // onBlur={handleBlur}
            />
            {errors.file && touched.file ? (
              <div className="invalid-msg">{errors.file}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="btnmultiple">
        <Link to="/educator/Manage">
          <button type="button" className="btn btn-light prevbtn">
            Previous
          </button>
        </Link>
        <button type="submit" className="btn submitbtn">
          Submit
        </button>
      </div>
      {cartIsShown && (
        <UserAdded
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
        />
      )}
    </form>
  );
};

export default MultipleUser;
