import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { getUserLocal } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import useCheckSize from "../hooks/useCheckSize";
import { toggleFalse } from "../redux/slice/toggleMenu/toggleMenuSlice";

function LeftBar() {
  const { toggleMenu } = useSelector((state) => state);
  const user = getUserLocal();
  const user_role = user?.roles[0];
  const size = useCheckSize();
  useEffect(() => {}, [size]);

  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (toggleMenu.value && ref.current && !ref.current.contains(e.target)) {
        dispatch(toggleFalse());
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggleMenu.value]);

  return (
    <>
      {size >= 768 ? (
        <aside
          className={!toggleMenu.value ? "left-bar" : "left-bar bar-change"}
        >
          <ul>
            <li>
              <NavLink
                // to="/student/dashboard"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/dashboard"
                    : "/student/dashboard"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={16}
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path d="M0 16V5.33333L7 0L14 5.33333V16H8.75V9.77778H5.25V16H0Z" />
                  </svg>
                </span>
                <span className={!toggleMenu.value ? "bar-name" : "bar-none"}>
                  Dashboard
                </span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/courses"
                    : "/student/courses"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="icon">
                  <img src="/images/icons/coursesicon.svg" alt="courses icon" />
                </span>
                <span className={!toggleMenu.value ? "bar-name" : "bar-none"}>
                  Courses
                </span>
              </NavLink>
            </li> */}

            {/* student courses */}
            <li>
              <NavLink
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/courses"
                    : "/student/coursesdata"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="icon">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="7.05273"
                      width="10.1052"
                      height="10.9473"
                      rx="1.7514"
                      fill="#D9DAE1"
                    />
                    <rect
                      x="4.17606"
                      y="1.1243"
                      width="11.8566"
                      height="12.6987"
                      rx="2.62709"
                      fill="#D9DAE1"
                      stroke="white"
                      strokeWidth="1.7514"
                    />
                  </svg>
                </span>
                <span className={!toggleMenu.value ? "bar-name" : "bar-none"}>
                  Courses
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                // to="/assessment"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/assessment"
                    : "/student/assessment"
                }
              >
                <span className="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5233 8.94116H8.54418L13.1921 13.5891C13.3698 13.7667 13.6621 13.7812 13.8448 13.6091C14.983 12.5367 15.7659 11.0912 15.9957 9.46616C16.0351 9.18793 15.8042 8.94116 15.5233 8.94116ZM15.0577 7.03528C14.8154 3.52176 12.0077 0.714119 8.49418 0.471767C8.22595 0.453237 8.00006 0.679413 8.00006 0.948236V7.5294H14.5815C14.8504 7.5294 15.0762 7.30352 15.0577 7.03528ZM6.5883 8.94116V1.96206C6.5883 1.68118 6.34153 1.45029 6.06359 1.48971C2.55859 1.985 -0.120524 5.04705 0.00418193 8.71675C0.132417 12.4856 3.37742 15.5761 7.14801 15.5288C8.63036 15.5103 10.0001 15.0326 11.1262 14.2338C11.3586 14.0691 11.3739 13.727 11.1724 13.5256L6.5883 8.94116Z"
                      fill="#D9DAE1"
                    />
                  </svg>
                </span>
                <span className={!toggleMenu.value ? "bar-name" : "bar-none"}>
                  Assessment
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                // to="/profileDetails"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/profileDetails"
                    : "/student/profileDetails"
                }
              >
                <span className="icon">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.86875 14L4.0875 8.82368L0 5.34211L5.4 4.88158L7.5 0L9.6 4.88158L15 5.34211L10.9125 8.82368L12.1312 14L7.5 11.2553L2.86875 14Z"
                      fill="#D9DAE1"
                    />
                  </svg>
                </span>
                <span className={!toggleMenu.value ? "bar-name" : "bar-none"}>
                  Profile Details
                </span>
              </NavLink>
            </li>
          </ul>
        </aside>
      ) : (
        <aside
          ref={ref}
          className={toggleMenu.value ? "left-bar" : "left-bar lefbarNone"}
        >
          <ul>
            <li onClick={() => dispatch(toggleFalse())}>
              <NavLink
                // to="/student/dashboard"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/dashboard"
                    : "/student/dashboard"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={16}
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path d="M0 16V5.33333L7 0L14 5.33333V16H8.75V9.77778H5.25V16H0Z" />
                  </svg>
                </span>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li onClick={() => dispatch(toggleFalse())}>
              <NavLink
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/courses"
                    : "/student/courses"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="icon">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="7.05273"
                      width="10.1052"
                      height="10.9473"
                      rx="1.7514"
                      fill="#D9DAE1"
                    />
                    <rect
                      x="4.17606"
                      y="1.1243"
                      width="11.8566"
                      height="12.6987"
                      rx="2.62709"
                      fill="#D9DAE1"
                      stroke="white"
                      strokeWidth="1.7514"
                    />
                  </svg>
                </span>
                Courses
              </NavLink>
            </li>

            {/* student courses */}
            {/* <li onClick={()=>dispatch(toggleFalse())}>
          <NavLink
            to={
              user_role === "ROLE_TEACHER"
                ? "/educator/courses"
                : "/student/coursesdata"
            }
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <span className="icon">
              <img src="/images/icons/coursesicon.svg" alt="courses icon" />
            </span>
            Student Courses
          </NavLink>
        </li> */}

            <li onClick={() => dispatch(toggleFalse())}>
              <NavLink
                // to="/assessment"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/assessment"
                    : "/student/assessment"
                }
              >
                <span className="icon">
                  <img src="/images/icons/asmt.svg" alt=" assessment icon" />
                </span>
                Assessment
              </NavLink>
            </li>
            <li onClick={() => dispatch(toggleFalse())}>
              <NavLink
                // to="/profileDetails"
                to={
                  user_role === "ROLE_TEACHER"
                    ? "/educator/profileDetails"
                    : "/student/profileDetails"
                }
              >
                <span className="icon">
                  <img
                    src="/images/icons/profiledetails.svg"
                    alt="profile detail icon"
                  />
                </span>
                Profile Details
              </NavLink>
            </li>
          </ul>
        </aside>
      )}
    </>
  );
}

export default LeftBar;
