import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

const LineChart = ({ videoLength, data }) => {
  const option = {
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },

    tooltip: {
      trigger: "axis",

      axisPointer: {
        type: "cross",
      },
      backgroundColor: "#C8AACE",
      borderWidth: 0,
    },
    color: [" #C8AACE", " #C8AACE", " #C8AACE"],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
      show: false,
    },

    xAxis: {
      type: "category",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        // data: [820, 932, 901, 934, 1290, 1330, 1320],
        data: data,
        type: "line",
        smooth: true,
      },
    ],
  };

  return (
    <div className="App">
      <ReactECharts option={option} style={{ width: "96%", height: "340px" }} />
    </div>
  );
};

export default LineChart;
