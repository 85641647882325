import React from "react";

const OutroForm = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <div className="intro-form">
      <div className="lower-part">
        <div className="input">
          <div className="check">
            <input type="checkbox" name="gratitude" id="gratitude" />
            <label htmlFor="gratitude">Greeting</label>
          </div>
          <input
            type="text"
            name="greetingOutro"
            id="gratitudeContent"
            placeholder="Press Enter To Add"
            value={values.greetingOutro}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="input">
          <div className="check">
            <input type="checkbox" name="gratitude" id="gratitude" />
            <label htmlFor="gratitude">Gratitude</label>
          </div>
          <input
            type="text"
            name="gratitudeOutro"
            id="gratitudeContent"
            placeholder="Press Enter To Add"
            value={values.gratitudeOutro}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="input">
          <div className="check">
            <input type="checkbox" name="customWord" id="customWord" />
            <label htmlFor="customWord">Custom Word</label>
          </div>
          <input
            type="text"
            name="customWordOutro"
            id="customWordContent"
            placeholder="Press Enter To Add"
            value={values.customWordOutro}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="input">
          <div className="check">
            <input type="checkbox" name="customSentence" id="customSentence" />
            <label htmlFor="customSentence">Custom Sentences</label>
          </div>
          <input
            type="text"
            name="customSentencesOutro"
            id="customSentenceContent"
            placeholder="Press Enter To Add"
            value={values.customSentencesOutro}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default OutroForm;
