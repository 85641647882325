import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../component/Loader";

const ManageCourses = () => {
  const [courses, setCourses] = useState([]);
  const { getCourses } = useSelector((state) => state);

  useEffect(() => {
    const isPublished = getCourses?.courseData?.filter((course, index) => {
      return course.is_published === true;
    });
    setCourses(isPublished);
  }, [getCourses.success]);
  return (
    // <div className="edu-right-side">
    //   <div className="teacherboard">
    //     <div className="teacherinnerbox">
    //       <p>Manage Courses</p>
    //     </div>

    //     {/* <div className="container"> */}
    //     <div className="row tab-platform padding-3">
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4 mb-4">
    //         <div className="platform-box">
    //           <div className="platform-img">
    //             <span className="title-name">The Language Lab</span>
    //             <img src="/images/Events.jpg" alt='event' />
    //           </div>
    //           <div className="platform-btn">
    //             <Link className="btnfull" to="/educator/manage/user/courses/1">
    //               View More
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* </div> */}
    //   </div>
    // </div>
    <div className="middle-section-tech">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Courses</h1>
        <div className="row tab-platform">
          {getCourses.loading ? (
            <Loader />
          ) : (
            courses.map((course, index) => {
              return (
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-4">
                  <div className="platform-box">
                    <div className="platform-img">
                      <span className="title-name">{course.name}</span>
                      <img src={course.image_url} alt />
                    </div>
                    <div className="platform-btn">
                      <Link
                        className="btnfull"
                        to={`/student/coursesdata/${course.id}/detail`}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageCourses;
