import React from "react";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import "../styles/questionAdded.css";
import classes from "../styles/Modal.module.css";
import VideoCard from "./FooterSection/VideoCard";

const VideoPreview = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      modal={classes.modal}
      content={classes.content}
      backdrop={classes.backdrop}
    >
      <div>
        <img
          src={props.url ? props.url : "/images/student/lady.svg"}
          className="videopreviewimg"
        />
      </div>
    </Modal>
  );
};

export default VideoPreview;
