import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify, removeUserSession } from "../../../utils/utils";

export const pendingAsmtAction = createAsyncThunk(
  "pendingAsmt",
  async (token, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/getPendingAssessment`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${token}`,
          },
        }
      );

      const processedData = await response.json();

      if (response.status === 401) {
        removeUserSession();
        return rejectWithValue(processedData);
      } else if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const pendingAsmtSlice = createSlice({
  name: "pendingAsmt",
  initialState: {
    assessment: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // pendingAsmt Request Handling
    [pendingAsmtAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [pendingAsmtAction.fulfilled]: (state, { payload }) => {
      state.assessment = payload.data;
      state.loading = false;
      state.success = true;
      state.error = false;
      // notify(payload.message);
    },
    [pendingAsmtAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default pendingAsmtSlice.reducer;
