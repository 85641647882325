import React, { useEffect, useState } from "react";
import AddParticipantsModal from "./AddParticipantsModal";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "../../common/Paginate";
import { getStudentsAction } from "../../../redux/slice/user/getStudentsSlice";
import { getSerielNumber, getToken } from "../../../utils/utils";

const AddParticipants = ({
  handleChange,
  errors,
  touched,
  handleBlur,
  values,
}) => {
  // const [cartIsShown, setCartIsShown] = useState(false);
  const { getStudents } = useSelector((state) => state);
  const [students, setStudents] = useState([]);
  const { getDepartments } = useSelector((state) => state);
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentsAction({ token: getToken(), pageNumber }));
  }, [pageNumber]);

  const department = (id) => {
    const department = getDepartments.departments?.find((dep) => {
      return dep.id == id;
    });
    return department?.departmentName;
  };

  // const showCartHandler = () => {
  //   setCartIsShown(true);
  // };

  // const hideCartHandler = () => {
  //   setCartIsShown(false);
  // };

  useEffect(() => {
    setStudents(getStudents.users);
  }, [getStudents.success]);
  return (
    <>
      <div className="add-questions">
        {/* <div className="add-question">
          <button type="button" onClick={() => setCartIsShown(true)}>
            + Add Participants
          </button>
        </div> */}
        <div className="table-container">
          <table className="table table-striped table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col"></th>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Department</th>
              </tr>
            </thead>

            <tbody>
              {students?.dataItems?.map((student, index) => {
                return (
                  <tr className="align-middle">
                    <th scope="row">
                      <input
                        type="checkbox"
                        name="participants"
                        id=""
                        checked={
                          values.participants?.find((val) => {
                            return val == student.id;
                          }) == student.id
                        }
                        value={student.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </th>
                    <th scope="row">{getSerielNumber(index, pageNumber)}</th>
                    <td>
                      {student.fname} {student.lname}
                    </td>
                    <td>{student?.email || "NA"}</td>
                    <td>{student?.mnumber || "NA"}</td>
                    <td>{department(student?.department) || "NA"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Paginate
          totalPages={students?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
      {/* {cartIsShown && (
        <AddParticipantsModal
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
        />
      )} */}
    </>
  );
};

export default AddParticipants;
