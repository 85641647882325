import React from "react";
import Carousel from "react-bootstrap/Carousel";

function Banner(props) {
  return (
    <>
      <Carousel className="banner">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/banner.png"
            alt="First slide"
          />
          <div className="container">
            <h2>Are you ready to learn</h2>
            <p>
              A Language with <br /> Global Educators
            </p>
            <div className="contact-btn" onClick={props.showCartHandler}>
              <a>Talk to an expert</a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/banner2.png"
            alt="Second slide"
          />
          <div className="container">
            <h2>Are you ready to learn</h2>
            <p>
              A Language With Linguistic <br /> Artificial Intelligence
            </p>
            <div className="contact-btn" onClick={props.showCartHandler}>
              <a>Talk to an expert</a>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Banner;
