import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getColor } from "../../utils/utils";

const CircularGraph = ({ value }) => {
  return (
    <CircularProgressbar
      value={value || 0}
      maxValue={100}
      text={`${value || 0}%`}
      styles={buildStyles({
        // Text size
        textSize: "16px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,
        // Colors
        pathColor: getColor(value),
        textColor: getColor(value),
        trailColor: "#d6d6d6",
        backgroundColor: "#3e98c7",
      })}
    />
  );
};

export default CircularGraph;
