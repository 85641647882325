import React from "react";

const EnvironmentForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="intro-form">
      <div className="upper-part">
        <div className="left">
          <div className="input">
            <input type="checkbox" name="greeting" id="greeting" />
            <label htmlFor="greeting">ENVIRONMENT</label>
            <div className="radios">
              <input
                type="radio"
                name="environmentTab"
                id="posture"
                checked={values.environmentTab === "clothing"}
                value="clothing"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="posture">Clothing</label>
              <br />
              <input
                type="radio"
                name="environmentTab"
                id="greetingWord"
                checked={values.environmentTab === "objectDetection"}
                value="objectDetection"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="greetingWord">Object Detection</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentForm;
