import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssignedAssessmentAction } from "../../redux/slice/assessment/getAssignedAssessmentSlice";
import { getPurposeHandle, getToken, localDateFormat } from "../../utils/utils";
import Loader from "../Loader";
import { Link, useParams } from "react-router-dom";
import { getAllAIReportsAction } from "../../redux/slice/aiReport/getAllAIReportSlice";

const AssignedAssessment = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [assignedAssessment, setassignedAssessment] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getAssignedAssessment, createAssessment, getAllAIReports, getUser } =
    useSelector((state) => state);
  const token = getToken();
  const data = { pageNumber, token };

  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    setReportData(getAllAIReports.reports.AllAIReports);
  }, [getAllAIReports]);

  useEffect(() => {
    // fetch('url', {
    //   Method: 'POST',
    //   Headers: {
    //     Accept: 'application.json',
    //     'Content-Type': 'application/json'
    //   },
    //   Body: body,
    //   Cache: 'default'
    // })

    if (getUser.success) {
      // const numberOfReports =
      dispatch(
        getAllAIReportsAction({
          studentId: getUser.userData.id,
          assignmentId: id,
          token,
        })
      );
    }
  }, [getUser.success]);

  useEffect(() => {
    dispatch(getAssignedAssessmentAction(data));
  }, [pageNumber, createAssessment.success]);

  useEffect(() => {
    setassignedAssessment(getAssignedAssessment.assignedAssessment?.data);
  }, [getAssignedAssessment.success]);

  const createAssessmentSave = (asses, assesId) => {
    localStorage.setItem("assesment", asses);
    localStorage.setItem("assesmentId", assesId);
  };

  return (
    <div className="col-md-12">
      {getAssignedAssessment.loading ? (
        <Loader />
      ) : (
        assignedAssessment?.map((assessment, index) => {
          const date = localDateFormat(assessment.updatedAt);
          return (
            <Link
              to={`/student/craeteAssesment/${assessment.id}`}
              key={index}
              onClick={() =>
                createAssessmentSave(assessment.assessmentName, assessment.id)
              }
            >
              <div className="assessment-box">
                <div className="date-box">
                  <span className="date">Last Updated : {date}</span>{" "}
                  <span className="mike-box">
                    {getPurposeHandle(assessment.assessmentPurpose) ===
                    "Others" ? (
                      "NA"
                    ) : (
                      <img
                        src={`/images/icons/${getPurposeHandle(
                          assessment.assessmentPurpose
                        )}.svg`}
                        alt
                      />
                    )}
                  </span>
                </div>
                <h2>{assessment.assessmentName}</h2>
                <div className="purpose">
                  <span>Purpose: Reading</span> <span>Progress: N/A</span>
                </div>
                <div className="version-count-wrap">
                  <div className="version-box">
                    {/* <span>{getAllAIReports?.reports.report?.length}</span>
                    Version Count */}
                  </div>
                  {/* <div className="coins-box">
                    <span>0</span>Coins Earned
                  </div> */}
                  {/* <div className="assessment-btn">
                  <Link to="/student/craeteAssesment">
                    <a className="btn-m yellow-btn">Take Assessment</a>
                  </Link>
                </div> */}
                </div>
              </div>
            </Link>
          );
        }) || (
          <div className="assessment-box no-completed-assessment">
            No Assessment Found
          </div>
        )
      )}

      {assignedAssessment?.length === 0 && (
        <div className="assessment-box no-completed-assessment">
          No Assessment Found
        </div>
      )}

      {/* Assessment Box */}
      {/* <div className="assessment-box">
        <div className="date-box">
          <span className="date">Last Updated : 06 Feb, 2023</span>{" "}
          <span className="mike-box">
            <img src="/images/mike.svg" alt />
          </span>
        </div>
        <h2>Reading Assessment</h2>
        <div className="purpose">
          <span>Purpose: Reading</span> <span>Progress: N/A</span>
        </div>
        <div className="version-count-wrap">
          <div className="version-box">
            <span>0</span>Version Count
          </div>
          <div className="coins-box">
            <span>0</span>Coins Earned
          </div>
        </div>
      </div> */}
      {/* Assessment Box */}
      {/* <div className="assessment-box">
        <div className="date-box">
          <span className="date">Last Updated : 06 Feb, 2023</span>{" "}
          <span className="mike-box">
            <img src="/images/mike.svg" alt />
          </span>
        </div>
        <h2>Writing Assessment</h2>
        <div className="purpose">
          <span>Purpose: Reading</span> <span>Progress: N/A</span>
        </div>
        <div className="version-count-wrap">
          <div className="version-box">
            <span>0</span>Version Count
          </div>
          <div className="coins-box">
            <span>0</span>Coins Earned
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AssignedAssessment;
