import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "../styles/VideoSlider.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Thumbs, Navigation } from "swiper";
import { Link } from "react-router-dom";

const VideoSlider = () => {
  const swiper = useSwiper();
  return (
    <div className="right-bar-section shadow-box">
      {/* <img src="/images/banner1.png" alt="Banner" style={{ width: "100%" }} /> */}
      <Swiper
        className="myVideoSwiper"
        // slidesPerView={1}
        // loop={true}
        // allowSlideNext={true}
        // rewind={true}
        // autoplay={{
        //   delay: 5000,
        //   // disableOnInteraction: true,
        //   autoplayDisableOnInteraction: true,
        // }}
        spaceBetween={0}
        loop={true}
        centeredSlides={true}
        speed={2000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        modules={[Autoplay]}
        navigation
        // modules={[Autoplay, Thumbs, Navigation]}
        grabCursor={true}
      >
        <SwiperSlide>
          <video controls>
            <source src="/videos/video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>

        <SwiperSlide>
          <video controls>
            <source src="/videos/video2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>
        <SwiperSlide>
          <video controls>
            <source src="/videos/video3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>
        <SwiperSlide>
          <video controls>
            <source src="/videos/video3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>
      </Swiper>

      {/* <Link
        className="btnfull mt-3"
        to="https://heyzine.com/flip-book/49ba0031ac.html"
        target="_blank"
      >
        Free Bees
      </Link> */}
    </div>
  );
};

export default VideoSlider;
