import React from "react";
import Modal from "./Modal";
import { Link } from 'react-router-dom';
import '../styles/questionAdded.css'

const UserAdded = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <div className="qnaddedwrapper">
        <img src='/images/educator/success.png' alt='success'/>
        <p>User Added Successfully</p>
        <Link to='/educator/Manage'><button type="button" className="btn btn-primary okbtn">ok</button></Link>
      </div>
    </Modal>
  );
};

export default UserAdded;
