import React, { useState } from "react";
import { Link } from "react-router-dom";
import EventModal from "../../component/student/EventModal";

const StudentEvent = () => {
  const [cartIsShown, setCartIsShown] = useState(false);
  const [imageValue, setImageValue] = useState();

  const eventArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  const imagePreview = (val) => {
    setImageValue(val);
  };
  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Event Platform</h1>
        <div className="row tab-platform ">
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/student/eventone.png" alt />
              </div>
              <div className="platform-btn">
                <Link
                  className="btnfull"
                  to="#"
                  onClick={() => {
                    imagePreview("/images/student/modaleventone.png");
                    showCartHandler();
                  }}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/student/eventtwo.png" alt />
              </div>
              <div className="platform-btn">
                <Link
                  className="btnfull"
                  to="#"
                  onClick={() => {
                    imagePreview("/images/student/modaleventtwo.png");
                    showCartHandler();
                  }}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/student/eventthree.png" alt />
              </div>
              <div className="platform-btn">
                <Link
                  className="btnfull"
                  to="#"
                  onClick={() => {
                    imagePreview("/images/student/modaleventthree.png");
                    showCartHandler();
                  }}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/student/eventfour.png" alt />
              </div>
              <div className="platform-btn">
                <Link
                  className="btnfull"
                  to="#"
                  onClick={() => {
                    imagePreview("/images/student/modaleventfour.png");
                    showCartHandler();
                  }}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/student/eventfive.png" alt />
              </div>
              <div className="platform-btn">
                <Link
                  className="btnfull"
                  to="#"
                  onClick={() => {
                    imagePreview("/images/student/modaleventfive.png");
                    showCartHandler();
                  }}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>

          {eventArray.map((event) => {
            return (
              <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
                <div className="platform-box">
                  <div className="platform-img">
                    <span className="title-name">Events</span>
                    <img src={`/images/student/event/event${event}.jpeg`} alt />
                  </div>
                  <div className="platform-btn">
                    <Link
                      className="btnfull"
                      to="#"
                      onClick={() => {
                        imagePreview(
                          `/images/student/event/event${event}.jpeg`
                        );
                        showCartHandler();
                      }}
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {cartIsShown && (
          <EventModal
            cartIsShown={cartIsShown}
            setCartIsShown={setCartIsShown}
            showCartHandler={showCartHandler}
            onClose={hideCartHandler}
            image={imageValue}
          />
        )}
      </div>
    </div>
  );
};

export default StudentEvent;
