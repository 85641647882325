import React, { useEffect, useState } from "react";
import DashContainer from "../../dashboard/DashContainer";
import LeftBar from "../../dashboard/LeftBar";
import RightBar from "../../dashboard/RightBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllLibraryAction } from "../../redux/slice/centeralLibrary/getAllLibrarySlice";
import { getToken } from "../../utils/utils";
import Loader from "../../component/Loader";
import { getLibraryByCategoryAction } from "../../redux/slice/centeralLibrary/getLibraryByCategorySlice";
import Paginate from "../../component/common/Paginate";

const CenteralLibrary = () => {
  const { getAllLibrary, toggleMenu, getLibraryByCategory } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const [catId, setCatId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [catPageNumber, setCatPageNumber] = useState(0);
  const [categories, setCategories] = useState([]);
  const [library, setLibrary] = useState([]);

  const token = getToken();

  //   Getting value of category
  const handleChange = (e) => {
    setCatId(e.target.value);
    if (e.target.value === "all") {
      dispatch(getAllLibraryAction({ token, pageNumber }));
    } else {
      dispatch(
        getLibraryByCategoryAction({
          token,
          id: e.target.value,
          pageNumber: catPageNumber,
        })
      );
    }
  };

  //   Fetching Categories
  const getCategories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/getAllCategory`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const result = await response.json();
    setCategories(result?.data);
  };

  //   setting fetched values from API
  useEffect(() => {
    if (getAllLibrary.success) {
      setLibrary(getAllLibrary.library.dataItems);
    }
  }, [getAllLibrary.success]);

  useEffect(() => {
    if (getLibraryByCategory.success) {
      setLibrary(getLibraryByCategory.library.dataItems);
    }
  }, [getLibraryByCategory.success]);

  //   Requesting All libary here
  // useEffect(() => {
  //   dispatch(getAllLibraryAction({ token, pageNumber }));
  //   getCategories();
  // }, []);

  useEffect(() => {
    dispatch(getAllLibraryAction({ token, pageNumber }));
    getCategories();
  }, [pageNumber]);

  useEffect(() => {
    if (catId === "all") {
      dispatch(getAllLibraryAction({ token, pageNumber }));
    } else {
      catId &&
        dispatch(
          getLibraryByCategoryAction({
            token,
            id: catId,
            pageNumber: catPageNumber,
          })
        );
    }
  }, [catPageNumber]);

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <div className="w-100 space-between filterLibaryCont">
          <h1 className="pagetitle">Centeral Library</h1>{" "}
          <div className="libraryFilter">
            <button
              onClick={() => {
                dispatch(getAllLibraryAction({ token, pageNumber }));
                setCatId("");
              }}
            >
              Show All
            </button>
            <select
              value={catId}
              name="category"
              id="category"
              onChange={handleChange}
            >
              <option>Select Category</option>
              <option value="all">All</option>
              {categories?.map((cat, index) => {
                return (
                  <option value={cat?.id} key={index}>
                    {cat?.categoryName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row tab-platform ">
          {getAllLibrary.loading || getLibraryByCategory.loading ? (
            <Loader />
          ) : library?.length === 0 ? (
            <div className="mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4 w-100">
              <div className="platform-box  p-4">Found Nothing</div>
            </div>
          ) : (
            library?.map((item, index) => {
              return (
                <div
                  className="mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4"
                  key={index}
                >
                  <div className="platform-box">
                    <div className="platform-img">
                      <span className="title-name">{item?.title}</span>
                      <img src={item.fileUpload} alt={item.title} />
                    </div>
                    <div className="platform-btn">
                      <a
                        className="btnfull"
                        href={item.bookUrl}
                        target="_blank"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {catId ? (
        <Paginate
          pageNumber={catPageNumber}
          setPageNumber={setCatPageNumber}
          totalPages={getLibraryByCategory.library?.totalPages}
        />
      ) : (
        <Paginate
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={getAllLibrary.library?.totalPages}
        />
      )}
    </div>
  );
};

export default CenteralLibrary;

let data = [
  {
    id: "1",
    url: "https://heyzine.com/flip-book/7e14986d95.html",
  },
  {
    id: "2",
    url: "https://heyzine.com/flip-book/74cf72ebc8.html",
  },
  {
    id: "3",
    url: "https://heyzine.com/flip-book/1e7ee69f1a.html",
  },
  {
    id: "4",
    url: "https://heyzine.com/flip-book/bc62ed27c6.html",
  },
  {
    id: "5",
    url: "https://heyzine.com/flip-book/a2f515d1b4.html",
  },
  {
    id: "6",
    url: "https://heyzine.com/flip-book/169528f1c9.html",
  },
  {
    id: "7",
    url: "https://heyzine.com/flip-book/876336e092.html",
  },

  {
    id: "8",
    url: "https://heyzine.com/flip-book/d9090b3fc9.html",
  },
  {
    id: "9",
    url: "https://heyzine.com/flip-book/df1384e422.html",
  },

  {
    id: "10",
    url: "https://heyzine.com/flip-book/ddb1c40009.html",
  },
  {
    id: "11",
    url: "https://heyzine.com/flip-book/2ec43f9591.html",
  },
  {
    id: "12",
    url: "https://heyzine.com/flip-book/931d4b8429.html",
  },

  {
    id: "13",
    url: "https://heyzine.com/flip-book/a5cbb775ec.html",
  },
  {
    id: "14",
    url: "https://heyzine.com/flip-book/559728d5fc.html",
  },
  {
    id: "15",
    url: "https://heyzine.com/flip-book/59e5f4ceb6.html",
  },
  {
    id: "16",
    url: "https://heyzine.com/flip-book/49a0b82c91.html",
  },
  {
    id: "17",
    url: "https://heyzine.com/flip-book/1fafaeae85.html",
  },
  {
    id: "18",
    url: "https://heyzine.com/flip-book/c80a6b9695.html",
  },
  {
    id: "19",
    url: "https://heyzine.com/flip-book/23d1f7bc88.html",
  },
  {
    id: "20",
    url: "https://heyzine.com/flip-book/1fff080d76.html",
  },
  {
    id: "21",
    url: "https://heyzine.com/flip-book/b7c1eb619e.html",
  },
  {
    id: "22",
    url: "https://heyzine.com/flip-book/7628aee911.html",
  },
  {
    id: "23",
    url: "https://heyzine.com/flip-book/5a06af3354.html",
  },
  {
    id: "24",
    url: "https://heyzine.com/flip-book/c2d29e1945.html",
  },

  {
    id: "26",
    url: "https://heyzine.com/flip-book/a2a3c01ca3.html",
  },
  {
    id: "27",
    url: "https://heyzine.com/flip-book/5a52324387.html",
  },

  {
    id: "29",
    url: "https://heyzine.com/flip-book/e474687c1a.html",
  },
  {
    id: "30",
    url: "https://heyzine.com/flip-book/f908d86df6.html",
  },
  {
    id: "31",
    url: "https://heyzine.com/flip-book/fc8667303b.html",
  },
  {
    id: "32",
    url: "https://heyzine.com/flip-book/9062726208.html",
  },
  {
    id: "33",
    url: "https://heyzine.com/flip-book/0192c478bd.html",
  },
  {
    id: "34",
    url: "https://heyzine.com/flip-book/e3ea9dc2a5.html",
  },

  {
    id: "36",
    url: "https://heyzine.com/flip-book/7083ca73a5.html",
  },
  {
    id: "37",
    url: "https://heyzine.com/flip-book/b979e25b07.html",
  },
  {
    id: "38",
    url: "https://heyzine.com/flip-book/5ed7165927.html",
  },
  {
    id: "39",
    url: "https://heyzine.com/flip-book/38b70ba119.html",
  },
  {
    id: "40",
    url: "https://heyzine.com/flip-book/72545ebb27.html",
  },
  {
    id: "41",
    url: "https://heyzine.com/flip-book/086ce406f9.html",
  },
  {
    id: "42",
    url: "https://heyzine.com/flip-book/bf22c08e6b.html",
  },
  {
    id: "43",
    url: "https://heyzine.com/flip-book/2f2b8692d6.html",
  },
  {
    id: "44",
    url: "https://heyzine.com/flip-book/c959f9c5a8.html",
  },
  {
    id: "45",
    url: "https://heyzine.com/flip-book/e0445f4931.html",
  },
  {
    id: "46",
    url: "https://heyzine.com/flip-book/7c90fb75eb.html",
  },
  {
    id: "47",
    url: "https://heyzine.com/flip-book/f3dd5fb5c3.html",
  },
  {
    id: "48",
    url: "https://heyzine.com/flip-book/bd82486a35.html",
  },
  {
    id: "49",
    url: "https://heyzine.com/flip-book/58659550da.html",
  },
  {
    id: "50",
    url: "https://heyzine.com/flip-book/d098802744.html",
  },
  {
    id: "51",
    url: "https://heyzine.com/flip-book/4b170a6014.html",
  },
  {
    id: "52",
    url: "https://heyzine.com/flip-book/343f3a55c6.html",
  },
  {
    id: "53",
    url: "https://heyzine.com/flip-book/94d73a1293.html",
  },
  {
    id: "54",
    url: "https://heyzine.com/flip-book/8378fe9d51.html",
  },
  {
    id: "55",
    url: "https://heyzine.com/flip-book/6c41e523b1.html",
  },
  {
    id: "56",
    url: "https://heyzine.com/flip-book/ee9b5c6ccc.html",
  },
  {
    id: "57",
    url: "https://heyzine.com/flip-book/98b69936b3.html",
  },
  {
    id: "58",
    url: "https://heyzine.com/flip-book/25ebc664b3.html",
  },
  {
    id: "59",
    url: "https://heyzine.com/flip-book/f59c018981.html",
  },
  {
    id: "60",
    url: "https://heyzine.com/flip-book/a735293498.html",
  },
  {
    id: "61",
    url: "https://heyzine.com/flip-book/88f82e1b20.html",
  },
  {
    id: "62",
    url: "https://heyzine.com/flip-book/13e600370f.html",
  },
  {
    id: "63",
    url: "https://heyzine.com/flip-book/fbb23d8029.html",
  },
  {
    id: "64",
    url: "https://heyzine.com/flip-book/157c3a348b.html",
  },
  {
    id: "65",
    url: "https://heyzine.com/flip-book/834039f14b.html",
  },
  {
    id: "66",
    url: "https://heyzine.com/flip-book/8e85656548.html",
  },
  {
    id: "67",
    url: "https://heyzine.com/flip-book/1c9f05b74f.html",
  },
  {
    id: "68",
    url: "https://heyzine.com/flip-book/b078a00bc9.html",
  },
  {
    id: "69",
    url: "https://heyzine.com/flip-book/c21b7ad001.html",
  },
  {
    id: "70",
    url: "https://heyzine.com/flip-book/19ea434cbc.html",
  },
  {
    id: "71",
    url: "https://heyzine.com/flip-book/4517a2aff9.html",
  },
  {
    id: "72",
    url: "https://heyzine.com/flip-book/d9039bc65b.html",
  },
  {
    id: "73",
    url: "https://heyzine.com/flip-book/3ae27937fc.html",
  },
  {
    id: "74",
    url: "https://heyzine.com/flip-book/a9a32a7ee0.html",
  },
  {
    id: "75",
    url: "https://heyzine.com/flip-book/f5c14f03e4.html",
  },
  {
    id: "76",
    url: "https://heyzine.com/flip-book/8e849a0c7e.html",
  },
  {
    id: "77",
    url: "https://heyzine.com/flip-book/d56d9eb7ad.html",
  },
  {
    id: "78",
    url: "https://heyzine.com/flip-book/595148d07a.html",
  },
  {
    id: "79",
    url: "https://heyzine.com/flip-book/fb9d5ff4ae.html",
  },
  {
    id: "80",
    url: "https://heyzine.com/flip-book/613b81abbb.html",
  },
  {
    id: "81",
    url: "https://heyzine.com/flip-book/b8bc176cd0.html",
  },
  {
    id: "82",
    url: "https://heyzine.com/flip-book/01945480fa.html",
  },
  {
    id: "83",
    url: "https://heyzine.com/flip-book/8d303e79a0.html",
  },
  {
    id: "84",
    url: "https://heyzine.com/flip-book/38ed46b71a.html",
  },
  {
    id: "85",
    url: "https://heyzine.com/flip-book/1626326748.html",
  },
  {
    id: "86",
    url: "https://heyzine.com/flip-book/1626326748.html",
  },
  {
    id: "87",
    url: "https://heyzine.com/flip-book/91dbe63a3b.html",
  },
  {
    id: "88",
    url: "https://heyzine.com/flip-book/407e62cbec.html",
  },
  {
    id: "89",
    url: "https://heyzine.com/flip-book/a8190a4cc4.html",
  },
  {
    id: "90",
    url: "https://heyzine.com/flip-book/38f1a9cd67.html",
  },
  {
    id: "91",
    url: "https://heyzine.com/flip-book/3c61b27fad.html",
  },
  {
    id: "92",
    url: "https://heyzine.com/flip-book/3c34aa384a.html",
  },
  {
    id: "93",
    url: "https://heyzine.com/flip-book/e9659102df.html",
  },
  {
    id: "94",
    url: "https://heyzine.com/flip-book/34fbfb536b.html",
  },
  {
    id: "95",
    url: "https://heyzine.com/flip-book/9a48e6b2af.html",
  },
  {
    id: "96",
    url: "https://heyzine.com/flip-book/8c978d2aad.html",
  },
  {
    id: "97",
    url: "https://heyzine.com/flip-book/8d9685191a.html",
  },
  {
    id: "98",
    url: "https://heyzine.com/flip-book/95276d9036.html",
  },
  {
    id: "99",
    url: "https://heyzine.com/flip-book/341e48e33b.html",
  },

  {
    id: "100",
    url: "https://heyzine.com/flip-book/8cc40a1583.html",
  },
  {
    id: "101",
    url: "https://heyzine.com/flip-book/2cf178f67b.html",
  },
  {
    id: "102",
    url: "https://heyzine.com/flip-book/01f80b50d7.html",
  },

  {
    id: "104",
    url: "https://heyzine.com/flip-book/65e377715d.html",
  },
  {
    id: "105",
    url: "https://heyzine.com/flip-book/cdd500c969.html",
  },
  {
    id: "106",
    url: "https://heyzine.com/flip-book/26f7dc3636.html",
  },
  {
    id: "107",
    url: "https://heyzine.com/flip-book/9843247a19.html",
  },
  {
    id: "108",
    url: "https://heyzine.com/flip-book/bce86f3955.html",
  },
  {
    id: "109",
    url: "https://heyzine.com/flip-book/9b95d42c47.html",
  },
  {
    id: "110",
    url: "https://heyzine.com/flip-book/6bc5058e57.html",
  },
  {
    id: "111",
    url: "https://heyzine.com/flip-book/9ae6c362fd.html",
  },
  {
    id: "112",
    url: "https://heyzine.com/flip-book/64bfa32945.html",
  },
  {
    id: "113",
    url: "https://heyzine.com/flip-book/22546aec74.html",
  },
  {
    id: "114",
    url: "https://heyzine.com/flip-book/3ff4dc4d71.html",
  },
  {
    id: "115",
    url: "https://heyzine.com/flip-book/49ba0031ac.html",
  },
  {
    id: "116",
    url: "",
  },
  {
    id: "117",
    url: "",
  },
  {
    id: "118",
    url: "",
  },
  {
    id: "119",
    url: "",
  },
  {
    id: "120",
    url: "",
  },
  // {}
];
