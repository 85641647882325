import React from "react";
import Header from "../Header";
import BlogDetailPage from "./BlogDetailPage";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBlogByIdAction } from "../../redux/slice/blog/getBlogByIdSlice";
import Loader from "../common/Loader";

const BlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { blogById } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getBlogByIdAction({ id }));
  }, []);

  // const { title, description, imgUrl } = blogById.blog;
  return (
    <div>
      <Header />
      <p className="blogpara">Blogs</p>
      <div className="blogwrap">
        <div className="blogin">
          {/* <p className="listblog">Home &gt; Blogs List </p> */}
          <div className="detailblog">
            {blogById.loading ? (
              <Loader />
            ) : (
              <BlogDetailPage {...blogById.blog} />
            )}
          </div>
        </div>
        {/* <div className='rightwrapblog'>
          <RightBlogBar />
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
