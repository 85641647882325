import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashContainer from "./DashContainer";
import LeftBar from "./LeftBar";
import RightBar from "./RightBar";
import { useDispatch, useSelector } from "react-redux";
import { pendingAsmtAction } from "../redux/slice/assessment/getPendingAsmtSlice";
import { getToken, localDateFormat } from "../utils/utils";

function Dashboard() {
  const [pendingAsmt, setPendingAsmt] = useState({});
  const dispatch = useDispatch();
  const { getPendingAsmt, toggleMenu } = useSelector((state) => state);
  const token = getToken();

  useEffect(() => {
    dispatch(pendingAsmtAction(token));
  }, []);

  useEffect(() => {
    setPendingAsmt(getPendingAsmt.assessment);
  }, [getPendingAsmt.success]);

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Bee Platform</h1>
        <hr className="dashboard-hr" />
        <div className="row tab-platform ">
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Events</span>
                <img src="/images/Events.jpg" alt />
              </div>
              <div className="platform-btn">
                <Link className="btnfull" to="/student/event">
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">
                  Pending Assements : {pendingAsmt?.assessmentName || ""}...
                </span>
                <div className="pendingAsmtInfo">
                  {pendingAsmt?.length > 0 ? (
                    <span className="heading">
                      Started At :{" "}
                      <span>{localDateFormat(pendingAsmt?.startDate)}</span>
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  {pendingAsmt?.length > 0 ? (
                    <span className="heading">
                      Started At :{" "}
                      <span>{localDateFormat(pendingAsmt?.endDate)}</span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <img src="/images/Pending-Assements.png" alt />
              </div>
              <div className="platform-btn">
                <Link className="btnfull" to="/student/assessment">
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-4 col-md-12 col-sm-12 col-lg-6 col-xl-4">
            <div className="platform-box">
              <div className="platform-img">
                <span className="title-name">Central library</span>
                <img src="/images/Central-librarry.png" alt />
              </div>
              <div className="platform-btn">
                <Link className="btnfull" to="/student/centeral-library">
                  View More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
