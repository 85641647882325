import React, { useState, useRef, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import "../../styles/recordVideo.css";
import { useDispatch, useSelector } from "react-redux";
import { aiReportRequestAction } from "../../redux/slice/aiReport/aiReportRequestSlice";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utils/utils";

const Video = () => {
  const [mediaBlobUrls, setMediaBlobUrls] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const videoRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const { getUser, aiReportRequest } = useSelector((state) => state);

  useEffect(() => {
    getUser.success && setUserData(getUser?.userData);
  }, [getUser.success]);

  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [routpage, setRoutePage] = useState(false);
  // const ref = useRef(0);
  useEffect(() => {
    if (startTime !== null) {
      setTimerInterval(
        setInterval(() => {
          setElapsedTime(Date.now() - startTime);
          // ref.current+=1;
        }, 1000)
      );
    }
  }, [startTime]);

  useEffect(() => {
    if (elapsedTime >= 5 * 60 * 1000) {
      stopRecording();
    }
    if (routpage && aiReportRequest.success) {
      navigate("/student/craeteAssesment");
    }
  }, [elapsedTime, aiReportRequest.success]);

  const navigate = useNavigate();

  const handleStopRecording = (blobUrl, blob) => {
    const recordTime = elapsedTime + (Date.now() - startTime);
    setMediaBlobUrls((prevState) => [...prevState, blobUrl]);
    setShowPreview(false);
    setElapsedTime(0);

    clearInterval(timerInterval);
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      setElapsedTime(0);
    }
    const videoBlob = new File([blob], "demo.mp4", { type: "video/mp4" });
    console.log(videoBlob, "VideoBlob");
    const formData = new FormData();
    formData.append("videoFile", videoBlob);
    formData.append("teacherId", userData.teacherId);
    formData.append("studentId", userData.id);
    formData.append("studentUUID", userData.uuid);
    formData.append("assignmentId", localStorage.getItem("assesmentId"));
    formData.append("assignmentName", localStorage.getItem("assesment"));
    // formData.append("videoDuration", ref.current);

    const videoObjectUrl = URL.createObjectURL(videoBlob);

    dispatch(aiReportRequestAction(formData));
    localStorage.removeItem("assesment");
    localStorage.removeItem("assesmentId");
    notify(
      "File uploded successfully for AI report please wait for 3 to 5 min "
    );
    navigate("/student/dashboard");
    setRoutePage(true);
  };

  const handleDeleteRecording = (index) => {
    const urlsCopy = [...mediaBlobUrls];
    urlsCopy.splice(index, 1);
    setMediaBlobUrls(urlsCopy);
  };

  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    video: true,
    audio: true,
    onStop: handleStopRecording,
  });

  const handleStartRecording = () => {
    setElapsedTime(0);

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.log("Error accessing camera and microphone", error);
      });
    setShowPreview(true);
    setStartTime(Date.now());
    startRecording();
  };

  const handleStopPreview = () => {
    setShowPreview(false);

    setElapsedTime(0);
    clearInterval(timerInterval);
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      setElapsedTime(0);
    }
    stopRecording();
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time - minutes * 60000) / 1000);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="videopreview">
      {showPreview ? (
        <div>
          <video
            style={{ width: "100%", height: "60vh" }}
            ref={videoRef}
            muted
            autoPlay
          />
          <div className="timeformat">
            Start time: {formatTime(elapsedTime)}
          </div>
        </div>
      ) : (
        <div className="videostore">
          <img src="/images/student/record.svg" alt="recordimage" />
          <p></p>
        </div>
      )}
      {/* {mediaBlobUrls.map((blobUrl, index) => (
        <div className="videostore" key={index}>
         
          <video style={{width:"222px"}} src={blobUrl} controls autoPlay loop />
          <button type="button" className="btn btn-danger"  onClick={() => handleDeleteRecording(index)}>
            Delete Recording
          </button>
        </div>
      ))} */}
      <div className="recordbtn">
        {status !== "recording" ? (
          <img
            title="start"
            className="recordimg"
            onClick={handleStartRecording}
            src="/images/student/RecordButton.png"
            alt="start"
          />
        ) : (
          ""
        )}

        {status == "recording" ? (
          <img
            title="stop"
            className="recordimg recordstop"
            onClick={stopRecording}
            src="/images/student/Stop.png"
            alt="stop"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Video;
