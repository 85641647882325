import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="wrap footer-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2>
                <span>About US</span>
              </h2>
              <p>
                Language Learning Platform powered by Artificial Intelligence
              </p>
              <a href="#" className="know-more">
                Know More
              </a>
            </div>

            <div className="col-md-3">
              <h2>
                <span>Featured links</span>
              </h2>
              <ul>
                <Link to="/gallery">
                  <li>
                    <a href="#gallery">Gallery</a>
                  </li>
                </Link>
                <Link to="/blog">
                  <li>
                    <a href="">Blogs</a>
                  </li>
                </Link>
                {/* <Link to="/contactus">
                  <li onClick={props.showCartHandler}>
                    <a href="#" onClick={props.showCartHandler}>
                      Contact us
                    </a>
                  </li>
                </Link> */}
              </ul>
            </div>

            <div className="col-md-3">
              <h2>
                <span>Contact Info</span>
              </h2>
              <ul>
                <li>
                  <a href="tel:0000000000">+910000000000</a>
                </li>
                <li>
                  <a href="mailto:support@mylanguage.ai">
                    support@shikshamitr.co
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <h2>
                <span>Social media</span>
              </h2>
              <div className="socialmedia">
                <ul>
                  <li>
                    <a
                      href="#"
                      target="_blank"
                    >
                      <img src="images/fb.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      target="_blank"
                    >
                      <img src="images/insta.png" alt="" width="20" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      target="_blank"
                    >
                      <img src="images/yt.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      target="_blank"
                    >
                      <img src="images/in.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="wrap footer-note">
        <div className="container">
          <div className="row">
            <ul>
              <li>Shikshamitr.CO 2024 All Right Reserved</li>
              <Link to="/privacy">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </Link>

              <Link to="/term-condition">
                <li>
                  <a href="#">Terms and conditions apply</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
