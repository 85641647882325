import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllReportsAssessment from "../component/assessment/AllReportsAssessment";
import AssignedAssessment from "../component/assessment/AssignedAssessment";
import CompletedAssessment from "../component/assessment/CompletedAssessment";
import CreateAssessmentModal from "../component/assessment/CreateAssessmentModal";
import SelfAssement from "../component/assessment/SelfAssement";
function Assessment() {
  let [taber, settaber] = useState(1);
  let [showPop, setShowPop] = useState(false);

  return (
    <>
      <div className="middle-section">
        <div className="container-fluid">
          {/* <div className="row">
              <div className="col-md-12 top-search col-md-6 col-sm-12 col-lg-6 col-xl-4">
                <form>
                  <div className="form-row">
                    <div className="input-box">
                      <select>
                        <option>Select Assessment Type</option>
                      </select>
                    </div>
                    <div className="input-box">
                      <select>
                      <option>Select Purpose </option>
                      </select>
                    </div>
                    <div className="input-box">
                      <input type="date" />
                    </div>
                    <div className="to">
                    <span>TO</span>
                    </div>
                    <div className="input-box">
                      <input type="date" />
                      </div>
                    <button className="submit-btn">Search</button>
                    <button className="submit-refresh"></button>
                  </div>
                </form>
                </div>
              </div> */}
          <h1 className="pagetitle">Assessment</h1>
          <hr className="dashboard-hr" />
          <div className="row">
            <div col-md-12>
              <ul className="tabs">
                <li>
                  <span
                    className={taber === 0 ? "active" : null}
                    role="button"
                    onClick={() => settaber(0)}
                  >
                    Self
                  </span>
                </li>
                <li>
                  <span
                    className={taber === 1 ? "active" : null}
                    role="button"
                    onClick={() => settaber(1)}
                  >
                    Assigned
                  </span>
                </li>
                <li>
                  <span
                    className={taber === 2 ? "active" : null}
                    role="button"
                    onClick={() => settaber(2)}
                  >
                    Completed
                  </span>
                </li>
                <li>
                  <span
                    className={taber === 3 ? "active" : null}
                    role="button"
                    onClick={() => settaber(3)}
                  >
                    All Reports
                  </span>
                </li>
                <li>
                  <span
                    className="btn-m active"
                    onClick={() => setShowPop(!showPop)}
                    role="button"
                  >
                    Create Assessment
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className=" assessment-tabs">
            {taber === 0 ? <SelfAssement /> : null}
            {taber === 1 ? <AssignedAssessment /> : null}
            {taber === 2 ? <CompletedAssessment /> : null}
            {taber === 3 ? <AllReportsAssessment /> : null}
          </div>
        </div>
      </div>

      <div
        className={`overlay ${showPop ? "show-popup" : null}`}
        onClick={() => setShowPop(!showPop)}
      ></div>
      <div className={`form-popup ${showPop ? "show-popup" : null}`}>
        <CreateAssessmentModal setShowPop={setShowPop} showPop={showPop} />
      </div>
    </>
  );
}

export default Assessment;
