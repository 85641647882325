import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify } from "../../../utils/utils";

export const addUserAction = createAsyncThunk(
  "addUser",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/user/signup`,
        {
          method: "POST",
          body: data,
          //   headers: {
          //     "x-access-token": `${token}`,
          //   },
        }
      );

      const processedData = await response.json();

      if (response.status === 200) {
        return fulfillWithValue(processedData);
      } else {
        return rejectWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addUserSlice = createSlice({
  name: "addUser",
  initialState: {
    user: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // addUser Request Handling
    [addUserAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [addUserAction.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.success = true;
      state.error = false;
      notify(payload.message);
    },
    [addUserAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default addUserSlice.reducer;
