import React from 'react'
import '../../styles/recomendations.css'

const RecomendationCard = ({image}) => {
  return (
    <div  className='recard'>
    <div className='recimg'>
      <img src={image} alt='circle'/>
    </div>
    <div className='movecard'>
      <p className='movepara'>Moves</p>
      <p className='pararec'>Need To Focus On Body Moments</p>
    </div>
  </div>
  )
}

export default RecomendationCard