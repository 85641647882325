import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import AccordianComponent from "../dashboard/AccordianComponent";
import Loader from "./Loader";

const CourseFullDetail = ({
  data,
  num,
  openAccordian,
  openAcc,
  openNext,
  openPrev,
  finalAccordian,
  setError,
}) => {
  const location = useLocation();
  const currentCourseId = location.pathname.split("/")[3];
  const [content, setContent] = useState([]);
  const { getCourseDetail } = useSelector((state) => state);

  async function callApi() {
    try {
      const response = await fetch(
        `https://developers.teachable.com/v1/courses/${currentCourseId}/lectures/${data}`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            ApiKey: `HX6GGtrLCIkmpPXUye4xgqzHndxC21oe`,
          },
        }
      );
      const processedData = await response.json();
      if (response.status !== 200) {
        setError(true);
      }
      setContent(processedData.lecture);
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }

  // Getting Lecture here
  useEffect(() => {
    getCourseDetail.success && callApi();
  }, [getCourseDetail.success]);

  return (
    <>
      {/* <h1>id --- {data}</h1>
      <h2>Name --- {content?.name}</h2>
      {content?.attachments?.map((content, index) => {
        return <div>{parse(content.text)} </div>;
      })} */}
      <AccordianComponent
        openNext={() => openNext(num)}
        openPrev={() => openPrev(num)}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name={content?.name ? content?.name : null}
        oaId={num}
        click={() => openAcc(num)}
      >
        {!content.name ? (
          <Loader />
        ) : (
          <div className="accordian-content">
            {content?.attachments?.map((content, index) => {
              if (content?.kind === "pdf_embed") {
                return <iframe src={content?.url} width="100%" height="500" />;
              } else if (content?.kind === "quiz") {
                return content?.quiz?.questions.map(
                  (questions, index, arrayRef) => {
                    <h3>QUESTIONS</h3>;
                    return (
                      <div className="question-answers">
                        <h4>{questions.question}</h4>{" "}
                        <textarea name="textarea"></textarea>
                      </div>
                    );
                  }
                );
              } else if (content?.kind === "video") {
                return (
                  <video width="100%" controls>
                    <source src={content?.url} type="video/mp4" />
                  </video>
                );
              } else if (content?.kind === "text") {
                return <div>{parse(content?.text)} </div>;
              } else if (content?.kind === "audio") {
                return (
                  <audio controls>
                    <source src={content?.url} type="audio/mpeg" />
                    Your browser does not support the html audio tag.
                  </audio>
                );
              }
            })}
          </div>
        )}
      </AccordianComponent>
    </>
  );
};

export default CourseFullDetail;
