import React,{useState} from 'react'
import '../../styles/Summary.css'
import minus from '../../utils/Images/minus.svg'


const CardWrapper = ({ headerValue, image1, children}) => {
    const [showCard, setShowCard] = useState(true);
    const [collapse, setCollapse] = useState("-");
    
    const cardCollapse = () =>{
        if(collapse=='-'){
            setShowCard(false);
            setCollapse("+");
        }
        else{
            setCollapse('-');
            setShowCard(true);
        }
    }
    return (
        <div className='criteria'>
            <div className='criteriainner' >
                <div className='imagewrap'>
                    <img src={`${image1}`} alt='iamges'/>
                    <p>{headerValue} </p>
                </div>
                <div className='collapsewrap'>
                    <p className='collapseicon' onClick={cardCollapse}>{collapse}</p>
                </div>
            </div>
            {showCard && children}
        </div>
    )
}

export default CardWrapper