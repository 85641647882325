import React from "react";
import DashHeader from "../../dashboard/DashHeader";
import { Outlet } from "react-router-dom";
import RightBar from "../../dashboard/RightBar";
import LeftBar from "../../dashboard/LeftBar";
import { useSelector } from "react-redux";
import { Fragment } from "react";

const StudentLayout = () => {
  const { toggleMenu } = useSelector((state) => state);
  return (
    <Fragment>
      <DashHeader />
      <section className={toggleMenu.value ? "wrapper" : "wrapper wrap-new"}>
        <LeftBar />
        <Outlet />
      </section>
    </Fragment>
  );
};

export default StudentLayout;
