import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUserAction } from "../redux/slice/user/updateCurrentUserSlice";
import { indianStates } from "../utils/data";
import { getUserLocal } from "../utils/utils";

function ProfileDetails() {
  const { getUser } = useSelector((state) => state);
  // const [genderValue, setGenderValue] = useState(1);
  const [redirectFlag, setRedirectFlag] = useState(false);
  const dispatch = useDispatch();
  const userDetail = getUserLocal();

  const [user, setUser] = useState({
    address: "",
    city: "",
    country: "",
    email: "",
    fname: "",
    lname: "",
    mnumber: "",
    pincode: "",
    state: "",
    profileImg: "",
    aadharNo: "",
    panNo: "",
    department: "",
  });

  // Handling form
  const initialValues = user;
  let {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: getUser?.userData || {
      aadharNo: "",
      address: "",
      city: "",
      country: "",
      department: "",
      dob: "",
      email: "",
      fname: "",
      gender: "",
      lname: "",
      mnumber: "",
      panNo: "",
      pincode: "",
      profileImg: "",
      roles: "",
      state: "",
      status: "",
      uuid: "",
    },
    // validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("fname", values.fname);
      formData.append("lname", values.lname || "");
      formData.append("email", values.email);
      formData.append("mnumber", values.mnumber || "");
      formData.append("address", values.address || "");
      formData.append("state", values.state);
      formData.append("city", values.city || "");
      formData.append("dob", values.dob);
      formData.append("pincode", values.pincode || "");
      formData.append("country", values.country);
      formData.append("gender", values.gender);
      formData.append("profileImg", values.profileImg);
      formData.append("status", "1");
      formData.append("panNo", values.panNo || "");
      formData.append("department", values?.department || 1);
      formData.append("aadharNo", values?.aadharNo || "");

      const data = {
        id: userDetail.id,
        token: userDetail.accessToken,
        formData: formData,
      };

      dispatch(updateCurrentUserAction(data));
      // action.resetForm();
      // setRedirectFlag(true);
    },
  });

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Profile Details</h1>
        <hr className="dashboard-hr" />
        {getUser.loading ? (
          "Loading..."
        ) : (
          <div className="row tab-platform">
            <div className="col-md-12 mb-4">
              <form className="form-detail" onSubmit={handleSubmit}>
                <div className="gender-box" role="group">
                  {values?.gender === "1" ? (
                    <div className="gender male-box">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="1"
                          checked={values?.gender === "1"}
                          disabled
                        />
                        <span>
                          <img src="/images/male.svg" alt="male" /> Male
                        </span>
                      </label>
                    </div>
                  ) : (
                    <div className="gender female-box">
                      <label>
                        <input
                          type="radio"
                          value="2"
                          checked={values?.gender === "2"}
                          name="gender"
                        />
                        <span>
                          <img src="/images/female.svg" alt="female" /> Female
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                <div className="profile-form-row">
                  <div>
                    <label htmlFor="fname" className="form-label">
                      First Name
                    </label>
                    <input
                      type="name"
                      className="form-control"
                      id="fname"
                      name="fname"
                      placeholder="First Name"
                      value={values?.fname}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="lname" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="name"
                      className="form-control"
                      id="lname"
                      name="lname"
                      placeholder="Last Name"
                      value={values?.lname}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="emai1" className="form-label">
                      Email
                    </label>
                    <input
                      type="emai1"
                      className="form-control"
                      id="emai1"
                      name="email"
                      placeholder="Student Email"
                      value={values?.email}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="dateOfBirth" className="form-label">
                      Date of birth
                    </label>
                    <input
                      type="dateOfBirth"
                      className="form-control "
                      id="dateOfBirth"
                      placeholder="10-12-1990"
                      name="dob"
                      value={values?.dob}
                      onChange={handleChange}
                      // disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="dateOfBirth" className="form-label">
                      Profile Picture
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="profileImg"
                      placeholder="Profile Image"
                      name="profileImg"
                      onChange={(e) => {
                        setFieldValue("profileImg", e.currentTarget.files[0]);
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="mobile" className="form-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      name="mnumber"
                      placeholder="+91 9999 999 999"
                      value={values?.mnumber}
                      onChange={handleChange}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="mobile" className="form-label">
                      Aadhaar No.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      name="aadharNo"
                      placeholder="Aadhaar Number"
                      value={values?.aadharNo}
                      onChange={handleChange}
                      // disabled
                    />
                  </div>
                </div>

                <h2>Address</h2>
                <div className="profile-form-row profile-form-column">
                  <div>
                    <label htmlFor="street-address" className="form-label">
                      Street Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="street-address"
                      name="address"
                      placeholder="Address"
                      value={values?.address || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      placeholder="City"
                      value={values?.city}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <select
                      className="form-control"
                      id="country"
                      name="country"
                      value={values?.country}
                      onChange={handleChange}
                    >
                      <option value="India">India</option>
                      {/* <option value="US">US</option>
                          <option value="UK">UK</option> */}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="pincode" className="form-label">
                      Pincode
                    </label>
                    <input
                      name="pincode"
                      type="text"
                      className="form-control"
                      id="pincode"
                      placeholder="pincode"
                      value={values?.pincode}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-control"
                      id="state"
                      value={values?.state}
                      onChange={handleChange}
                      name="state"
                    >
                      {indianStates.map((state, index) => {
                        return (
                          <option value={state} key={index}>
                            {state}
                          </option>
                        );
                      })}

                      {/* <option>US</option>
                          <option>UK</option> */}
                    </select>
                  </div>
                </div>
                <div className="profile-form-submit">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileDetails;
