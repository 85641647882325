import React from "react";

const InsightVideo = ({ movements, handleTimeClick }) => {
  const high_pitch = JSON.parse(
    movements?.voice_pitch && movements?.voice_pitch
  );
  return (
    <div>
      <div className="contentdesc firstDesc">
        <div className="heading">
          <div className="playimg" style={{ background: "transparent" }}>
            <img src="/images/icons/postiveIcon.svg" />
          </div>
          <p>Positive</p>
        </div>
        <div className="movements">
          <ul>
            {movements?.hand_movement_1?.movement_timings.map((time, index) => {
              return (
                <li key={index}>
                  <button onClick={() => handleTimeClick(time)}>
                    <img
                      src="/images/icons/playbutton.svg"
                      alt="play btn"
                      width="15px"
                    />
                  </button>
                  <span>Clip : {index + 1} </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* <div className="contentdesc">
        <div className="playimg" onClick={() => handleTimeClick()}>
          <img src="/images/student/play.svg" />
        </div>

        <p>Eye Contact</p>
      </div> */}
      <div className="contentdesc">
        <div
          className="playimg"
          onClick={() => handleTimeClick(high_pitch?.max_pitch_timing)}
        >
          <img src="/images/student/play.svg" />
        </div>

        <p>Voice</p>
      </div>
      {/* <div className="contentdesc">
        <div className="playimg">
          <img src="/images/student/play.svg" />
        </div>

        <p>Objects Detected</p>
        {movements?.object_detection?.detected_objects?.map((object) => {
          return <p>{object}</p>;
        })}
      </div> */}

      <div className="contentdesc firstDesc">
        <div className="heading">
          <div className="playimg" style={{ background: "transparent" }}>
            <img src="/images/icons/postiveIcon.svg" />
          </div>
          <p>Objects Detected</p>
        </div>
        <div className="movements">
          <ul>
            {movements?.object_detection?.detected_objects?.map(
              (object, index) => {
                return (
                  <li key={index}>
                    <button>
                      <img
                        src="/images/icons/playbutton.svg"
                        alt="play btn"
                        width="15px"
                      />
                    </button>
                    <span>{object} </span>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InsightVideo;
