import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify } from "../../../utils/utils";

export const aiReportRequestAction = createAsyncThunk(
  "aiReportRequest",
  async (formData, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REPORT_API}uploadfile`,
        {
          method: "POST",
          headers: {
            "ai-secret-key": `$2a$10$N95XNPMLi0egfF3K9S8q/uUagvyqtpVTLL8Sx8BIfQCo9aaw9/hIm`,
          },
          body: formData,
        }
      );

      const processedData = await response.json();

      if (response.status === 200) {
        return fulfillWithValue(processedData);
      } else {
        return rejectWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aiReportRequestSlice = createSlice({
  name: "aiReportRequest",
  initialState: {
    report: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // aiReportRequest Request Handling
    [aiReportRequestAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [aiReportRequestAction.fulfilled]: (state, { payload }) => {
      state.report = payload;
      state.loading = false;
      state.success = true;
      state.error = false;
      notify(payload.message);
    },
    [aiReportRequestAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default aiReportRequestSlice.reducer;
