import React from "react";

const EnterDetails = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <div className="enter-details-container">
      <div className="form">
        <div className="input">
          {/* Name */}
          <label htmlFor="name">
            Assessment Name<span>*</span>
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter Assessment Name"
            name="assessmentName"
            value={values.assessmentName}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={1}
            autoFocus
          />
          {errors.assessmentName && touched.assessmentName ? (
            <div className="invalid-msg">{errors.assessmentName}</div>
          ) : null}
        </div>

        {/* Assessment Name */}
        <div className="input">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="assessmentDesc"
            id="description"
            placeholder="Describe here"
            value={values.assessmentDesc}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={2}
          />
        </div>

        {/* Start Date */}
        <div className="input">
          <label htmlFor="started">
            Start Date<span>*</span>{" "}
          </label>
          <input
            type="date"
            name="startDate"
            id="started"
            value={values.startDate}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={3}
          />
          {errors.startDate && touched.startDate ? (
            <div className="invalid-msg">{errors.startDate}</div>
          ) : null}
        </div>

        {/* End Date */}
        <div className="input">
          <label htmlFor="ended">
            End Date<span>*</span>{" "}
          </label>
          <input
            type="date"
            name="endDate"
            id="ended"
            value={values.endDate}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={4}
          />
          {errors.endDate && touched.endDate ? (
            <div className="invalid-msg">{errors.endDate}</div>
          ) : null}
        </div>

        {/* Purpose */}
        <div className="input">
          <label htmlFor="assessmentPurpose">
            Purpose<span>*</span>{" "}
          </label>
          <select
            type="text"
            id="assessmentPurpose"
            name="assessmentPurpose"
            value={values.assessmentPurpose}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={5}
          >
            <option>Select Purpose</option>
            <option value="1">Speaking</option>
          </select>
          {errors.assessmentPurpose && touched.assessmentPurpose ? (
            <div className="invalid-msg">{errors.assessmentPurpose}</div>
          ) : null}
        </div>

        {/* AI Level */}
        <div className="input">
          <label htmlFor="ai-level">
            AI Level<span>*</span>
          </label>
          <select
            type="text"
            id="ai-level"
            name="aiLevel"
            value={values.aiLevel}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={6}
          >
            <option>Select Level</option>
            <option value="1">Basic</option>
            <option value="2">Intermediate</option>
            <option value="3">Advance</option>
          </select>
          {errors.aiLevel && touched.aiLevel ? (
            <div className="invalid-msg">{errors.aiLevel}</div>
          ) : null}
        </div>

        {/* Assessment response type */}
        <div className="input">
          <label htmlFor="assessmentResponseType">
            Assessment Response type<span>*</span>
          </label>
          <select
            type="text"
            id="assessmentResponseType"
            name="assessmentResponseType"
            value={values.assessmentResponseType}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex={7}
          >
            <option>Select Assessment Response type</option>
            <option value="1">Video</option>
          </select>
          {errors.assessmentResponseType && touched.assessmentResponseType ? (
            <div className="invalid-msg">{errors.assessmentResponseType}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EnterDetails;
