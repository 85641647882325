import React from "react";
import Modal from "../Modal";
import classes from "../../styles/Confirm.module.css";

const Confirm = ({ question, setConfirm, onClose, dispatch }) => {
  return (
    <Modal
      onClose={onClose}
      modal={classes.modal}
      content={classes.content}
      backdrop={classes.backdrop}
    >
      <div className={classes.closebtnContainer}>
        <button className={classes.close} onClick={onClose}>
          X
        </button>
      </div>
      <h2 className={classes.h2}>{question}</h2>
      <div className={classes.btn}>
        <button
          onClick={() => {
            dispatch();
            onClose();
          }}
        >
          Yes
        </button>
        <button
          onClick={() => {
            setConfirm(false);
            onClose();
          }}
        >
          No
        </button>
      </div>
    </Modal>
  );
};

export default Confirm;
