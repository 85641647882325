import React, { useEffect, useState } from "react";
import AssesmentComponent from "./AssesmentComponent";
import "../styles/teacherDashBoard.css";
import ScoreEducatorTable from "../component/educator/ScoreEducatorTable";
import PieChart from "../component/educator/PieChart";
import "../styles/searchScore.css";
import LineChart from "../component/educator/LineChart";
import BarChart from "../component/educator/BarChart";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../utils/utils";
import { getTeacherReportAction } from "../redux/slice/teacher/getTeacherReportSlice";
import { Link } from "react-router-dom";
import useCheckSize from "../hooks/useCheckSize";
import { getStudentsAction } from "../redux/slice/user/getStudentsSlice";
import { getAllTeacherAsmtsAction } from "../redux/slice/teacherAssessment/getAllTeacherAsmtsSlice";
import { getPracticeTeacherAsmtsAction } from "../redux/slice/teacherAssessment/getPraticeTeacherAsmtsSlice";
import { getAssignedTeacherAsmtsAction } from "../redux/slice/teacherAssessment/getAssignedTeacherAsmtsSlice";

const TeacherDashboard = () => {
  const size = useCheckSize();

  const {
    getStudents,
    getAllTeacherAsmts,
    getPracticeTeacherAsmts,
    getAssignedTeacherAsmts,
    getTeacherReport,
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [assesmentComponent, setAssesmentComponent] = useState([]);
  const [teacherReport, setTeacherReport] = useState([]);
  const [students, setStudents] = useState([]);
  const [datao, setDataO] = useState([]);

  const token = getToken();
  const teacherId = JSON.parse(localStorage.getItem("user"));

  // Getting all reports
  useEffect(() => {
    dispatch(getTeacherReportAction(data));
    dispatch(getAllTeacherAsmtsAction(token));
    dispatch(getPracticeTeacherAsmtsAction(token));
    dispatch(getAssignedTeacherAsmtsAction(token));
    dispatch(getStudentsAction({ token, pageNumber: 0 }));
  }, []);

  useEffect(() => {
    setStudents(getStudents.users);
  }, [getStudents.success]);

  const data = {
    teacherId: teacherId.id,
    token,
  };

  // setting all reports
  useEffect(() => {
    setTeacherReport(getTeacherReport.assessment);
  }, [getTeacherReport.success]);

  // pairing students and report
  useEffect(() => {
    let teacherReport2 = teacherReport?.map((report) => {
      let student = students?.dataItems?.find((std) => {
        return std.id === report.studentId;
      });
      return {
        ...report,
        fname: student?.fname,
        lname: student?.lname,
        email: student?.email,
        mnumber: student?.mnumber,
      };
    });
    setDataO(teacherReport2);
  }, [teacherReport, students]);

  useEffect(() => {
    setAssesmentComponent([
      {
        themeClass: "",
        assesmentNumber: getAllTeacherAsmts.assessments?.totalItems || "NA",
        assesmentType: "Total Assesments",
        assesmentImage: "/images/total-assesments.svg",
      },
      {
        themeClass: "assesment-component-yellow",
        assesmentNumber: getPracticeTeacherAsmts.assessments?.totalItems || "0",
        assesmentType: "Practice Assesments",
        assesmentImage: "/images/practice-assesments.svg",
      },
      {
        themeClass: "assesment-component-green",
        assesmentNumber: getAssignedTeacherAsmts.assessments?.totalItems || "0",
        assesmentType: "Assigned Assesments",
        assesmentImage: "/images/total-assesments-green.svg",
      },
      {
        themeClass: "assesment-component-red",
        assesmentNumber: getStudents.users?.totalItems || "0",
        assesmentType: "Total Students",
        assesmentImage: "/images/total-assesments-red.svg",
      },
    ]);
  }, [
    getAllTeacherAsmts.success,
    getPracticeTeacherAsmts.success,
    getAssignedTeacherAsmts.success,
    getStudents.success,
  ]);

  return (
    <div className="right-full-section">
      <div className="assesments-container">
        {assesmentComponent.map((e, i) => {
          return (
            <AssesmentComponent
              key={i}
              themeClass={e.themeClass}
              assesmentNumber={e.assesmentNumber}
              assesmentType={e.assesmentType}
              assesmentImage={e.assesmentImage}
            />
          );
        })}
      </div>
      <div className="teacherboard_sec">
        <div className="teacherboard">
          <div className="teacherinnerbox"></div>
          <div className="teacherinnerbox_padd">
            <div className="scoreboard">
              <div className="scoreinnerbox" style={{ position: "relative" }}>
                <p className="scorepara">Score Analysis</p>
              </div>

              <div className="scorebox">
                <div className="piechart">
                  <PieChart />
                </div>

                <div className="tabl">
                  <table className="table table-striped">
                    <thead>
                      <tr className="align-middle rowstyle">
                        <th scope="col">Sr No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        {size > 768 ? (
                          <th scope="col">Assessment name</th>
                        ) : (
                          <th scope="col">Asst.name</th>
                        )}
                        <th scope="col">Score</th>
                        <th scope="col">Report</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datao?.length > 0 ? (
                        datao.map((student, index) => {
                          return (
                            <tr className="align-middle rowstyle">
                              <th scope="row">{index + 1}</th>
                              <td>
                                {student?.fname} {student?.lname}
                              </td>
                              <td>{student?.email}</td>
                              <td
                                // style={{ border: "2px solid" }}
                                className="w-25"
                              >
                                {student?.assignmentName}
                              </td>
                              <td>{Math.floor(student?.totalAverage)}%</td>
                              <td>
                                <Link
                                  to={`/educator/report-detail/${student?.studentId}/${student.id}`}
                                >
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "#c8aace",
                                      color: "#fff",
                                    }}
                                    className="btn "
                                  >
                                    View
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <p className="noreport">No Data Available</p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="chartbox">
          <div className="avgscore">
            <div className="abginnerbox">
              <p className="avgpara">Average Score per Assesment</p>
            </div>
            <BarChart />
          </div>
          <div className="avgscore">
            <div className="abginnerbox">
              <p className="avgpara">Average Score per Assesment</p>
            </div>
            <LineChart />
          </div>
        </div> */}
            <div className="allscoreouter">
              <div className="allscoreinner">
                <p className="allscorepara">All Scores</p>
                {/* <input className="inputbox" type="text" placeholder="Search" />
            <img
              className="searchicon"
              src="/images/educator/searchicon.svg"
              alt="searchicon"
            />
            <select name="cars" id="cars">
              <option className="values">Select Department</option>
              <option className="values" value="volvo">
                CSE
              </option>
              <option className="values" value="saab">
                Mechanical
              </option>
              <option className="values" value="opel">
                BCA
              </option>
              <option className="values" value="audi">
                Arts
              </option>
            </select>
            <div className="refreshcontainer">
              <img
                className="refreshicon"
                src="/images/educator/refreshicon.svg"
                alt="refreshicon"
              />
            </div> */}
              </div>
              <div className="tabls">
                <table className="table table-striped ">
                  <thead>
                    <tr className="align-middle rowstyle">
                      <th scope="col">Sr No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      {size > 768 ? (
                        <th scope="col">Mobile no</th>
                      ) : (
                        <th scope="col">Mob.no</th>
                      )}
                      {size > 768 ? (
                        <th scope="col">Assessment name</th>
                      ) : (
                        <th scope="col">Asst.name</th>
                      )}
                      <th scope="col">Score</th>
                      <th scope="col">Report</th>
                      {/* <th scope="col"> Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {datao?.length > 0 ? (
                      datao.map((student, index) => {
                        return (
                          <tr className="align-middle rowstyle">
                            <th scope="row">{index + 1}</th>
                            <td>
                              {student?.fname} {student?.lname}
                            </td>
                            <td>{student?.email}</td>
                            <td>{student?.mnumber}</td>
                            <td>{student?.assignmentName}</td>
                            <td>{Math.floor(student?.totalAverage)}%</td>
                            <td>
                              <Link
                                to={`/educator/report-detail/${student?.studentId}/${student.id}`}
                              >
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#c8aace",
                                    color: "#fff",
                                  }}
                                  className="btn "
                                >
                                  View
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="noreporttwo">No Data Available</p>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <ScoreEducatorTable style={{ marginTop: "-20px" }} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherDashboard;
