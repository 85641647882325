import React from "react";

const Content = ({ movements, handleTimeClick }) => {
  return (
    <div>
      {movements?.language?.top_four_words_timestamps?.map((word, index) => {
        return (
          <div className="contentdesc" key={index}>
            <div className="playimg" onClick={() => handleTimeClick(word[1])}>
              <img src="/images/student/play.svg" />
            </div>
            <p>{word[0]?.toUpperCase()}</p>
          </div>
        );
      })}
      {/* <div className='contentdesc'>
                <div className='playimg'>

                    <img src='/images/student/play.svg' />
                </div>

                <p>Listening : Lorem Ipsum</p>
            </div>
            <div className='contentdesc'>
                <div className='playimg'>

                    <img src='/images/student/play.svg' />
                </div>

                <p>Writing : Lorem Ipsum</p>
            </div> */}
    </div>
  );
};

export default Content;
