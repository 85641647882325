import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/singleUser.css";
import UserAdded from "../UserAdded";
import { useFormik } from "formik";
import { addStudentSchema } from "../../utils/schema";
import { indianStates } from "../../utils/data";
import { getUserLocal } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addUserAction } from "../../redux/slice/user/addUserSlice";

const SingleUser = () => {
  const [cartIsShown, setCartIsShown] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);
  const { getDepartments, addUser } = useSelector((state) => state);
  const [departmentsList, setDepartmentsList] = useState([]);
  const navigate = useNavigate();
  const currentUser = getUserLocal();
  const dispatch = useDispatch();

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  useEffect(() => {
    setDepartmentsList(getDepartments.departments);
  }, [getDepartments.success]);

  useEffect(() => {
    routeFlag && navigate("/educator/Manage");
  }, [addUser.success]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      mnumber: "",
      profileImg: "",
      gender: "",
      dob: "",
      departmentName: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      aadharNo: "",
      panNo: "",
      status: "1",
    },
    validationSchema: addStudentSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const newdob = values?.dob
        .split("-")
        .reverse()
        .join()
        .replaceAll(",", "-");
      const formData = new FormData();
      formData.append("fname", values.fname);
      formData.append("lname", values.lname);
      formData.append("email", values.email);
      formData.append("mnumber", values.mnumber);
      formData.append("profileImg", values.profileImg);
      formData.append("gender", values.gender);
      formData.append("dob", newdob);
      formData.append("country", values.country);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("pincode", values.pincode);
      formData.append("status", "1");
      formData.append("aadharNo", values.aadharNo);
      formData.append("panNo", values.panNo);
      formData.append("department", values.departmentName);
      formData.append("teacherId", currentUser.id);
      formData.append("roles[0]", "student");
      // showCartHandler();
      dispatch(addUserAction(formData));
      setRouteFlag(true);
    },
  });

  return (
    <form className="singleuserwrapper" onSubmit={handleSubmit}>
      {/* first name */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldsval">First Name</p>
          <p className="req">*</p>
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter user's first name"
            name="fname"
            value={values.fname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {errors.fname && touched.fname ? (
          <div className="invalid-msg">{errors.fname}</div>
        ) : null}
      </div>

      {/* last name */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldsval">Last Name</p>
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter user's last name"
            name="lname"
            value={values.lname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Email */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldsemail">Email</p>
          <p className="req">*</p>
        </div>
        <div className="inputstyle">
          <input
            type="email"
            placeholder="Enter email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email ? (
            <div className="invalid-msg">{errors.email}</div>
          ) : null}
        </div>
      </div>

      {/* Mobile */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldsemail">Mobile</p>
          <p className="req">*</p>
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter Mobile"
            name="mnumber"
            value={values.mnumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.mnumber && touched.mnumber ? (
            <div className="invalid-msg">{errors.mnumber}</div>
          ) : null}
        </div>
      </div>

      {/* Profile Image */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fields">Profile Image</p>
          {/* <p className="req">*</p> */}
        </div>
        <div className="inputstyle">
          <input
            type="file"
            name="profileImg"
            onChange={(e) =>
              setFieldValue("profileImg", e.currentTarget.files[0])
            }
            onBlur={handleBlur}
          />
          {errors.profileImg && touched.profileImg ? (
            <div className="text-danger">{errors.profileImg}</div>
          ) : null}
        </div>
      </div>

      {/* Gender */}
      <div className="userdetail">
        <div className="label gender">
          <div className="labelreq">
            <label className="fields" for="html">
              Gender
            </label>
            <p className="req">*</p>
          </div>

          <select
            className="textfields"
            id="level"
            name="gender"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Gender</option>
            <option className="values" value="1">
              Male
            </option>
            <option className="values" value="2">
              Female
            </option>
          </select>
          {errors.gender && touched.gender ? (
            <div className="invalid-msg">{errors.gender}</div>
          ) : null}
        </div>
      </div>

      {/* Department */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldsdate">Date Of Birth</p>
          <p className="req">*</p>
        </div>
        <div className="inputstyle">
          <input
            type="date"
            name="dob"
            value={values.dob}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.dob && touched.dob ? (
            <div className="invalid-msg">{errors.dob}</div>
          ) : null}
        </div>
      </div>

      {/* Department */}
      <div className="userdetail">
      <div className="label">
        <div className="labelreq">
          <label className="fields" for="html">
            Department
          </label>
          <p className="req">*</p>
        </div>

        <select
          className="textfields"
          id="level"
          name="departmentName"
          value={values.departmentName}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option>Select Department</option>

          {departmentsList.map((department, index) => {
            return (
              <option value={department.id} key={index}>
                {department.departmentName}
              </option>
            );
          })}
        </select>
        {errors.departmentName && touched.departmentName ? (
          <div className="invalid-msg">{errors.departmentName}</div>
        ) : null}
      </div>
      </div>

      {/* Country */}
      <div className="userdetail">
      <div className="label">
        <div className="labelreq">
          <label className="fields" for="html">
            Country
          </label>
          {/* <p className="req">*</p> */}
        </div>
        <select
          className="textfields"
          id="level"
          name="country"
          value={values.country}
          onChange={handleChange}
        >
          <option>Country</option>
          <option className="values" value="India">
            India
          </option>
          {/* <option className="values" value="multiple">
            Multiple
          </option> */}
        </select>
      </div>
      </div>

      {/* State */}
      <div className="userdetail">
      <div className="label">
        <div className="labelreq">
          <label className="fields" for="html">
            State
          </label>
          {/* <p className="req">*</p> */}
        </div>
        <select
          className="textfields"
          id="level"
          name="state"
          value={values.state}
          onChange={handleChange}
        >
          <option>state</option>
          {indianStates.map((state, index) => {
            return (
              <option value={state} key={index}>
                {state}
              </option>
            );
          })}
        </select>
      </div>
      </div>

      {/* City */}
      {/* <div className="label">
        <div className="labelreq">
          <label className="fields" for="html">
            City
          </label>
          <p className="req">*</p>
        </div>
        <select
          className="textfields"
          id="level"
          name="fname"
          value={values.fname}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option className="values" value="single">
            Single
          </option>
          <option className="values" value="multiple">
            Multiple
          </option>
        </select>
      </div> */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fields">City</p>
          {/* <p className="req">*</p> */}
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter City"
            name="city"
            value={values.city}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Pincode */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fieldspin">Pincode</p>
          <p className="req">*</p>
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter Pincode"
            name="pincode"
            value={values.pincode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.pincode && touched.pincode ? (
            <div className="invalid-msg">{errors.pincode}</div>
          ) : null}
        </div>
      </div>

      {/* Aadhaar */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fields">Aadhaar</p>
          {/* <p className="req">*</p> */}
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter Aadhar"
            name="aadharNo"
            value={values.aadharNo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Pan Card */}
      <div className="userdetail">
        <div className="userabt">
          <p className="fields">Pan Card</p>
          {/* <p className="req">*</p> */}
        </div>
        <div className="inputstyle">
          <input
            type="text"
            placeholder="Enter PAN"
            name="panNo"
            value={values.panNo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Status */}
      {/* <div className="userdetail">
        <div className="switch form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            name="status"
            value={values.status}
            onChange={handleChange}
          />
        </div>
      </div> */}

      <div className="btnsingle">
        <Link to="/educator/Manage">
          <button type="button " className="btn btn-light prevbtn">
            Previous
          </button>
        </Link>
        <button type="submit" className="btn submitbtn">
          Submit
        </button>
      </div>
      {cartIsShown && (
        <UserAdded
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
        />
      )}
    </form>
  );
};

export default SingleUser;
