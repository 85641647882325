import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify, removeUserSession } from "../../../utils/utils";

export const addDepartmentAction = createAsyncThunk(
  "addDepartment",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { token, values } = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/vol/department`,
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${token}`,
          },
        }
      );

      const processedData = await response.json();

      if (response.status === 401) {
        removeUserSession();
        return rejectWithValue(processedData);
      } else if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addDepartmentSlice = createSlice({
  name: "addDepartment",
  initialState: {
    department: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // addDepartment Request Handling
    [addDepartmentAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [addDepartmentAction.fulfilled]: (state, { payload }) => {
      state.department = payload;
      state.loading = false;
      state.success = true;
      state.error = false;
      notify(payload.message);
    },
    [addDepartmentAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default addDepartmentSlice.reducer;
