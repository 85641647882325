import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashContainer from "../dashboard/DashContainer";
import LeftBar from "../dashboard/LeftBar";
import RightBar from "../dashboard/RightBar";
import { changePasswordAction } from "../redux/slice/auth/changePasswordSlice";
import { changePasswordSchema } from "../utils/schema";
import { getToken, getUserLocal } from "../utils/utils";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    confirmPassword: false,
    newPassword: false,
  });
  const dispatch = useDispatch();
  const [redirectFlag, setRedirectFlag] = useState(false);
  const navigate = useNavigate();
  const { changePassword } = useSelector((state) => state);
  const currentUser = getUserLocal();
  const user = getUserLocal();
  const user_role = user?.roles[0];
  const token = getToken();

  // Redirecting after changing password
  useEffect(() => {
    if (!changePassword.error && changePassword.success && redirectFlag) {
      navigate(
        `/${user_role === "ROLE_TEACHER" ? "educator" : "student"}/dashboard`
      );
    }
  }, [changePassword.success, changePassword.error]);

  // Handling form
  let { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: changePasswordSchema,
      onSubmit: (values, action) => {
        const data = { values, token, id: currentUser.id };
        dispatch(changePasswordAction(data));
        setRedirectFlag(true);
        // action.resetForm();
      },
    });

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Change Password</h1>
        <hr className="dashboard-hr" />
        <div className="row tab-platform">
          <div className="col-md-12 mb-4">
            <form className="form-detail" onSubmit={handleSubmit}>
              <div className="profile-form-row  profile-form-column">
                <div>
                  <label htmlFor="oldPassword" className="form-label">
                    Current Password
                  </label>
                  <input
                    className="form-control"
                    placeholder="Old password"
                    type={showPassword.oldPassword ? "text" : "password"}
                    name="oldPassword"
                    id="oldPassword"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {showPassword.oldPassword ? (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          oldPassword: !showPassword.oldPassword,
                        })
                      }
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          oldPassword: !showPassword.oldPassword,
                        })
                      }
                    />
                  )}
                  {errors.oldPassword && touched.oldPassword ? (
                    <div className="invalid-msg">{errors.oldPassword}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    className="form-control"
                    placeholder="New password"
                    type={showPassword.newPassword ? "text" : "password"}
                    name="newPassword"
                    id="newPassword"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {showPassword.newPassword ? (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          newPassword: !showPassword.newPassword,
                        })
                      }
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          newPassword: !showPassword.newPassword,
                        })
                      }
                    />
                  )}
                  {errors.newPassword && touched.newPassword ? (
                    <div className="invalid-msg">{errors.newPassword}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    className="form-control"
                    placeholder="Confirm password"
                    type={showPassword.confirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {showPassword.confirmPassword ? (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    />
                  )}
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="invalid-msg">{errors.confirmPassword}</div>
                  ) : null}
                </div>
              </div>

              <div className="profile-form-submit">
                <button type="submit" className="btn btn-primary">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
