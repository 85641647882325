import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/manageUserData.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/Loader";
import { getSerielNumber, getToken } from "../utils/utils";
import { getStudentsAction } from "../redux/slice/user/getStudentsSlice";
import { userStatusAction } from "../redux/slice/user/userStatusSlice";
import Paginate from "../component/common/Paginate";

const ManageUser = () => {
  const { getStudents, updateUser, addUser, addMultipleUser } = useSelector(
    (state) => state
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [students, setStudents] = useState([]);
  const [userStatus, setUserStatus] = useState(false);

  const token = getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    setStudents(getStudents.users);
  }, [getStudents.success, updateUser.success]);

  useEffect(() => {
    dispatch(getStudentsAction({ token, pageNumber }));
  }, [addUser.success, addMultipleUser.success, pageNumber]);

  return (
    <div className="userwrapper_padd">
      <div className="userwrapper">
        <div className="userinnercon">
          <div className="userpara">
            <p>Mange User</p>
          </div>
          <div>
            <Link to="/educator/Manage/User/add-user" className="usericonpara">
              + Add User
            </Link>
          </div>
        </div>
        <div className="usertable">
          {getStudents.loading ? (
            <Loader />
          ) : (
            <table className="table table-striped">
              <thead>
                <tr className="align-middle rowstyle">
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile no</th>
                  <th scope="col"> Status</th>
                  <th scope="col"> Action</th>
                </tr>
              </thead>
              <tbody>
                {students?.dataItems?.map((student, index) => {
                  return (
                    <tr className="align-middle rowstyle">
                      <th scope="row">{getSerielNumber(index, pageNumber)}</th>
                      <td>
                        {student.fname} {student.lname}
                      </td>
                      <td>{student.email}</td>
                      <td>{student.mnumber}</td>
                      <td className="switch form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={true}
                          // value={student.status === "1"}
                          role="switch"
                          onChange={(e) => {
                            const statusInput = e.target.checked;
                            const status = statusInput === true ? "1" : "0";
                            dispatch(
                              userStatusAction({
                                token,
                                data: { status: status },
                                id: student?.id,
                              })
                            );
                          }}
                          defaultChecked={student.status === "1"}
                          id="flexSwitchCheckDefault"
                        />
                      </td>
                      <td className="edituser">
                        {" "}
                        <Link
                          to={`/educator/Manage/User/edit-user/${student.id}`}
                        >
                          <img
                            src="/images/educator/edituser.svg"
                            alt="teacher"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <Paginate
            totalPages={students?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
