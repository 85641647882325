import React, { useEffect, useState, useCallback } from "react";
import DashContainer from "./DashContainer";
import "../styles/StudentCourseDetail.css";
// import Text from "../component/student/Text";
// import Survey from "../component/student/Survey";
// import PdfCourse from "../component/student/PdfCourse";
// import VideoCourse from "../component/student/VideoCourse";
// import Quiz from "../component/student/Quiz";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetailAction } from "../redux/slice/course/getCourseDetailSlice";
import { Link, useParams } from "react-router-dom";
import StudentCourseTab from "../component/student/StudentCourseTab";
import PreStudentCourseDetails from "../component/student/PreStudentCourseDetails";

const StudentCourseDetail = () => {
  const [tab, setTab] = useState(0);
  const [isError, setIsError] = useState(false);
  const [disableTab, setDisableTab] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);
  const { getCourseDetail, getCourses } = useSelector((state) => state);
  const [courseData, setCourseData] = useState([]);
  const [singleCourse, setSingleCourse] = useState({});

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    getCourses.success && dispatch(getCourseDetailAction(id));
  }, [getCourses.success]);

  useEffect(() => {
    let allDataArray = () => {
      let newData = [];
      getCourseDetail.success &&
        getCourseDetail.courseData.lecture_sections
          ?.filter((lecture) => {
            return lecture.is_published === true;
          })
          .map((lectures, index) => {
            return lectures.lectures?.map((lecture, index) => {
              return newData.push(lecture.id);
            });
          });
      return newData;
    };
    setCourseData(allDataArray());
  }, [getCourseDetail.success]);

  var clearTimer = setTimeout(() => {
    setDisableTab(false);
    // setTab(tab + 1);
  }, 5 * 60 * 1000);

  useCallback(() => {
    isQuiz && clearTimer();
  }, []);

  !isQuiz && clearTimeout(clearTimer);

  return (
    // <DashContainer>
    <section className="wrappercourse">
      <div className="outerstudent">
        <div className="langwrap">
          <div>
            <p className="langparacour">Language Lab-Princeton Hive</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Warning example"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ height: "8%" }}
          >
            <div
              className="progress-bar bg-warning"
              style={{
                width: `${(tab / (courseData?.length - 1)) * 100}%`,
                height: "100%",
              }}
            ></div>
          </div>
          <div>
            <p className="percentcour">
              {`${Math.floor((tab / (courseData?.length - 1)) * 100)}%`}{" "}
              Complete
            </p>
          </div>
        </div>
        {/* <div className="indexwrap">
            <div className="indeximg">
              <img
                className="rightimg"
                src="/images/student/right.svg"
                alt="right"
              />
              <img src="/images/student/bars.svg" alt="bar" />
              <p>Index</p>
            </div>
            <div>
              <p>1/1</p>
            </div>
          </div> */}
        <div className="create-course-container">
          <div className="coursetab">
            {courseData?.map((course, index) => {
              return (
                <StudentCourseTab
                  setIsError={setIsError}
                  course={course}
                  tab={tab}
                  setTab={setTab}
                  index={index}
                  setSingleCourse={setSingleCourse}
                  disableTab={disableTab}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="innercontent">
        {isError ? (
          <Link to="/">Please Go Back and Try after sometime!</Link>
        ) : (
          courseData?.map((course, index) => {
            return (
              tab === index && (
                <PreStudentCourseDetails
                  singleCourse={singleCourse}
                  setDisableTab={setDisableTab}
                  setTab={setTab}
                  tab={tab}
                  disableTab={disableTab}
                  tabIndex={index}
                  setIsQuiz={setIsQuiz}
                />
              )
            );
          })
        )}
        {/* {tab === 1 && <Survey />}
          {tab === 2 && <PdfCourse />}
          {tab === 3 && <VideoCourse />}
          {tab === 4 && <Quiz />} */}
      </div>
    </section>
    // </DashContainer>
  );
};

export default StudentCourseDetail;

{
  /* <div
                onClick={() => setTab(1)}
                className={`tab ${tab === 1 && "active"}`}
              >
                <div className="innercirc"></div>
                <div className="textwrap">
                  <div>
                    <p className="inpara">
                      2. Why do I want to Communicate in English?
                    </p>
                  </div>
                  <div className="pdfwrap">
                    <img
                      src="/images/student/survey.svg"
                      alt="quiz"
                      className="icons"
                    />
                    <p className="inpara">SURVEY 2 QUESTIONS</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setTab(2)}
                className={`tab ${tab === 2 && "active"}`}
              >
                <div className="innercirc"></div>
                <div className="textwrap">
                  <div>
                    <p className="inpara">3. Reading Skills Strategy Summary</p>
                  </div>
                  <div className="pdfwrap">
                    <img
                      src="/images/student/pdfone.svg"
                      alt="quiz"
                      className="icons"
                    />
                    <p className="inpara">Pdf</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setTab(3)}
                className={`tab ${tab === 3 && "active"}`}
              >
                <div className="innercirc"></div>
                <div className="textwrap">
                  <div>
                    <p className="inpara">4. SHIVE STEPS - LANGUAGE LAB</p>
                  </div>
                  <div className="pdfwrap">
                    <img
                      src="/images/student/videocourse.svg"
                      alt="quiz"
                      className="icons"
                    />
                    <p className="inpara">Video</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setTab(4)}
                className={`tab ${tab === 4 && "active"}`}
              >
                <div className="innercirc"></div>
                <div className="textwrap">
                  <div>
                    <p className="inpara">4. Informative Speaking</p>
                  </div>
                  <div className="pdfwrap">
                    <img
                      src="/images/student/quiz.svg"
                      alt="quiz"
                      className="icons"
                    />
                    <p className="inpara">Quiz</p>
                  </div>
                </div>
              </div> */
}
