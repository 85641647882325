import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../redux/slice/auth/loginSlice";
import { loginSchema } from "../utils/schema";
import { securePasswordField, userRole } from "../utils/utils";
import HomeContainer from "./HomeContainer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Signup() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [redirectFlag, setRedirectFlag] = useState(false);
  const navigate = useNavigate();
  const { login } = useSelector((state) => state);
  const user_role = userRole();

  // Redirecting after logout
  useEffect(() => {
    if (!login.error && login.success && login.user && redirectFlag) {
      navigate(
        `/${user_role === "ROLE_TEACHER" ? "educator" : "student"}/dashboard`
      );
    }
  }, [login.success, login.error]);

  // Handling form
  let { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        const data = {
          email: values.email,
          password: values.password,
        };
        dispatch(loginAction(data));

        action.resetForm();
        setRedirectFlag(true);
      },
    });

  return (
    <HomeContainer>
      <section className="section-wrap signup-section">
        <div className="signup-box">
          <div className="signup-img">
            <img src="images/sign-img.jpg" />
          </div>
          <h2>Sign in</h2>
          <div className="sub-heading">Please Provide your details</div>
          <form onSubmit={handleSubmit}>
            <ul>
              <li>
                <label htmlFor="email">Phone Number/Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email/Phone Number"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <div className="invalid-msg">{errors.email}</div>
                ) : null}

                <label htmlFor="password">Password</label>
                <div className="inputField">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onCopy={securePasswordField}
                    onCut={securePasswordField}
                  />
                  {showPassword ? (
                    <AiFillEye onClick={() => setShowPassword(!showPassword)} />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
                {errors.password && touched.password ? (
                  <div className="invalid-msg">{errors.password}</div>
                ) : null}
              </li>
              <li>
                <input type="submit" value="Sign in" />
              </li>
            </ul>
          </form>
        </div>
      </section>
    </HomeContainer>
  );
}

export default Signup;
