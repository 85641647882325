import React from 'react'
import CardWrapper from './CardWrapper'

const StudentDetail = ({headerValue, image1}) => {
  return (
    <CardWrapper  headerValue={headerValue} image1={image1}>
        <div className='studentwrap'>
            <div className='criteriasize'>
                <p>Criteria</p>
            </div>
            <div className='ratingcriteria'>
                <p>100-81%=Excellent</p>
                <p>80-61%=Good</p>
                <p>60-41%=Average</p>
                <p>40-21%=Need Improvement</p>
                <p>20-0%=Poor</p>
            </div>
        </div>
    </CardWrapper>
  )
}

export default StudentDetail