import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/eduAssessment.css";
import { useDispatch, useSelector } from "react-redux";
import { getOngoingAssessmentsAction } from "../../redux/slice/teacherAssessment/getOngoingAssessmentsSlice";
import {
  getPurposeHandle,
  getToken,
  getUserLocal,
  localDateFormat,
} from "../../utils/utils";
import { getUpcomingAssessmentsAction } from "../../redux/slice/teacherAssessment/getUpcomingAssessmentsSlice";
import { getPreviousAssessmentsAction } from "../../redux/slice/teacherAssessment/getPreviousAssessmentsSlice";
import { deleteAssessmentAction } from "../../redux/slice/teacherAssessment/deleteAssessmentSlice";
import Loader from "../../component/Loader";
import Paginate from "../../component/common/Paginate";

function EducatorAssessment() {
  const [onGoingAssessmentList, setOnGoingAssessmentList] = useState([]);
  const [upCommingAssessmentList, setUpCommingAssessmentList] = useState([]);
  const [previousAssessmentsList, setPreviousAssessmentsList] = useState([]);
  const {
    getParticipantAssmt,
    getOngoingAssessments,
    getUpcomingAssessments,
    getPreviousAssessments,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const token = getToken();
  const currentUser = getUserLocal();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageNumberUpcoming, setPageNumberUpcoming] = useState(0);
  const [pageNumberPrevious, setPageNumberPrevious] = useState(0);

  useEffect(() => {
    dispatch(
      getUpcomingAssessmentsAction({ token, pageNumber: pageNumberUpcoming })
    );
  }, [pageNumberUpcoming, token]);

  useEffect(() => {
    dispatch(getOngoingAssessmentsAction({ token, pageNumber }));
  }, [pageNumber, token]);

  useEffect(() => {
    dispatch(
      getPreviousAssessmentsAction({ token, pageNumber: pageNumberPrevious })
    );
  }, [pageNumberPrevious, token]);

  useEffect(() => {
    setOnGoingAssessmentList(getOngoingAssessments.assessments);
    setUpCommingAssessmentList(getUpcomingAssessments.assessments);
    setPreviousAssessmentsList(getPreviousAssessments.assessments);
  }, [
    getOngoingAssessments.success,
    getUpcomingAssessments.success,
    getPreviousAssessments.success,
  ]);

  const deleteAsssessmentHandle = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteAssessmentAction({ id, token }));
    }
  };

  return (
    <div className="edu-right-side">
      {/* Search filter */}
      {/* <div className="asmt-search-filter">
        <form>
          <div className="input-field">
            <select name="assessmentType" id="assessmentType">
              <option value="volvo">Assessment Type</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select>
          </div>

          <div className="input-field">
            <select name="assessmentType" id="assessmentType">
              <option value="volvo">Select Purpose</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select>
          </div>

          <div className="input-field">
            <input
              type="date"
              name="assessmentStarted"
              id="assessmentStarted"
            />
          </div>
          <button className="secondary-btn">To</button>
          <div className="input-field">
            <input type="date" name="assessmentEnded" id="assessmentEnded" />
          </div>

          <button type="submit" className="search-btn">
            Search
          </button>
          <button type="button">Refresh</button>
        </form>
      </div> */}

      {/* OnGoing Assessment */}
      <div className="teacherboard">
        <div className="teacherinnerbox">
          <p>Ongoing Assessments</p>
        </div>

        <div className="asmt-container">
          {/* Assessment Box */}
          {getOngoingAssessments.loading ? (
            <Loader />
          ) : getOngoingAssessments.assessments?.dataItems?.length > 0 ? (
            onGoingAssessmentList?.dataItems?.map((asmt, index) => {
              return (
                <Link to={`/educator/assessment-detail/${asmt.id}`}>
                  <div className="asmt-box">
                    <p className="title-name">{asmt.assessmentName}</p>
                    {currentUser.roles[0] === "ROLE_ADMIN" && (
                      <div
                        className="delete-btn"
                        onClick={() => deleteAsssessmentHandle(asmt.id)}
                        role="button"
                      >
                        <img
                          src="/images/educator/delete.svg"
                          alt="delete icon"
                        />
                      </div>
                    )}

                    <div className="left-asmt-box">
                      <div className="content">
                        <p>
                          <span>Purpose:</span>{" "}
                          {getPurposeHandle(asmt.assessmentPurpose)}
                        </p>
                        <p>
                          <span>Start On :</span>{" "}
                          {localDateFormat(asmt.startDate)}
                          {/* January 29th 2023 11:00am */}
                        </p>
                        <p>
                          <span>End On:</span> {localDateFormat(asmt.endDate)}
                        </p>
                      </div>
                      {/* Participated part */}
                      <div className="participated-container">
                        {/* <div className="left-participated">
                        <div className="data-icon">
                          <img
                            src="/images/educator/participated.svg"
                            alt="participated ico"
                          />
                          <span>05</span>
                        </div>
                        Participated
                      </div> */}
                        <div className="right-participated">
                          <div className="data-icon">
                            <img
                              src="/images/educator/total-pctd.svg"
                              alt="participated ico"
                            />
                            <span>{asmt.studentId.length}</span>
                          </div>
                          Total Participants
                        </div>
                      </div>
                    </div>
                    <div className="right-asmt-box">
                      <img src="/images/educator/asmt-card.png" alt="" />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="no-asmt">There Are No Ongoing Assessment</div>
          )}

          <Paginate
            totalPages={getOngoingAssessments.assessments?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {/* Upcoming Assessment */}
      <div className="teacherboard">
        <div className="teacherinnerbox">
          <p>Upcoming Assessments</p>
        </div>
        <div className="asmt-container">
          {/* Assessment Box */}

          {getUpcomingAssessments.loading ? (
            <Loader />
          ) : getUpcomingAssessments.assessments?.dataItems?.length > 0 ? (
            upCommingAssessmentList?.dataItems?.map((asmt, index) => {
              return (
                <Link to={`/educator/assessment-detail/${asmt.id}`}>
                  <div className="asmt-box">
                    <p className="title-name">{asmt.assessmentName}</p>
                    {currentUser.roles[0] === "ROLE_ADMIN" && (
                      <div className="delete-btn">
                        <img
                          src="/images/educator/delete.svg"
                          alt="delete icon"
                        />
                      </div>
                    )}

                    <div className="left-asmt-box">
                      <div className="content">
                        <p>
                          <span>Purpose:</span>{" "}
                          {getPurposeHandle(asmt.assessmentPurpose)}
                        </p>
                        <p>
                          <span>Start On :</span>{" "}
                          {localDateFormat(asmt.startDate)}
                          {/* January 29th 2023 11:00am */}
                        </p>
                        <p>
                          <span>End On:</span> {localDateFormat(asmt.endDate)}
                        </p>
                      </div>
                      {/* Participated part */}
                      <div className="participated-container">
                        {/* <div className="left-participated">
                          <div
                            className="data-icon"
                            onClick={() => deleteAsssessmentHandle(asmt.id)}
                          >
                            <img
                              src="/images/educator/participated.svg"
                              alt="participated ico"
                            />
                            <span>05</span>
                          </div>
                          Participated
                        </div> */}
                        <div className="right-participated">
                          <div className="data-icon">
                            <img
                              src="/images/educator/total-pctd.svg"
                              alt="participated ico"
                            />
                            <span>{asmt.studentId.length}</span>
                          </div>
                          Total Participants
                        </div>
                      </div>
                    </div>
                    <div className="right-asmt-box">
                      <img src="/images/educator/asmt-card.png" alt="" />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="no-asmt">There Are No Upcomming Assessment</div>
          )}
          <Paginate
            totalPages={getUpcomingAssessments.assessments?.totalPages}
            pageNumber={pageNumberUpcoming}
            setPageNumber={setPageNumberUpcoming}
          />
        </div>
      </div>

      {/* Previous Assessment */}
      <div className="teacherboard">
        <div className="teacherinnerbox">
          <p>Previous Assessment</p>
        </div>
        <div className="asmt-container">
          {getPreviousAssessments.loading ? (
            <Loader />
          ) : getPreviousAssessments.assessments?.dataItems?.length > 0 ? (
            previousAssessmentsList?.dataItems?.map((asmt, index) => {
              return (
                <Link to={`/educator/assessment-detail/${asmt.id}`}>
                  <div className="asmt-box">
                    <p className="title-name">{asmt.assessmentName}</p>
                    {currentUser.roles[0] === "ROLE_ADMIN" && (
                      <div
                        className="delete-btn"
                        onClick={() => deleteAsssessmentHandle(asmt.id)}
                      >
                        <img
                          src="/images/educator/delete.svg"
                          alt="delete icon"
                        />
                      </div>
                    )}

                    <div className="left-asmt-box">
                      <div className="content">
                        <p>
                          <span>Purpose:</span>{" "}
                          {getPurposeHandle(asmt.assessmentPurpose)}
                        </p>
                        <p>
                          <span>Start On :</span>{" "}
                          {localDateFormat(asmt.startDate)}
                          {/* January 29th 2023 11:00am */}
                        </p>
                        <p>
                          <span>End On:</span> {localDateFormat(asmt.endDate)}
                        </p>
                      </div>
                      {/* Participated part */}
                      <div className="participated-container">
                        {/* <div className="left-participated">
                          <div className="data-icon">
                            <img
                              src="/images/educator/participated.svg"
                              alt="participated ico"
                            />
                            <span>05</span>
                          </div>
                          Participated
                        </div> */}
                        <div className="right-participated">
                          <div className="data-icon">
                            <img
                              src="/images/educator/total-pctd.svg"
                              alt="participated ico"
                            />
                            <span>{asmt.studentId.length}</span>
                          </div>
                          Total Participants
                        </div>
                      </div>
                    </div>
                    <div className="right-asmt-box">
                      <img src="/images/educator/asmt-card.png" alt="" />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="no-asmt">There Are No Upcomming Assessment</div>
          )}
          <Paginate
            totalPages={getPreviousAssessments.assessments?.totalPages}
            pageNumber={pageNumberPrevious}
            setPageNumber={setPageNumberPrevious}
          />
        </div>
      </div>
    </div>
  );
}

export default EducatorAssessment;
