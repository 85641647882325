import { toast } from "react-toastify";

// Notification message
export const notify = (message, type = "success") => {
  const option = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "success") {
    toast.success(message, option);
  } else if (type === "warn") {
    toast.warn(message, option);
  } else if (type === "error") {
    toast.error(message, option);
  }
};

// Return the user data from the LocalStorage storage
export const getUserLocal = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getPurposeHandle = (type) => {
  if (type) {
    if (type === "1") {
      return "Speaking";
    } else if (type === "2") {
      return "Listening";
    } else if (type === "3") {
      return "Reading";
    } else if (type === "4") {
      return "Writing";
    } else if (type === "5") {
      return "Others";
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const localDateFormat = (timeStamp) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (timeStamp) {
    const date = new Date(timeStamp);
    let day = 0;
    date.getDate() < 10 ? (day = "0" + date.getDate()) : (day = date.getDate());

    return day + " " + monthNames[date.getMonth()] + ", " + date.getFullYear();
  } else {
    return "dd-mm-yy";
  }
};

// return the token from the Localstorage
export const getToken = () => {
  return localStorage.getItem("accessToken") || null;
};

// remove the token and user from the Localstorage
export const removeUserSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
};

// set the token and user from the Localstorage
export const setUserSession = (token, user) => {
  localStorage.setItem("accessToken", token);
  localStorage.setItem("user", JSON.stringify(user));
};

export const userRole = () => {
  const user = getUserLocal();
  if (user) {
    if (user.roles[0] === "ROLE_TEACHER") {
      return "ROLE_TEACHER";
    } else if (user.roles[0] === "ROLE_STUDENT") {
      return "ROLE_STUDENT";
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const securePasswordField = (e) => {
  e.preventDefault();
  return false;
};

// export const getDepartmentName = (departmentNo) => {
//   const rawDepartments = localStorage.getItem("departments");
//   if (rawDepartments) {
//     const departmentsArray = JSON.parse(rawDepartments);

//     const department = departmentsArray.filter((dpt) => {
//       return dpt.id == departmentNo;
//     });

//     return department[0].departmentName;
//   } else return null;
// };

export const initialValuesByTab = (tab) => {
  let initialValues = {};
  switch (tab) {
    case 0:
      return {
        assessmentType: "1",
      };
    case 1:
      initialValues = {
        ...initialValues, // Enter details
        assessmentName: "",
        assessmentDesc: "",
        startDate: "",
        endDate: "",
        assessmentPurpose: "",
        assessmentResponseType: "",
      };
      return initialValues;
    case 2:
      initialValues = {
        ...initialValues, // level form
        questions: [],
      };
      return initialValues;
    case 3:
      initialValues = {
        ...initialValues, // Weightage Form
        participants: [],
      };
      return initialValues;
    case 4:
      initialValues = {
        ...initialValues,
        intro: "0",
        mainContext: "0",
        environment: "0",
        outro: "0",
        // Intro Form
        aiLevel: "",
        level: "",
        greeting: "",
        facialExpression: "",
        gratitude: "",
        customWord: "",
        customSentences: "",
      };
      return initialValues;

    case 5:
      initialValues = {
        ...initialValues, //Main Context
        voiceContext: "",
        environmentContext: "",
        bodyLangaugeContext: "",
        facialExpressionContext: "",
      };
      return initialValues;
    case 6:
      initialValues = {
        ...initialValues, // Outro Form
        greetingOutro: "",
        gratitudeOutro: "",
        customWordOutro: "",
        customSentencesOutro: "",
      };
      return initialValues;
    case 7:
      initialValues = { ...initialValues, environmentTab: "" };
      return initialValues;
    default:
      return initialValues;
  }
};

export const getColor = (percentage) => {
  if (percentage < 30) {
    return "RGB(156, 156, 156)";
  } else if (percentage >= 30 && percentage < 50) {
    return "RGB(233, 19, 19)";
  } else if (percentage >= 50 && percentage < 70) {
    return "RGB(0, 128, 0)";
  } else if (percentage >= 70 && percentage <= 100) {
    return "RGB(240, 188, 21)";
  }
};

export const getColorByQuality = (quality) => {
  if (quality === "blurry video") {
    return "RGB(156, 156, 156)";
  } else if (quality == "poor") {
    return "RGB(233, 19, 19)";
  } else if (quality === "average") {
    return "RGB(0, 128, 0)";
  } else if (quality === "good") {
    return "RGB(240, 188, 21)";
  } else return "RGB(156, 156, 156)";
};

// export const getVideoQualityPercent = (videoQuality)=>{
//   switch (videoQuality) {
//     case "good":
//       return 81;
//       break;
//       case "average":
//       return 81;
//       break;
//       case "poor":
//       return 81;
//       break;
//       case "blurry video":
//       return 81;
//       break;

//     default:
//       break;
//   }tc
// }

export const getSerielNumber = (index, page = 0) => {
  return `${index < 9 ? page : ""}${index + 1}`;
};
