import React from "react";
import { Outlet } from "react-router-dom";
import RightBar from "../../dashboard/RightBar";

const StudentLayoutRightBar = () => {
  return (
    <>
      <Outlet />
      <RightBar />
    </>
  );
};

export default StudentLayoutRightBar;
