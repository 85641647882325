import React, { useRef, useState } from "react";
import "../../styles/videoAnalysis.css";
import Content from "./Content";
import InsightVideo from "./InsightVideo";
import ReactPlayer from "react-player";

const VideoAnalysis = ({ report }) => {
  const [tab, setTab] = useState(0);
  // const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);
  const playerRef = useRef();

  const handleTimeClick = (time) => {
    setTimeStamp(time);
    playerRef.current.seekTo(timeStamp, "seconds");
    setIsPlaying(true);
  };

  return (
    <div className="videowrap">
      <div className="analysisinner"></div>
      <div className="videocontent">
        <div className="ladyimg">
          {/* <img src='/images/student/lady.svg' alt='lady' /> */}
          {report[0]?.videoUrl && (
            // <video controls style={{ borderRadius: "21px" }}>
            //   <source src={report[0]?.videoUrl} type="video/mp4" />
            //   Your browser does not support the video tag.
            // </video>

            <ReactPlayer
              url={report[0]?.videoUrl}
              style={{ borderRadius: "21px" }}
              ref={playerRef}
              playing={isPlaying}
              width="100%"
              height="100%"
              controls={true}
              // onReady={onReady}
              className="video"
            />
          )}
        </div>
        <div className="tabcontent">
          <div className="create-video-container">
            <div className="videotab">
              <button
                type="button"
                onClick={() => setTab(0)}
                className={`tab contenttab ${tab === 0 && "active"}`}
              >
                Content
              </button>

              <button
                type="button"
                onClick={() => setTab(1)}
                className={`tab insighttab  ${tab === 1 && "active"}`}
              >
                Insights
              </button>
            </div>
          </div>
          {tab === 0 && (
            <Content
              movements={report[0]?.aiReport}
              handleTimeClick={handleTimeClick}
            />
          )}
          {tab === 1 && (
            <InsightVideo
              movements={report[0]?.aiReport}
              handleTimeClick={handleTimeClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoAnalysis;
