import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../component/Loader";
import DashContainer from "./DashContainer";
import LeftBar from "./LeftBar";
import RightBar from "./RightBar";

function Courses() {
  const [courses, setCourses] = useState([]);
  const { getCourses, toggleMenu } = useSelector((state) => state);

  useEffect(() => {
    const isPublished = getCourses.courseData.filter((course, index) => {
      return course.is_published === true;
    });
    console.log(isPublished);
    setCourses(getCourses.courseData);
  }, [getCourses.success]);

  // const courseDetailHandle = (id) => {
  //   dispatch(getCourseDetailAction(id));
  // };

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Courses</h1>
        <hr className="dashboard-hr" />
        <div className="row tab-platform">
          {getCourses.loading ? (
            <Loader />
          ) : (
            courses.map((course, index) => {
              return (
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-4">
                  <div className="platform-box">
                    <div className="platform-img">
                      <span className="title-name">{course.name}</span>
                      <img src={course.image_url} alt />
                    </div>
                    <div className="platform-btn">
                      <Link
                        className="btnfull"
                        to={`/student/courses/${course.id}/detail`}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default Courses;
