import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CalendarComponent = () => {
  const events = [
    "04-04-2023",
    "01-04-2023",
    "03-05-2023",
    "05-04-2023",
    "06-05-2023",
    "07-04-2023",
    "08-06-2023",
    "09-05-2023",
    "10-06-2023",
    "11-05-2023",
  ];

  const showEventDates = ({ date }) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (date.getMonth() < 10) month = "0" + month;
    if (date.getDate() < 10) {
      day = "0" + day;
    }
    const realDate = day + "-" + month + "-" + date.getFullYear();
    if (events.find((val) => val === realDate)) {
      return "highlight";
    }
  };

  return (
    <Calendar
      calendarType="US"
      tileClassName={showEventDates}
      onClickDay={(value) => alert(value)}
    />
  );
};

export default CalendarComponent;
