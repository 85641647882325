import React from "react";
import parse from "html-react-parser";

const BlogCard = ({ title, imgUrl, description }) => {
  const getTextFromHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const descriptionText = getTextFromHTML(description);

  // Limit descriptionText to 100 characters
  const newDesc =
    descriptionText.length > 80
      ? descriptionText.slice(0, 80) + "..."
      : descriptionText;

  return (
    <div className="card blogcard">
      <img
        src={imgUrl || "/images/footer/blogstudent.svg"}
        className="card-img-top"
        alt="..."
      />
      <div className="card-body">
        <p className="card-text carddesc">
          {title?.length > 100 ? title?.slice(0, 100) + "..." : title}
        </p>
        <p className="card-short-desc">{newDesc}</p>
      </div>
    </div>
  );
};

export default BlogCard;
