import React, { useEffect, useState } from "react";
import Header from "../Header";
import BlogCard from "./BlogCard";
import "../../styles/Blog.css";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogsAction } from "../../redux/slice/blog/getAllBlogsSlice";
import Paginate from "../common/Paginate";
import Loader from "../common/Loader";

const Blog = () => {
  const dispatch = useDispatch();
  const { allBlogs } = useSelector((state) => state);

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    dispatch(getAllBlogsAction(pageNumber));
  }, [pageNumber]);

  return (
    <div className="blogfull">
      <Header />
      <p className="blogpara">Blogs</p>
      <div className="blogwrap">
        <div className="blogin">
          {/* <p className="listblog">Home &gt; Blogs List </p> */}
          <div className="cardouterblog">
            {allBlogs.loading ? (
              <Loader />
            ) : (
              allBlogs.blogs?.dataItems?.map((blog, index) => {
                return (
                  <Link
                    to={`/blog/details/${blog.id}/${blog.slug}`}
                    key={index}
                  >
                    <BlogCard {...blog} />
                  </Link>
                );
              })
            )}
          </div>
          <Paginate
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={allBlogs.blogs?.totalPages}
          />
        </div>
        {/* <div className="rightwrapblog">
          <RightBlogBar />
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
