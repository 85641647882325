import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../../styles/Gallery.css";
import AllMedia from "./AllMedia";
import PhotoMedia from "./PhotoMedia";
import VideoMedia from "./VideoMedia";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Gallery = () => {
  const [tab, setTab] = useState(0);

  const {pathname} = useLocation();

  useEffect(()=>{
    window.scrollTo(0,0)
  },[pathname])

  return (
    <div>
      <Header />
      <div id="gallery">
        <p className="gallerypara">Gallery</p>
        <div className="contactwrap">
          {/* <div className="create-contact-container">
            <div className="contacttabs">
              <button
                type="button"
                onClick={() => setTab(0)}
                className={`tab ${tab === 0 && "active"}`}
              >
                All
              </button>

              <button
                type="button"
                onClick={() => setTab(1)}
                className={`tab ${tab === 1 && "active"}`}
              >
                Photos
              </button>
              <button
                type="button"
                onClick={() => setTab(2)}
                className={`tab ${tab === 2 && "active"}`}
              >
                Videos
              </button>
            </div>
          </div> */}

          {tab === 0 && <AllMedia />}
          {/* {tab === 1 && <PhotoMedia />}
          {tab === 2 && <VideoMedia />} */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
