import React from "react";
import PhotoCard from "./PhotoCard";
import VideoCard from "./VideoCard";
import { galleryImages } from "../../utils/data";

const AllMedia = () => {
  return (
    <div className="allmedwrap">
      {/* <VideoCard /> */}
      {galleryImages.map((img, index) => {
        return <PhotoCard url={img.url} />;
      })}
    </div>
  );
};

export default AllMedia;
