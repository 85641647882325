import React, {useEffect, useState} from 'react';

const useCheckSize = () => {

    const [widowSize, setWindowSize] = useState(0);

    useEffect(()=>{
        function updateSize(){
            setWindowSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return ()=>window.removeEventListener('resize', updateSize);
    },[])

  return widowSize;
}

export default useCheckSize;