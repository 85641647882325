import React, { Fragment, useEffect, useState } from "react";
import AssessmentTable from "./AssessmentTable";
import { useDispatch, useSelector } from "react-redux";
import { participatAction } from "../../redux/slice/assessment/participantSlice";
import { getToken } from "../../utils/utils";

const ParticipantsTab = ({ participants }) => {
  // const { getStudents, updateUser, getParticipantAssmt } = useSelector(
  //   (state) => state
  // );
  // const [participant, setParticipant] = useState([]);
  // const [students, setStudents] = useState([]);
  // const token = getToken();

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(participatAction(token));
  // }, []);

  // useEffect(() => {
  //   setParticipant(getParticipantAssmt.assessment?.dataItems);
  // }, [getParticipantAssmt.success]);

  // useEffect(() => {
  //   setStudents(getStudents.users);
  // }, [getStudents.success, updateUser.success]);

  return (
    <Fragment>
      {/* <div className="table-upper-ctrl space-between">
        <div className="left">
          <label htmlFor="show">Show </label>
          <select name="show" id="show">
            <option value="10">10</option>
            <option value="10">20</option>
            <option value="10">30</option>
            <option value="10">40</option>
          </select>
          Entries
        </div>

        <div className="right">
          <label htmlFor="search">Search</label>
          <input type="search" name="search" id="search" />
        </div>
      </div> */}

      <table className="table table-striped table-hover">
        <thead className="table-light">
          <tr>
            <th scope="col">Sr. No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile No.</th>
            {/* <th scope="col">Score</th>
            <th scope="col">Report</th>
            <th scope="col">Status</th> */}
          </tr>
        </thead>

        <tbody>
          {participants &&
            participants?.map((student, index) => {
              return (
                <tr className="align-middle">
                  <th scope="row">{index + 1}</th>
                  <td> {student?.name}</td>
                  <td>{student?.email}</td>
                  <td>{student?.mobileNumber}</td>
                  {/* <td>NA</td>
                <td>NA</td>
                <td>
                  <span className="ongoingText">Ongoing</span>
                </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>

      {/* <div className="table-lower-ctrl space-between">
        <span className="left">Showing 1 to 1 of 1 Entries</span>
        <div className="right">
          <span>Previous</span>
          <button>1</button>
          <span>Next</span>
        </div>
      </div> */}
    </Fragment>
  );
};

export default ParticipantsTab;
