import { configureStore } from "@reduxjs/toolkit";
import createAssessmentSlice from "./slice/assessment/createAssessmentSlice";
import getAllReportsSlice from "./slice/assessment/getAllReportsSlice";
import getAssignedAssessmentSlice from "./slice/assessment/getAssignedAssessmentSlice";
import getCompletedAssessmentSlice from "./slice/assessment/getCompletedAssessmentSlice";
import getSelfAssessmentSlice from "./slice/assessment/getSelfAssessmentSlice";

// Authentication
import changePasswordSlice from "./slice/auth/changePasswordSlice";
import loginSlice from "./slice/auth/loginSlice";
import logoutSlice from "./slice/auth/logoutSlice";

import getCourseDetailSlice from "./slice/course/getCourseDetailSlice";
import getCoursesSlice from "./slice/course/getCoursesSlice";
import getLectureSlice from "./slice/course/getLectureSlice";

import getUserSlice from "./slice/user/getUserSlice";
import updateCurrentUserSlice from "./slice/user/updateCurrentUserSlice";
import addUserSlice from "./slice/user/addUserSlice";
import addMultipleUserSlice from "./slice/user/addMultipleUserSlice";
import getStudentsSlice from "./slice/user/getStudentsSlice";
import updateUserSlice from "./slice/user/updateUserSlice";

import addDepartmentSlice from "./slice/department/addDepartmentSlice";
import getDepartmentsSlice from "./slice/department/getDepartmentsSlice";

import getOngoingAssessmentsSlice from "./slice/teacherAssessment/getOngoingAssessmentsSlice";
import getUpcomingAssessmentsSlice from "./slice/teacherAssessment/getUpcomingAssessmentsSlice";
import getPreviousAssessmentsSlice from "./slice/teacherAssessment/getPreviousAssessmentsSlice";
import deleteAssessmentSlice from "./slice/teacherAssessment/deleteAssessmentSlice";

import addQuestionSlice from "./slice/question/addQuestionSlice";
import getQuestionsSlice from "./slice/question/getQuestionsSlice";
import deleteQuestionSlice from "./slice/question/deleteQuestionSlice";
import getPracticeAssessmentsSlice from "./slice/assessment/getPracticeAssessmentsSlice";
import getAllTeacherAsmtsSlice from "./slice/teacherAssessment/getAllTeacherAsmtsSlice";
import getPracticeTeacherAsmtsSlice from "./slice/teacherAssessment/getPraticeTeacherAsmtsSlice";
import getAssignedTeacherAsmtsSlice from "./slice/teacherAssessment/getAssignedTeacherAsmtsSlice";
import addAsmtTeacherSlice from "./slice/teacherAssessment/addAsmtTeacherSlice";
import aiReportRequestSlice from "./slice/aiReport/aiReportRequestSlice";
import getAllAIReportSlice from "./slice/aiReport/getAllAIReportSlice";
import getAIReportSlice from "./slice/aiReport/getAIReportSlice";
import toggleMenuSlice from "./slice/toggleMenu/toggleMenuSlice";
import contactUsSlice from "./slice/common/contactUsSlice";
import deleteDepartmentSlice from "./slice/department/deleteDepartmentSlice";
import updateDepartmentSlice from "./slice/department/updateDepartmentSlice";
import getPendingAsmtSlice from "./slice/assessment/getPendingAsmtSlice";
import getAllReportDataSlice from "./slice/aiReport/AllReportDataSlice";
import participantSlice from "./slice/assessment/participantSlice";
import getTeacherReportSlice from "./slice/teacher/getTeacherReportSlice";
import getAllLibrarySlice from "./slice/centeralLibrary/getAllLibrarySlice";
import getLibraryByCategorySlice from "./slice/centeralLibrary/getLibraryByCategorySlice";
import userStatusSlice from "./slice/user/userStatusSlice";
import getAsmtDetailByIdSlice from "./slice/teacherAssessment/getAsmtDetailByIdSlice";
import getAllBlogsSlice from "./slice/blog/getAllBlogsSlice";
import getBlogByIdSlice from "./slice/blog/getBlogByIdSlice";

export const store = configureStore({
  reducer: {
    toggleMenu: toggleMenuSlice,
    contactUs: contactUsSlice,

    // Authentication
    login: loginSlice,
    logout: logoutSlice,
    changePassword: changePasswordSlice,

    // User
    getUser: getUserSlice,
    userStatus: userStatusSlice,
    updateCurrentUser: updateCurrentUserSlice,
    addUser: addUserSlice,
    addMultipleUser: addMultipleUserSlice,
    getStudents: getStudentsSlice,
    updateUser: updateUserSlice,

    // Department
    addDepartment: addDepartmentSlice,
    getDepartments: getDepartmentsSlice,
    deleteDepartment: deleteDepartmentSlice,
    updateDepartment: updateDepartmentSlice,

    // Student Assessment
    getOngoingAssessments: getOngoingAssessmentsSlice,
    getUpcomingAssessments: getUpcomingAssessmentsSlice,
    getPreviousAssessments: getPreviousAssessmentsSlice,
    deleteAssessment: deleteAssessmentSlice,
    getPracticAssessments: getPracticeAssessmentsSlice,
    getPendingAsmt: getPendingAsmtSlice,
    getParticipantAssmt: participantSlice,

    // Teacher Assessments
    getAllTeacherAsmts: getAllTeacherAsmtsSlice,
    getPracticeTeacherAsmts: getPracticeTeacherAsmtsSlice,
    getAssignedTeacherAsmts: getAssignedTeacherAsmtsSlice,
    addAsmtTeacher: addAsmtTeacherSlice,
    getTeacherReport: getTeacherReportSlice,
    getAsmtDetailById: getAsmtDetailByIdSlice,

    // Course
    getCourses: getCoursesSlice,
    getCourseDetail: getCourseDetailSlice,
    getLecture: getLectureSlice,

    // AI Report
    aiReportRequest: aiReportRequestSlice,
    getAllAIReports: getAllAIReportSlice,
    getAIReport: getAIReportSlice,
    getAllReportData: getAllReportDataSlice,

    // Question
    addQuestion: addQuestionSlice,
    getQuestions: getQuestionsSlice,
    deleteQuestion: deleteQuestionSlice,

    // Assessment
    createAssessment: createAssessmentSlice,
    getSelfAssessment: getSelfAssessmentSlice,
    getAssignedAssessment: getAssignedAssessmentSlice,
    getCompletedAssessment: getCompletedAssessmentSlice,
    getAllReports: getAllReportsSlice,

    // Library
    getAllLibrary: getAllLibrarySlice,
    getLibraryByCategory: getLibraryByCategorySlice,

    // Blogs
    allBlogs: getAllBlogsSlice,
    blogById: getBlogByIdSlice,
  },
});
