import React from 'react'

const AssesmentComponent = ({ themeClass, assesmentNumber, assesmentType, assesmentImage }) => {
  return (
    <div className={`assesment-component ${themeClass}`}>
      <div className='assesment-content'>
        <span className='number-of-assesment'>{assesmentNumber}</span>
        <span className='type-of-assesment'>{assesmentType}</span>
      </div>
      <div className='assesment-image'>
        <img src={assesmentImage} alt='assesments-image' />
      </div>
    </div>
  )
}

export default AssesmentComponent
