import React from "react";
import EnvironmentForm from "./EnvironmentForm";
import IntroForm from "./IntroForm";
import LevelForm from "./LevelForm";
import MainContextForm from "./MainContextForm";
import OutroForm from "./OutroForm";
import WeightageForm from "./WeightageForm";

const SetAIParameters = ({
  setTab,
  tab,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <div className="ai-tab-container">
      <div className="ai-tabs">
        <button type="button" className={tab === 4 ? "active" : "tab"}>
          <span>Level</span> <div>1</div>
        </button>
        <button type="button" className={tab === 5 ? "active" : "tab"}>
          <span>Weightage</span> <div>2</div>
        </button>
        <button type="button" className={tab === 6 ? "active" : "tab"}>
          <span>Intro</span> <div>3</div>
        </button>
        <button type="button" className={tab === 7 ? "active" : "tab"}>
          <span>Main Context</span> <div>4</div>
        </button>
        <button type="button" className={tab === 8 ? "active" : "tab"}>
          <span>Outro</span> <div>5</div>
        </button>
        <button type="button" className={tab === 9 ? "active" : "tab"}>
          <span>Environment</span> <div>6</div>
        </button>
      </div>

      {tab === 4 && (
        <LevelForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
        />
      )}
      {tab === 5 && (
        <WeightageForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      {tab === 6 && (
        <IntroForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      {tab === 7 && (
        <MainContextForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      {tab === 8 && (
        <OutroForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      {tab === 9 && (
        <EnvironmentForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
    </div>
  );
};

export default SetAIParameters;
