import React, { useState, Fragment, useEffect } from "react";
import DeleteDepartment from "../../component/DeleteDepartment";
import "../../styles/department.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addDepartmentAction } from "../../redux/slice/department/addDepartmentSlice";
import { getToken } from "../../utils/utils";
import { deleteDepartmentAction } from "../../redux/slice/department/deleteDepartmentSlice";
import { updateDepartmentAction } from "../../redux/slice/department/updateDepartmentSlice";
import Confirm from "../../component/common/Confirm";

const Department = () => {
  const [cartIsShown, setCartIsShown] = useState(false);
  const [departmentId, setDepartmentId] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const { getDepartments } = useSelector((state) => state);
  const [departmentsList, setDepartmentsList] = useState([]);
  const dispatch = useDispatch();
  const [editDepartmentDetail, setEditDepartmentDetail] = useState({});
  const token = getToken();

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  // Getting department
  useEffect(() => {
    getDepartments.success && setDepartmentsList(getDepartments.departments);
  }, [getDepartments.success]);

  // Adding department
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: editDepartmentDetail || {
        department: "",
      },
      enableReinitialize: true,
      // validationSchema: addStudentSchema,
      onSubmit: (values, action) => {
        const newValues = { departmentName: values.department, status: "1" };
        console.log(newValues);
        // showCartHandler();

        !editDepartmentDetail.department &&
          dispatch(addDepartmentAction({ values: newValues, token }));

        editDepartmentDetail.department &&
          dispatch(
            updateDepartmentAction({
              token,
              values: newValues,
              id: editDepartmentDetail.id,
            })
          );

        setEditDepartmentDetail({
          id: "",
          department: "",
        });
        action.resetForm();
      },
    });

  // Update department handle
  const handleUpdateDepartment = (id) => {
    const depInfo = departmentsList.find((dep, index) => dep.id === id);
    setEditDepartmentDetail({
      id: depInfo.id,
      department: depInfo.departmentName,
    });
  };

  // Delete department handle
  const handleDeleteDepartment = (id) => {
    // window.confirm("Are you sure?") &&

    confirm && dispatch(deleteDepartmentAction({ token, id }));
  };

  console.log(getDepartments.departments);
  return (
    <Fragment>
      <div className="departmentwrapper">
        <div className="left">
          <div className="inner">
            <p>Existing Departments</p>
          </div>
          <div className="testingwrap">
            {departmentsList.map((department, index) => {
              return (
                <div className="testinginner" key={index}>
                  <div className="human">
                    <div className="departmentdiv">
                      <img src="/images/educator/human.svg" alt="hgs" />
                    </div>
                    <p>{department.departmentName}</p>
                  </div>
                  <div className="testicon">
                    <img
                      src="/images/educator/edit.svg"
                      alt="hgs"
                      onClick={() => handleUpdateDepartment(department.id)}
                    />
                    <img
                      className="delete"
                      src="/images/educator/deletemange.svg"
                      alt="hgs"
                      onClick={() => {
                        setCartIsShown(true);
                        setDepartmentId(department.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <form className="right" onSubmit={handleSubmit}>
          <div className="innertwo">
            <p>Add Department</p>
          </div>
          <div className="departmentouter">
            <p className="department">Enter Department Name</p>
            <input
              type="text"
              placeholder="Enter new deparment name"
              name="department"
              value={values.department}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.fname && touched.fname ? (
              <div className="invalid-msg">{errors.fname}</div>
            ) : null}
            <button type="submit" className="btn addbtn">
              Add
            </button>
          </div>
        </form>
      </div>

      {cartIsShown && (
        <Confirm
          setConfirm={setConfirm}
          question="Are you sure want to delete this department?"
          cartIsShown={cartIsShown}
          setCartIsShown={setCartIsShown}
          showCartHandler={showCartHandler}
          onClose={hideCartHandler}
          dispatch={() =>
            dispatch(deleteDepartmentAction({ token, id: departmentId }))
          }
        />
      )}
    </Fragment>
  );
};

export default Department;
