import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/multiSlide.css";

function TrainingPartner() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      partialVisible={false}
      swipeable={true}
      showDots={true}
    >
      {partnersImages.map((img, index) => {
        return (
          <div className="slider-box img-slider" key={index}>
            <img src={img.url} alt="img" />
          </div>
        );
      })}
    </Carousel>
  );
}

export default TrainingPartner;

const partnersImages = [
  { url: "images/partners/partners1.png" },
  { url: "images/partners/partners2.png" },
  { url: "images/partners/partners3.png" },
  // { url: "images/partners/partners4.png" },
  // { url: "images/partners/partners5.png" },
  { url: "images/partners/partners6.png" },
  { url: "images/partners/partners7.png" },
  { url: "images/partners/partners8.png" },
  { url: "images/partners/partners9.png" },
  { url: "images/partners/partners10.png" },
  { url: "images/partners/partners11.png" },
  { url: "images/partners/partners12.png" },
  { url: "images/partners/partners13.png" },
  { url: "images/partners/partners14.png" },
  { url: "images/partners/partners15.png" },
  { url: "images/partners/partners16.png" },
  { url: "images/partners/partners17.png" },
  { url: "images/partners/partners18.png" },
  { url: "images/partners/partners19.png" },
  { url: "images/partners/partners20.png" },
  { url: "images/partners/partners21.png" },
];
