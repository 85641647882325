import React from 'react'
import '../../styles/recomendations.css'
import RecomendationCard from './RecomendationCard'
import circleImage from '../../utils/Images/rotatecircle.svg'
import hand from '../../utils/Images/hand.svg'
import smile from '../../utils/Images/smile.svg'
import arrow from '../../utils/Images/arrow.svg'
import RecVideoCard from './RecVideoCard'
import humanVideo from '../../utils/Images/videohuman.svg'

const Recomendations = () => {
  return (
    <div className='headerbox'>
      <div className='headerinner'></div>
      <div className='outerboxhead'>
        <div className='recheadinnerwrap'>
          <div className='reccon'>
            <p className='headpara'>Your Top Areas</p>
            <button type="button" className="btn btn-light">Hide Details</button>
          </div>
          <div className='areawrap'>
           <RecomendationCard image={circleImage}/>
          </div>
        </div>
        <div className='recheadinnerwrap'>
          <div className='reccon'>
            <p className='headpara'>Your Area For Improvement</p>
            <button type="button" className="btn btn-light">Show Deatils</button>
          </div>
          <div className='areawrap'>
           <RecomendationCard image={circleImage}/>
           <RecomendationCard image={hand}/>
           <RecomendationCard image={arrow}/>
           <RecomendationCard image={smile}/>
          </div>
        </div>
        <div className='recheadinnerwrap'>
          <div className='reccon'>
            <p className='headpara'>Recomendations Videos/Articles</p>
            <button type="button" className="btn btn-light">Show Deatils</button>
          </div>
          <div className='areawrap'>
           <RecVideoCard image="/videos/body.mp4"/>
           <RecVideoCard image="/videos/impression.mp4"/>
           <RecVideoCard image="/videos/interview.mp4"/>
           <RecVideoCard image="/videos/campus.mp4"/>
           {/* <RecVideoCard image="/videos/english.mp4"/> */}
           {/* <RecVideoCard image="/videos/interviewsuccess.mp4"/>
           <RecVideoCard image="/videos/skills.mp4"/>
           <RecVideoCard image="/videos/speak.mp4"/> */}
          </div> 
        </div>
      </div>
    </div>
  )
}

export default Recomendations