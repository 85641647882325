import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completedAssessmentAction } from "../../redux/slice/assessment/getCompletedAssessmentSlice";
import { getPurposeHandle, getToken, localDateFormat } from "../../utils/utils";
import Loader from "../Loader";

const CompletedAssessment = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [completedAssessment, setCompletedAssessment] = useState([]);
  const dispatch = useDispatch();
  const { getCompletedAssessment, createAssessment } = useSelector(
    (state) => state
  );
  const token = getToken();
  const data = { token };

  useEffect(() => {
    dispatch(completedAssessmentAction(data));
  }, [pageNumber, createAssessment.success]);

  useEffect(() => {
    setCompletedAssessment(getCompletedAssessment.assessment?.data?.dataItems);
  }, [getCompletedAssessment.success]);

  return (
    <div className="col-md-12">
      {getCompletedAssessment.loading ? (
        <Loader />
      ) : completedAssessment?.length > 0 ? (
        completedAssessment?.map((assessment, index) => {
          const date = localDateFormat(assessment.updatedAt);
          return (
            <div className="assessment-box">
              <div className="date-box">
                <span className="date">Last Updated : {date}</span>{" "}
                <span className="mike-box">
                  {getPurposeHandle(assessment.assessmentPurpose) ===
                  "Others" ? (
                    "NA"
                  ) : (
                    <img
                      src={`/images/icons/${getPurposeHandle(
                        assessment.assessmentPurpose
                      )}.svg`}
                      alt
                    />
                  )}
                </span>
              </div>
              <h2>{assessment.assessmentName}</h2>
              <div className="purpose">
                <span>Purpose: Reading</span> <span>Progress: N/A</span>
              </div>
              <div className="version-count-wrap">
                <div className="version-box">
                  <span>0</span>Version Count
                </div>
                {/* <div className="coins-box">
                  <span>0</span>Coins Earned
                </div> */}
              </div>
            </div>
          );
        })
      ) : (
        <div className="assessment-box no-completed-assessment">
          No Completed Assessment
        </div>
      )}
    </div>
  );
};

export default CompletedAssessment;
