import React from "react";

const Paginate = ({ pageNumber, setPageNumber, totalPages }) => {
  return totalPages > 1 ? (
    <div className="d-flex w-100 justify-content-end p-3 paginationBtns">
      {/* Previous Button */}
      <button
        disabled={pageNumber <= 0}
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setPageNumber(pageNumber - 1)}
        hidden={pageNumber <= 0}
      >
        Prev
      </button>
      {/* Button One */}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-primary ml-2 active-page-btn"
        onClick={() => setPageNumber(pageNumber)}
      >
        {pageNumber + 1}
      </button>
      {/* Button 2 */}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setPageNumber(pageNumber + 1)}
        hidden={pageNumber + 1 >= totalPages - 1}
      >
        {pageNumber + 2}
      </button>{" "}
      {/* Button  3 */}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setPageNumber(pageNumber + 2)}
        hidden={pageNumber + 2 >= totalPages - 1}
      >
        {pageNumber + 3}
      </button>{" "}
      {/* Button 4 */}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setPageNumber(pageNumber + 3)}
        hidden={pageNumber + 3 >= totalPages - 1}
      >
        {pageNumber + 4}
      </button>{" "}
      {/* Button 5 */}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setPageNumber(pageNumber + 4)}
        hidden={pageNumber + 4 >= totalPages - 1}
      >
        {pageNumber + 5}
      </button>{" "}
      <button
        disabled={pageNumber >= totalPages - 1}
        type="button"
        className="btn btn-outline-primary ml-2 mr-2"
        onClick={() => setPageNumber(pageNumber + 1)}
        hidden={pageNumber >= totalPages - 1}
      >
        Next
      </button>
    </div>
  ) : (
    ""
  );
};

export default Paginate;
