import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllAIReportsAction = createAsyncThunk(
  "getAllAIReports",
  async (
    { token, studentId, assignmentId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REPORT_API}report?studentId=${studentId}&assignmentId=${assignmentId}`,
        // `${process.env.REACT_APP_BASE_URL}api/hiv/student/ai/allreports`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // "x-access-token": `${token}`,
          },
        }
      );
      const processedData = await response.json();
      if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const getAllAIReportsSlice = createSlice({
  name: "getAllAIReports",
  initialState: {
    reports: [],
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // Get Users Request Handling
    [getAllAIReportsAction.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },

    [getAllAIReportsAction.fulfilled]: (state, { payload }) => {
      state.reports = payload;
      state.loading = false;
      state.success = true;
    },

    [getAllAIReportsAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.reports = payload;
      state.error = payload;
      state.success = false;
      console.log("Error : ", payload);
    },
  },
});

export default getAllAIReportsSlice.reducer;
