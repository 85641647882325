import React from 'react'
import { Link } from 'react-router-dom'
import HomeContainer from './HomeContainer'

function Otp() {
  return (
    <HomeContainer>
<section className="section-wrap signup-section">
  <div className="signup-box"><div className="back-btn"><Link to="sign-up"><img src="images/back-icon.png" alt="img" /></Link></div>
    <div className="signup-img"><img src="images/otp.jpg" /></div>
    <h2 className="text-left">Verify OTP</h2>
    <div className="sub-heading text-left" style={{color: '#540375'}}>Enter OTP sent on your phone number.</div>
    <ul>
      <li className="otp-box">
        <input type="password" tabIndex={1} />
        <input type="password" tabIndex={2} />
        <input type="password" tabIndex={3} />
        <input type="password" tabIndex={4} />
      </li>
      <li><input type="submit" defaultValue="Continue" /></li>
    </ul>
  </div>
</section>
</HomeContainer>
  )
}

export default Otp
