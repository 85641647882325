import React, { useState } from "react";
import AddParticipants from "../../component/educator/createAssessment/AddParticipants";
import AddQuestions from "../../component/educator/createAssessment/AddQuestions";
import AssessmentType from "../../component/educator/createAssessment/AssessmentType";
import EnterDetails from "../../component/educator/createAssessment/EnterDetails";
import SetAIParameters from "../../component/educator/createAssessment/SetAIParameters";
import { useFormik } from "formik";
import {
  assessmentDetailsSchema,
  assessmentType,
  levelSchema,
  participantsSchema,
  questionSchema,
} from "../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { addAsmtTeacherAction } from "../../redux/slice/teacherAssessment/addAsmtTeacherSlice";
import { getToken } from "../../utils/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const initialValues = {
  assessmentType: "1",
  // Enter details
  assessmentName: "",
  assessmentDesc: "",
  startDate: "",
  endDate: "",
  assessmentPurpose: "",
  assessmentResponseType: "",

  // level form
  aiLevel: "",
  questions: [],
  participants: [],
  level: "",

  // Weightage Form
  intro: "0",
  mainContext: "0",
  environment: "0",
  outro: "0",

  // Intro Form
  greeting: "",
  facialExpression: "",
  gratitude: "",
  customWord: "",
  customSentences: "",

  //Main Context
  voiceContext: "",
  environmentContext: "",
  bodyLangaugeContext: "",
  facialExpressionContext: "",

  // Outro Form
  greetingOutro: "",
  gratitudeOutro: "",
  customWordOutro: "",
  customSentencesOutro: "",

  environmentTab: "",
};

const CreateAssessment = () => {
  const [tab, setTab] = useState(0);
  const [routeFlag, setRouteFlag] = useState(false);
  const { addAsmtTeacher } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();

  useEffect(() => {
    routeFlag && navigate("/educator/assessment");
  }, [addAsmtTeacher.success]);

  const dynamicSchema = () => {
    switch (tab) {
      case 0:
        return assessmentType;
        break;

      case 1:
        return assessmentDetailsSchema;
        break;

      case 2:
        return questionSchema;
        break;

      case 3:
        return participantsSchema;
        break;

      case 4:
        return levelSchema;
        break;

      default:
        break;
    }
  };

  let {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    // here change schema based on Active tab
    validationSchema: dynamicSchema(),
    onSubmit: (values, action) => {
      const newValues = {
        assessmentName: values.assessmentName,
        assessmentType: values.assessmentType,
        description: values.assessmentDesc,
        startDate: values.startDate,
        endDate: values.endDate,
        assessmentPurpose: values.assessmentPurpose,
        assessmentAILevel: values.aiLevel,
        assessmentResponseType: values.assessmentResponseType,
        questionId: values.questions, // Array
        studentId: values.participants, // Array
        aiParametersLevel: "1",
        weightage: {
          intro: values.intro,
          mainContext: values.mainContext,
          environment: values.environment,
          outro: values.outro,
        },
        aiParametersIntro: {
          greeting: values.greeting,
          facialExpression: values.facialExpression,
          gratitude: values.gratitude,
          customWord: values.customWord,
          customSentences: values.customSentences,
        },
        aiParametersMainContext: {
          voiceContext: values.voiceContext,
          environmentContext: values.environmentContext,
          bodyLangaugeContext: values.bodyLangaugeContext,
          facialExpressionContext: values.facialExpressionContext,
        },
        aiParametersOutro: {
          greetingOutro: values.greetingOutro,
          gratitudeOutro: values.gratitudeOutro,
          customWordOutro: values.customWordOutro,
          customSentencesOutro: values.customSentencesOutro,
        },
        aiParametersEnvironment: values.environmentTab,
        status: "1",
      };

      tab < 9 && setTab(tab + 1);

      if (submit) {
        dispatch(addAsmtTeacherAction({ newValues, token }));
        setRouteFlag(true);
      }
    },
  });

  return (
    <div className="edu-right-side">
      <form className="teacherboard" onSubmit={handleSubmit}>
        <div className="teacherinnerbox">
          <p>Create Assessment</p>
        </div>
        <div className="create-asmt-container">
          <div className="tabs">
            <button type="button" className={`tab ${tab === 0 && "active"}`}>
              <span>1</span> Assessment Type
            </button>

            <button type="button" className={`tab ${tab === 1 && "active"}`}>
              <span>2</span> Enter Details
            </button>

            <button type="button" className={`tab ${tab === 2 && "active"}`}>
              <span>3</span> Add Questions
            </button>

            <button type="button" className={`tab ${tab === 3 && "active"}`}>
              <span>4</span> Add Participants
            </button>

            <button type="button" className={`tab ${tab >= 4 && "active"}`}>
              <span>5</span> Set AI Parameters
            </button>
          </div>

          {tab === 0 && (
            <AssessmentType
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          )}
          {tab === 1 && (
            <EnterDetails
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
          {tab === 2 && (
            <AddQuestions
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
          {tab == 3 && (
            <AddParticipants
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
          {tab >= 4 && (
            <SetAIParameters
              setTab={setTab}
              tab={tab}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}

          {errors.participants && touched.participants ? (
            <div className="invalid-msg">{errors.participants}</div>
          ) : null}

          {errors.questions && touched.questions ? (
            <div className="invalid-msg">{errors.questions}</div>
          ) : null}

          {errors.level && touched.level ? (
            <div className="invalid-msg">{errors.level}</div>
          ) : null}

          <div className="nav-btns">
            <button
              className="left"
              onClick={() => setTab(tab - 1)}
              hidden={tab <= 0}
              type="button"
            >
              Previous
            </button>

            <button
              className="right"
              // onClick={() =>  setTab(tab + 1)}
              hidden={tab > 8}
              type="submit"
              onClick={() => setSubmit(false)}
            >
              Next
            </button>

            <button
              className="right"
              type="submit"
              // onClick={() => setTab(tab + 1)}
              hidden={tab < 9}
              onClick={() => setSubmit(true)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAssessment;
