import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSelfAssessmentAction } from "../../redux/slice/assessment/getSelfAssessmentSlice";
import { getPurposeHandle, getToken, localDateFormat } from "../../utils/utils";
import Loader from "../Loader";

const SelfAssement = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [selfAssessmentData, setSelfAssessmentData] = useState([]);
  const dispatch = useDispatch();
  const { getSelfAssessment, createAssessment, getAllAIReports } = useSelector(
    (state) => state
  );

  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    setReportData(getAllAIReports?.reports.AllAIReports);
  }, [getAllAIReports]);

  const token = getToken();
  const data = { pageNumber, token };

  useEffect(() => {
    dispatch(getSelfAssessmentAction(data));
  }, [pageNumber, createAssessment.success]);

  useEffect(() => {
    setSelfAssessmentData(getSelfAssessment.selfAssessment?.data?.dataItems);
  }, [getSelfAssessment.success]);

  const createAssessmentSave = (asses, assesId) => {
    localStorage.setItem("assesment", asses);
    localStorage.setItem("assesmentId", assesId);
  };

  return (
    <div className="col-md-12">
      {/* <div className="assessment-box">
        <div className="date-box">
          <span className="date">Last Updated : 06 Feb, 2023</span>{" "}
          <span className="mike-box">
            <img src="/images/mike.svg" alt />
          </span>
        </div>
        <h2>Teaching Assessment</h2>

        <div className="purpose">
          <span>Purpose: Reading</span> <span>Progress: N/A</span>
        </div>
        <div className="version-count-wrap">
          <div className="version-box">
            <span>0</span>Version Count
          </div>
          <div className="coins-box">
            <span>0</span>Coins Earned
          </div>
          <div className="assessment-btn">
            <a href="#" className="btn-m yellow-btn">
              Take Assessment
            </a>
          </div>
        </div>
      </div> */}

      {getSelfAssessment.loading ? (
        <Loader />
      ) : selfAssessmentData?.length === 0 ? (
        <div className="assessment-box no-completed-assessment">
          No Assessment Found
        </div>
      ) : (
        selfAssessmentData?.map((assesment, index) => {
          let assesmentPurpose = getPurposeHandle(assesment.assessmentPurpose);
          let date = localDateFormat(assesment.updatedAt);

          return (
            <Link to={`/student/craeteAssesment/${assesment.id}`} key={index}>
              <div
                className="assessment-box"
                onClick={() =>
                  createAssessmentSave(assesment.assessmentName, assesment.id)
                }
              >
                <div className="date-box">
                  {/* <span className="date">Last Updated : 06 Feb, 2023</span>{" "} */}
                  <span className="date">Lasted Update : {date}</span>{" "}
                  <span className="mike-box">
                    {getPurposeHandle(assesment.assessmentPurpose) ===
                    "Others" ? (
                      "NA"
                    ) : (
                      <img
                        src={`/images/icons/${getPurposeHandle(
                          assesment.assessmentPurpose
                        )}.svg`}
                        alt
                      />
                    )}
                  </span>
                </div>
                {/* <h2>Mentor Kart Assessment</h2> */}
                <h2>{assesment.assessmentName}</h2>
                <div className="purpose">
                  <span>Purpose: {assesmentPurpose}</span>{" "}
                  <span>Progress: N/A</span>
                </div>
                <div className="version-count-wrap">
                  <div className="version-box">
                    <span>
                      {getAllAIReports?.reports.report?.length || "NA"}
                    </span>
                    Version Count
                  </div>
                  {/* <div className="coins-box">
                    <span>0</span>Coins Earned
                  </div> */}
                  {/* <div className="assessment-btn">
                  <Link to="/student/craeteAssesment">
                    <a className="btn-m yellow-btn">Take Assessment</a>
                  </Link>
                </div> */}
                </div>
              </div>
            </Link>
          );
        }) || (
          <div className="assessment-box no-completed-assessment">
            No Assessment Found
          </div>
        )
      )}

      {/* {selfAssessmentData?.length === 0 && (
        <div className="assessment-box no-completed-assessment">
          No Report Found
        </div>
      )} */}

      {/* <div className="assessment-box">
        <div className="date-box">
          <span className="date">Last Updated : 06 Feb, 2023</span>{" "}
          <span className="mike-box">
            <img src="/images/mike.svg" alt />
          </span>
        </div>
        <h2>Mentor Kart Assessment</h2>
        <div className="purpose">
          <span>Purpose: Reading</span> <span>Progress: N/A</span>
        </div>
        <div className="version-count-wrap">
          <div className="version-box">
            <span>0</span>Version Count
          </div>
          <div className="coins-box">
            <span>0</span>Coins Earned
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SelfAssement;
