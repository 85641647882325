import React, { useEffect, useState } from "react";
import QuestionCard from "../../component/educator/QuestionCard";
import "../../styles/questionBank.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Loader";
import Paginate from "../../component/common/Paginate";
import { getQuestionsAction } from "../../redux/slice/question/getQuestionsSlice";
import { getToken } from "../../utils/utils";

const QuestionBank = () => {
  const [questionsList, setQuestionsList] = useState([]);
  const { getQuestions } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    dispatch(getQuestionsAction({ token: getToken(), pageNumber }));
  }, [pageNumber]);

  useEffect(() => {
    setQuestionsList(getQuestions.questions);
  }, [getQuestions.success]);

  console.log(questionsList);

  return (
    <div className="qnbankouterbox_sec">
      <div className="qnbankouterbox">
        <div className="qnbankinner">
          <div>
            <p className="qnparagraph"> Question Bank</p>
          </div>
          <div className="qnicon">
            <div>
              <Link
                className="qniconpara"
                to="/educator/question-bank/add-question"
              >
                + Add Question
              </Link>
            </div>
          </div>
        </div>
        <div className="qncard">
          {getQuestions.loading ? (
            <Loader />
          ) : (
            questionsList?.dataItems?.map((question, index) => {
              return <QuestionCard {...question} key={index} />;
            })
          )}
        </div>
        <Paginate
          totalPages={questionsList?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </div>
  );
};

export default QuestionBank;
