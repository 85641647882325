import React, { memo, useState } from "react";
import parse from "html-react-parser";
import QuizCard from "./QuizCard";

const PreStudentCourseDetails = ({
  singleCourse,
  setDisableTab,
  disableTab,
  tabIndex,
  setTab,
  tab,
  // modelStop,
  // setIsQuiz,
}) => {
  const [showQuizNo, setShowQuizNo] = useState(0);
  const [score, setScore] = useState({
    correctAnswers: 0,
    incorrectAnswers: 0,
  });

  return singleCourse?.attachments?.map((singleCourse, index) => {
    if (singleCourse?.kind === "pdf_embed") {
      // setIsQuiz(false);
      return (
        <div className="surveybox pdfbox" key={index}>
          <div className="pdfwrapcour">
            <p className="readpara">{singleCourse?.name}</p>
            <div className="pdfdetail">
              <iframe src={singleCourse?.url} width="100%" height="500" />
              {/* <img
                className="pdfrender"
                src="/images/student/pdficon.svg"
                alt="pdf"
              /> */}
              {/* <p>PDF FILE</p> */}
            </div>
          </div>
        </div>
      );
    } else if (singleCourse?.kind === "quiz") {
      tabIndex === tab && setDisableTab(true);
      // setIsQuiz(true);

      // tab === tabIndex &&
      //   setTimeout(() => {
      //     setDisableTab(false);
      //     tab === index && setTab(tab + 1);
      //   }, 3 * 1000);
      // modelStop()
      // disableTab && clearTimeout()
      // setIsQuiz(true);
      // setTab(tab + 1);

      setTimeout(() => {
        setDisableTab(false);
        tab === index && setTab(tab + 1);
        // setIsQuiz(false);
      }, 5 * 60 * 1000);

      return (
        <div className="quizbox" key={index}>
          <p className="communicate">MyLanguage.AI - LANGUAGE LAB</p>
          <div className="outersurvey">
            {singleCourse?.quiz?.questions.map((questions, index, array) => {
              // <h3>QUESTIONS</h3>;
              return (
                showQuizNo === index && (
                  <div className="outerqnbox" key={index}>
                    <div>
                      <p className="qnpara">
                        QUESTION {index + 1} OF {array.length}
                      </p>
                      <QuizCard
                        qnNumber={index + 1}
                        question={questions.question}
                        options={questions?.answers}
                        correct_answers={questions.correct_answers}
                        setShowQuizNo={setShowQuizNo}
                        showQuizNo={showQuizNo}
                        arrayLength={array.length}
                        setScore={setScore}
                        score={score}
                      />
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      );
    } else if (singleCourse?.kind === "video") {
      // setIsQuiz(false);
      return (
        <div key={index}>
          {/* <p className="videparawrap">HIVE STEPS - LANGUAGE LAB</p> */}
          {/* <div className="ladyvideoimg"> */}
          {/* <img src='/images/student/lady.svg' alt='ladyimage' /> */}
          <video width="100%" controls>
            <source src={singleCourse?.url} type="video/mp4" />
          </video>
          {/* </div> */}
        </div>
      );
    } else if (singleCourse?.kind === "text") {
      // setIsQuiz(false);
      return (
        <div key={index}>
          {parse(singleCourse?.text.replaceAll("-src", " src"))}{" "}
        </div>
      );
    } else if (singleCourse?.kind === "audio") {
      // setIsQuiz(false);
      return (
        <audio controls key={index}>
          <source src={singleCourse?.url} type="audio/mpeg" />
          Your browser does not support the html audio tag.
        </audio>
      );
    }
  });
};

export default memo(PreStudentCourseDetails);

// <div className="outersurvey">
//   <div className="innersurvey"></div>

// </div>
// </div>

/* <p className="engpara">English in Mongolian & Multilingual Contexts</p>
    <img src="/images/student/lady.svg" alt="ladyimg" />
    <div className="outerparawrap">
      <ul>
        <li className="listpara">
          To think about how one learns to speak a language{" "}
        </li>
        <li className="listpara">
          To find out why we want to communicate in English
        </li>
        <li className="listpara">
          To find out what it means to say English is a world language, and
        </li>
        <li className="listpara">
          To think about international languages and multilingualism.{" "}
        </li>
      </ul>
      <p>
        Why do we want to speak English? Many of us have studied this language
        for years at school. We can read English, and we can write at least
        some English. But we are not confident about speaking this language.
        Why not? How can we begin to speak English, and speak it well?
      </p>
      <p>
        In this module, we will see that it is important to listen to a
        language and to read a few pages of it every day, to develop speaking
        and writing skills. We shall think about why English is important to
        us. We shall also get some information about English as an
        international language. What is n international language? Who speaks
        it? We shall think about descriptions of speakers such as "native
        speakers", "second language speakers", "multilingual", and
        "monolinguals". We shall see that there are many varieties of English
        in the{" "}
      </p>
    </div> */
