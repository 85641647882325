import React from "react";
import "../../styles/comparative.css";
import { GiPerson } from "react-icons/gi";
import { BsSpeedometer2 } from "react-icons/bs";
import { localDateFormat } from "../../utils/utils";

const Comparative = ({ report }) => {
  console.log(report);
  return (
    <div className="headerbox">
      <div className="headerinner"></div>
      <div className="outerboxhead">
        <div className="headinnerwrap">
          <div className="headcon">
            <p className="headpara">Self Comparative Analysis</p>
          </div>
          <div className="selfwrap">
            <div className="selfone">
              {/* <div className="humanbox">
                <p className="percenthuman">42%</p>
                <GiPerson className="humanicon" />
                <p className="percenthuman">March 10, 2023</p>
                <p className="reporthuman">This Report</p>
              </div> */}
              <div className="humanbox">
                <p className="percenthuman">
                  {Math.floor(report[0]?.totalAverage)}%
                </p>
                <GiPerson className="humantwo" />
                <p className="percenthuman">
                  {localDateFormat(report[0]?.created_at)}
                </p>
                <p className="reporthuman">This Report</p>
              </div>
              {/* <div className="humanbox">
                <p className="percenthuman">42%</p>
                <GiPerson className="humanicon" />
                <p className="percenthuman">March 10, 2023</p>
                <p className="reporthuman">This Report</p>
              </div> */}
            </div>
            <div className="meterbox">
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Intro</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Facial Expressions</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Body Language</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Language</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">
                  {Math.floor(report[0]?.aiReport?.voice.energy)}%
                </p>
                <p className="introspeed">Voice</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Outro</p>
              </div>
            </div>
          </div>
        </div>
        <div className="headinnerwrap">
          <div className="headcon">
            <p className="headpara">Inter Comparative Analysis</p>
          </div>
          <div className="selfwrap">
            <div className="selfone">
              {/* <div className="humanbox">
                <p className="percenthuman">42%</p>
                <GiPerson className="humanicon" />
                <p className="percenthuman">March 10, 2023</p>
                <p className="reporthuman">This Report</p>
              </div> */}
              <div className="humanbox">
                <p className="percenthuman">
                  {Math.floor(report[0]?.totalAverage)}%
                </p>
                <GiPerson className="humantwo" />
                <p className="percenthuman">
                  {localDateFormat(report[0]?.created_at)}
                </p>
                <p className="reporthuman">This Report</p>
              </div>
              {/* <div className="humanbox">
                <p className="percenthuman">42%</p>
                <GiPerson className="humanicon" />
                <p className="percenthuman">March 10, 2023</p>
                <p className="reporthuman">This Report</p>
              </div> */}
            </div>
            <div className="meterbox">
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Intro</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Facial Expressions</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Body Language</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Language</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">
                  {Math.floor(report[0]?.aiReport?.voice.energy)}%
                </p>
                <p className="introspeed">Voice</p>
              </div>
              <div className="speebox">
                <BsSpeedometer2 className="metericon" />
                <p className="speedpercent">0%</p>
                <p className="introspeed">Outro</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comparative;
