import React from 'react'
import '../../styles/recomendations.css'


const RecVideoCard = ({ image }) => {

  return (
    <div className='recard'>
      {console.log(image)}
      <div className='recimg'>

        <video controls>
          <source src={image} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <div className='playwrap'>
    <img className='playimgrec' src='/images/student/play.svg' alt='play'/>
    </div> */}

      {/* <div className='movecard'>
      <p className='pararec'>Need To Focus On Body Moments</p>
    </div> */}
    </div>
  )
}

export default RecVideoCard