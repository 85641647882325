import React from 'react'
import Modal from '../Modal';
import classes from "../../styles/Modal.module.css";

const EventModal = ({ image, onClose }) => {

    return (
        <Modal
            onClose={onClose}
            modal={classes.modal}
            content={classes.content}
            backdrop={classes.backdrop}
        >
            <div>
                <img
                    src={image} style={{ width: "100%" }} alt='images'
                />
            </div>
        </Modal>
    )
}

export default EventModal;