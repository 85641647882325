import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/multiSlide.css";

function OurStakeholders() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Carousel responsive={responsive} partialVisible={false} swipeable={true}>
      <div className="slider-box">
        <div className="slider-img">
          <img src="images/icons/stakeholderIcon1.png" alt="img" />
        </div>
        <h3>Individuals</h3>
        <div className="sllider-text">
          <p>
            Get your students ready and active for the mysterious corporate
            world with our AI-driven and skill-based training platform.
          </p>
        </div>
      </div>
      <div className="slider-box">
        <div className="slider-img">
          <img src="images/icons/stakeholderIcon2.png" alt="img" />
        </div>
        <h3>University</h3>
        <div className="sllider-text">
          <p>
            Select from the Industry and Market ready pool of candidates. Find
            the right fit for your organization to build your core team.
          </p>
        </div>
      </div>
      <div className="slider-box">
        <div className="slider-img">
          <img src="images/icons/stakeholderIcon3.png" alt="img" />
        </div>
        <h3>Corporations</h3>
        <div className="sllider-text">
          <p>
            Make a social change by providing quality education and training to
            the youth of our country.
          </p>
        </div>
      </div>

      {/* <div className="slider-box">
        <div className="slider-img">
          <img src="images/icons/stakeholderIcon (1).png" alt="img" />
        </div>
        <h3>Individuals</h3>
        <div className="sllider-text">
          <p>
            Get your students ready and active for the mysterious corporate
            world with our AI-driven skill-based training platform.
          </p>
        </div>
      </div>
      <div className="slider-box">
        <div className="slider-img">
          <img src="images/sliderimg.png" alt="img" />
        </div>
        <h3>University</h3>
        <div className="sllider-text">
          <p>
            Get your students ready and active for the mysterious corporate
            world with our AI-driven skill-based training platform.
          </p>
        </div>
      </div>
      <div className="slider-box">
        <div className="slider-img">
          <img src="images/sliderimg.png" alt="img" />
        </div>
        <h3>Corporations</h3>
        <div className="sllider-text">
          <p>
            Make a social change by providing quality education and training to
            the youth of our country.
          </p>
        </div>
      </div> */}
    </Carousel>
  );
}

export default OurStakeholders;
