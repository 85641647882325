import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportsAction } from "../../redux/slice/assessment/getAllReportsSlice";
import { getAllReportDataAction } from "../../redux/slice/aiReport/AllReportDataSlice";
import { getToken, getUserLocal, localDateFormat } from "../../utils/utils";
import useCheckSize from "../../hooks/useCheckSize";

const AllReportsAssessment = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [reportGen, setReportGen] = useState([]);
  const dispatch = useDispatch();
  const { getAllReports, createAssessment, getAllReportData } = useSelector(
    (state) => state
  );

  const locUser = getUserLocal();
  const token = getToken();
  const data = { pageNumber, token };

  useEffect(() => {
    if (locUser?.roles[0] == "ROLE_STUDENT") {
      dispatch(getAllReportDataAction({ token, id: locUser?.id }));
    }
  }, []);

  useEffect(() => {
    setReportGen(getAllReportData.report?.data);
  }, [getAllReportData.success]);

  useEffect(() => {
    dispatch(getAllReportsAction(data));
  }, [pageNumber, createAssessment.success]);

  const size = useCheckSize();

  return (
    <div className="teacherboard score-tab" style={{ width: "100%" }}>
      <div className="teacherinnerbox">
        {/* <p>All Scores</p> */}

        {/* <div className="input">
          <input type="search" name="search" id="search" placeholder="Search" />
          <img src="/images/icons/searchicon.svg" alt="" />
        </div> */}

        {/* <div className="input">
          <select name="addDepartment" id="addDepartment">
            <option value="">Add Department</option>
            <option value="students">Students</option>
            <option value="teacher">Teacher</option>
          </select>
        </div> */}
      </div>

      <div className="tableContainer">
        <table className="table table-striped table-hover mt-4">
          <thead className="table-light">
            <tr>
              <th scope="col">S.No</th>
              {size > 768 ? (
                <th scope="col">Campaign Name</th>
              ) : (
                <th scope="col">Camp.name</th>
              )}

              <th scope="col">Score</th>
              <th scope="col">Date</th>
              <th scope="col">Report</th>
            </tr>
          </thead>

          <tbody>
            {reportGen?.map((rep, index) => {
              return (
                <tr className="align-middle rowstyle">
                  <th scope="row">{index + 1}</th>
                  <td>{rep.assignmentName}</td>
                  <td>{rep.totalAverage}</td>
                  <td>{localDateFormat(rep.createdAt)}</td>
                  <td>
                    <a
                      href={`https://dev.princetonhive.com/student/craeteAssesment/insight/${rep.reportId}`}
                    >
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#c8aace",
                          color: "#fff",
                        }}
                        className="btn "
                      >
                        View
                      </button>
                    </a>
                  </td>
                </tr>
              );
            })}
            {/* <tr className="align-middle">
              <th>01</th>
              <td>Asif Imam</td>
              <td>asif.imam@volansinfo.com</td>
              <td>BTech</td>
              <td>9911424995</td>
            </tr> */}
            {/* <tr className="align-middle">
              <th>02</th>
              <td>Pankaj Kumar</td>
              <td>pankaj.kumar@volansinfo.com</td>
              <td>MBA</td>
              <td>9911471995</td>
            </tr> */}
            {/* <tr className="align-middle">
              <th>03</th>
              <td>Parwez Alam</td>
              <td>parwez.alam@volansinfo.com</td>
              <td>BCA</td>
              <td>9911471456</td>
            </tr> */}
            {/* <tr className="align-middle">
              <th>04</th>
              <td>Mohd Mustakim</td>
              <td>Mustakim@volansinfo.com</td>
              <td>BTech</td>
              <td>9911471834</td>
            </tr> */}
            {/* <tr className="align-middle">
              <th>05</th>
              <td>Lovenish Singh</td>
              <td>Lovenish.Singh@volansinfo.com</td>
              <td>BBA</td>
              <td>9911478672</td>
            </tr> */}
            {/* <tr className="align-middle">
              <th>06</th>
              <td>Ranjeet Kumar</td>
              <td>ranjeet.kumar@volansinfo.com</td>
              <td>BTech</td>
              <td>9911479867</td>
            </tr> */}
          </tbody>
        </table>
        {reportGen?.length == 0 && (
          <div className="center w-100">
            <p>No Report Available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllReportsAssessment;
