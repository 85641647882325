import React from 'react'
import Header from '../Header'
import ContactCard from './ContactCard';
import '../../styles/Contact.css'
import Footer from '../Footer';
import Voice from '../../utils/Images/voice.svg';
import HandShake from '../../utils/Images/handshake.svg';
import Writting from '../../utils/Images/writting.svg'

const ContactUs = () => {
    return (
        <div>
            <Header />
            <div className='contactwrap'>
                <div className='insidecontact'>
                    <p className='contactpara'>Contact Us</p>
                    <p className='contdesc'> Hive Steps provides an AI-driven employer platform to discover and hire detailed -oriented, skill-based, and bias-free talent. Analyze and grow with Hive Steps effortlessly.</p>
                </div>
                <div className='contactcardwrap'>
                    <ContactCard image={Voice} />
                    <ContactCard image={HandShake} />
                    <ContactCard image={Writting} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs