import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify, removeUserSession } from "../../../utils/utils";

export const addQuestionAction = createAsyncThunk(
  "addQuestion",
  async ({ formData, token }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/vol/question`,
        {
          method: "POST",
          body: formData,
          headers: {
            "x-access-token": `${token}`,
          },
        }
      );

      const processedData = await response.json();

      if (response.status === 401) {
        removeUserSession();
        return rejectWithValue(processedData);
      } else if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addQuestionSlice = createSlice({
  name: "addQuestion",
  initialState: {
    question: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // addQuestion Request Handling
    [addQuestionAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [addQuestionAction.fulfilled]: (state, { payload }) => {
      state.question = payload;
      state.loading = false;
      state.success = true;
      state.error = false;
      notify(payload.message);
    },
    [addQuestionAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default addQuestionSlice.reducer;
