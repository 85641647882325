import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCoursesAction = createAsyncThunk(
  "getCourses",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://developers.teachable.com/v1/courses`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            ApiKey: `HX6GGtrLCIkmpPXUye4xgqzHndxC21oe`,
          },
        }
      );
      const processedData = await response.json();
      if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const getCoursesSlice = createSlice({
  name: "getCourses",
  initialState: {
    courseData: [],
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // Get Users Request Handling
    [getCoursesAction.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },

    [getCoursesAction.fulfilled]: (state, { payload }) => {
      state.courseData = payload.courses;
      state.loading = false;
      state.success = true;
    },

    [getCoursesAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
      console.log("Error : ", payload);
    },
  },
});

export default getCoursesSlice.reducer;
