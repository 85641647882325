import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../component/Loader";
import { getCourseDetailAction } from "../redux/slice/course/getCourseDetailSlice";
import CourseDetailsAccordion from "./CourseDetailsAccordion";

const CourseDetail = () => {
  const { getCourseDetail } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentCourseId = location.pathname.split("/")[3];

  useEffect(() => {
    dispatch(getCourseDetailAction(currentCourseId));
  }, []);

  return (
    <div className="middle-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Course Detail</h1>
        {getCourseDetail.loading ? <Loader /> : <CourseDetailsAccordion />}
      </div>
    </div>
  );
};

export default CourseDetail;
