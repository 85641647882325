import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify, removeUserSession } from "../../../utils/utils";

export const getAllBlogsAction = createAsyncThunk(
  "getAllBlogs",
  async (pageNumber, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/hiv/blogs?page=${pageNumber}`
      );

      const processedData = await response.json();

      if (response.status === 401) {
        removeUserSession();
        return rejectWithValue(processedData);
      } else if (response.status !== 200) {
        return rejectWithValue(processedData);
      } else if (response.status === 200) {
        return fulfillWithValue(processedData);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getAllBlogsSlice = createSlice({
  name: "getAllBlogs",
  initialState: {
    blogs: null,
    loading: false,
    error: "",
    message: "",
    success: false,
  },
  extraReducers: {
    // getAllBlogs Request Handling
    [getAllBlogsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [getAllBlogsAction.fulfilled]: (state, { payload }) => {
      state.blogs = payload.data;
      state.loading = false;
      state.success = true;
      state.error = false;
      notify(payload.message);
    },
    [getAllBlogsAction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.message = payload.message;
      state.success = false;
      notify(payload.message, "error");
      console.log("Error : ", payload);
    },
  },
});

export default getAllBlogsSlice.reducer;
