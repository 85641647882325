import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "department",
    label: "Department",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "report", label: "Report", minWidth: 100 },
];

function createData(name, email, department, report) {
  return { name, email, department, report };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const rows = [
  createData("Asif Imam", "asif@gmail.com", "CSE", "Report"),
  createData("Prince", "prince@gmail.com", "CSE", "Report"),
  createData("Parwez Aalam", "parwez@gmail.com", "Arts", "Report"),
  createData("Pankaj Kumar", "pankaj@gmail.com", "NodeJS", "Report"),
  createData("Mustakim", "Mustakim@gmail.com", "Python", "Report"),
  createData("Asif Imam", "asif@gmail.com", "CSE", "Report"),
  createData("Prince", "prince@gmail.com", "CSE", "Report"),
  createData("Parwez Aalam", "parwez@gmail.com", "Arts", "Report"),
  createData("Pankaj Kumar", "pankaj@gmail.com", "NodeJS", "Report"),
  createData("Mustakim", "Mustakim@gmail.com", "Python", "Report"),
  createData("Asif Imam", "asif@gmail.com", "CSE", "Report"),
  createData("Prince", "prince@gmail.com", "CSE", "Report"),
  createData("Parwez Aalam", "parwez@gmail.com", "Arts", "Report"),
  createData("Pankaj Kumar", "pankaj@gmail.com", "NodeJS", "Report"),
  createData("Mustakim", "Mustakim@gmail.com", "Python", "Report"),
];

export default function ScoreEducatorTable() {
  return (
    <Paper sx={{ width: "100%", marginY: 5 }}>
      <TableContainer sx={{ maxHeight: 312 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 57,
                    minWidth: column.minWidth,
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
