import React, { Fragment, useEffect, useState } from "react";
import AssessmentTable from "./AssessmentTable";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import useCheckSize from "../../hooks/useCheckSize";

const QuestionsTab = ({ questions }) => {
  // const { getQuestions } = useSelector((state) => state);
  // const [questionsList, setQuestionsList] = useState([]);

  // useEffect(() => {
  //   setQuestionsList(getQuestions.questions);
  // }, [getQuestions.success]);

  const { getDepartments } = useSelector((state) => state);

  const size = useCheckSize();

  function getDepartment(id) {
    const department = getDepartments?.departments?.find((department) => {
      return id === department.id;
    });
    if (department) {
      return department.departmentName;
    } else {
      return "NA";
    }
  }

  return (
    <Fragment>
      {/* <div className="table-upper-ctrl space-between">
        <div className="left">
          <label htmlFor="show">Show</label>
          <select name="show" id="show">
            <option value="10">10</option>
            <option value="10">20</option>
            <option value="10">30</option>
            <option value="10">40</option>
          </select>
          Entries
        </div>

        <div className="right">
          <label htmlFor="search">Search</label>
          <input type="search" name="search" id="search" />
        </div>
  </div> */}

      <table className="table table-striped table-hover">
        <thead className="table-light">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Question</th>
            <th scope="col">Level</th>
            <th scope="col">Department</th>
            {size > 768 ? (
              <th scope="col">Question Video</th>
            ) : (
              <th scope="col">Q.Video</th>
            )}
            {/* <th scope="col">Duration</th> */}
          </tr>
        </thead>

        <tbody>
          {questions?.map((question, index) => {
            return (
              <tr className="align-middle">
                <th scope="row">{index + 1}</th>
                <td style={{ width: "40%" }}>{question.questionName}</td>
                <td>{question.level}</td>
                <td>{getDepartment(question.departmentId)}</td>
                <td>
                  {/* <button className="videoIconBtn"> */}
                  <img
                    src={question.questionImage}
                    alt=""
                    height="60"
                    width="100"
                  />
                  {/* </button>{" "} */}
                </td>
                {/* <td>Text</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <div className="table-lower-ctrl space-between">
        <span className="left">Showing 1 to 1 of 1 Entries</span>
        <div className="right">
          <span>Previous</span>
          <button>1</button>
          <span>Next</span>
        </div>
      </div> */}
    </Fragment>
  );
};

export default QuestionsTab;
