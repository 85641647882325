import React, { useState } from "react";
import MultipleUser from "../../component/educator/MultipleUser";
import SingleUser from "../../component/educator/SingleUser";
import "../../styles/addUser.css";

const AddUser = () => {
  const [user, setUser] = useState("single");

  const csvData =
    "fname,lname,email,gender,dob,mnumber,address,city,state,pincode,country,department";
  const handleDownload = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "users-data-template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="userbox">
      <div className="innerbox">
        <p>Add User</p>
      </div>
      <div className="selectwrapper">
        <div className="selectinneruser text-center custom-width">
          {user == "single" ? (
            <div className="userselect">
              <p className="usermultipara">
                Please Select Single or Multiple User
              </p>
              <p className="required">*</p>
            </div>
          ) : (
            <div className="usermulti">
              <div className="userselect">
                <p className="usermultipara">
                  Please Select Single or Multiple User
                </p>
                <p className="required">*</p>
              </div>
              <div className="multibtn">
                <img src="/images/educator/download.svg" alt="download" />
                <button
                  onClick={handleDownload}
                  type="button"
                  className="btn btn-warning"
                >
                  Download template
                </button>
              </div>
            </div>
          )}
          <div>
            <select
              className="textfields"
              name="cars"
              id="level"
              required
              onChange={(e) => setUser(e.target.value)}
            >
              <option className="values" value="single">
                Single
              </option>
              <option className="values" value="multiple">
                Multiple
              </option>
            </select>
          </div>
        </div>
        {user == "single" ? <SingleUser /> : <MultipleUser />}
      </div>
    </div>
  );
};

export default AddUser;
