import React from 'react'
import CardWrapper from './CardWrapper'


const Voice = ({ headerValue, image1 }) => {
  return (
    <CardWrapper headerValue={headerValue} image1={image1}>
    <div className='studentwrap'>
        <div className='ratingcriteria'>
            <p className='feature'>Features</p>
            <p>100-81%=Excellent</p>
            <p>80-61%=Good</p>
            <p>60-41%=Average</p>
            <p>40-21%=Need Improvement</p>
            <p>20-0%=Poor</p>
        </div>
        <div className='ratingcriteria'>
        <p className='feature'>BenchMark</p>

            <p>100-81%=Excellent</p>
            <p>80-61%=Good</p>
            <p>60-41%=Average</p>
            <p>40-21%=Need Improvement</p>
            <p>20-0%=Poor</p>
        </div>
    </div>
</CardWrapper>
  )
}

export default Voice