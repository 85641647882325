import React from "react";

const EducatorCourseDetail = () => {
  return (
    <div className="middle-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 top-ade">
            <img src="/images/ade.png" alt="ade" />
          </div>
        </div>
        <h1 className="pagetitle">Course Detail</h1>
      </div>
    </div>
  );
};

export default EducatorCourseDetail;
