import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CourseFullDetail from "../component/CourseFullDetail";
import Loader from "../component/Loader";
import "../styles/courseDetailsAccordion.css";

const CourseDetailsAccordion = () => {
  let [openAccordian, setOpenAccordian] = useState(0);
  const [loading, setLoading] = useState(0);
  const [error, setError] = useState(false);
  const { getCourseDetail } = useSelector((state) => state);
  const [courseData, setCourseData] = useState([]);

  let openAcc = (num) => {
    if (openAccordian === num) {
      setOpenAccordian(null);
    } else {
      setOpenAccordian(num);
    }
  };

  let openNext = (num) => {
    setOpenAccordian(num + 1);
  };

  let openPrev = (num) => {
    setOpenAccordian(num - 1);
  };

  useEffect(() => {
    let allDataArray = () => {
      let newData = [];
      getCourseDetail.courseData.lecture_sections?.map((lectures, index) => {
        return lectures.lectures?.map((lecture, index) => {
          return newData.push(lecture.id);
        });
      });
      return newData;
    };
    setCourseData(allDataArray());
  }, [getCourseDetail.success]);

  return (
    <>
      {error ? (
        <div className="errorbox">
          <img
            className="errorimage"
            src="/images/errorpage.jpg"
            alt="errorimage"
          />
          <p className="errormessage">
            Some Error occurred! Please refresh the page after some time.
          </p>
        </div>
      ) : getCourseDetail.loading ? (
        <Loader />
      ) : (
        courseData?.map((course, index, arrayRef) => {
          return (
            <CourseFullDetail
              data={course}
              num={index}
              setLoading={setLoading}
              loading={loading}
              setError={setError}
              finalAccordian={arrayRef.length - 1}
              openAccordian={openAccordian}
              openAcc={openAcc}
              openNext={openNext}
              openPrev={openPrev}
            />
          );
        })
      )}

      {/* <AccordianComponent
        openNext={() => openNext(0)}
        openPrev={() => openPrev(0)}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name="Text"
        oaId={0}
        click={() => openAcc(0)}
      >
        <div className="accordian-content">
          <img src="/images/Rectangle 475.png" alt="img" />
          <h3>
            This Module on Language Skills aims to achieve
            <br /> the following objectives:
          </h3>
          <ul>
            <li>to think about how one learns to speak a language</li>
            <li>to find out why we want to communicate in English</li>
            <li>
              to find out what it means to say English is a world language, and
            </li>
            <li>to think about international languages and multilingualism.</li>
          </ul>
          <p>
            Why do we want to speak English? Many of us have studied this
            language for years at school. We can read English, and we can write
            at least some English. But we are not confident about speaking this
            language. Why not? How can we begin to speak English, and speak it
            well?
          </p>
          <p>
            In this module, we will see that it is important to listen to a
            language and to read a few pages of it every day, to develop
            speaking and writing skills. We shall think about why English is
            important to us. We shall also get some information about English as
            an international language. What is n international language? Who
            speaks it? We shall think about descriptions of speakers such as
            "native speakers", "second language speakers", "multilingual", and
            "monolinguals". We shall see that there are many varieties of
            English in the world, but what matters for global communication is
            that we use as standard a variety of English as possible, and become
            as proficient in this variety as we can.
          </p>
          <p>
            A question we often ask about a small child is, "Does (s)he speak
            yet?' A child "picks up" language, and "learns" to talk, just as
            (she "learns" to walk. Walking and taking come naturally to a child
            as it grows. In our country, the child may grow up speaking more
            than one language, if these languages are spoken in the home and in
            the neighborhood. We call this multilingualism.
          </p>
          <p>
            A child speaks a language or languages much before (s)he starts
            going to school. To know a language, then, is first of all to be
            able to speak it as easily and naturally as a three-year-old child
            does. Later on, at school, the child will go on to read and write,
            as you and I do. But unless we can speak a language at will, we may
            feel that we do not really know the language.
          </p>
          <p>
            Since you are taking this module, you are probably saying to
            yourself: "But I did not learn English as a child! What can I do
            now? Can I become a child again?' No, but you can do some of the
            things a child does when (s)he learns language.
          </p>
          <p>
            The child listens before (s)he speaks. A baby may understand a few
            words at six months of age, but it has been listening ever since it
            was born, and even a little before that! The baby may not speak any
            words until it is nine months old. By the time it is three years
            old, though, it can speak at length.
          </p>
          <p>
            So your first strategy is to listen to the language you want to
            communicate in. Perhaps your friends and your family don't speak
            English. Never mind. Since you are an adult, you can listen to the
            radio, and watch television or films where people speak English.
            Don't worry if at first, you don't understand very much of what you
            hear! If you listen to the same kind of program regularly - such as
            the news, sports commentary, or quiz programs - you will soon begin
            to understand it better.
          </p>
          <p>
            Just as you must listen in order to be able to speak, you must read
            regularly and at length, to be able to speak or to write. Make it a
            habit to read an English newspaper every day. Try to read magazines
            and books in English. Make sure that the English in these books and
            magazines is written by writers who know their craft!
          </p>
          <p>
            A child learns language wherever it can, whenever it can: anyone who
            uses a language is a language teacher for the child! You too can
            "pick up" language, in this case, English, if you actively look for
            opportunities to use it. Use it to listen and read, 7speak and
            write; and learn whenever you can, from anyone who can speak to you,
            or anything you read. Practice makes perfect!
          </p>
          <p>
            You can also learn more about the language by consulting reference
            Practice materials such as dictionaries, Video Lessons, and E-books
            available in the section devoted to Modules of Listening, Speaking,
            Reading, and Writing.
          </p>
        </div>
      </AccordianComponent> */}

      {/* <AccordianComponent
        openNext={() => openNext(1)}
        openPrev={() => openPrev(1)}
        withQa={"withQa"}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name="Survey"
        oaId={1}
        click={() => openAcc(1)}
      >
        <div className="question-answers">
          <h3>QUESTION 1 OF 2</h3>
          <h4>
            In what order does a child learn these language skills: listening,
            speaking, reading, and writing?
          </h4>
          <textarea name="textarea"></textarea>
        </div>
      </AccordianComponent> */}
      {/* <AccordianComponent
        openNext={() => openNext(2)}
        openPrev={() => openPrev(2)}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name="PDF"
        oaId={2}
        click={() => openAcc(2)}
      >
        <p>PDF</p>
      </AccordianComponent> */}
      {/* <AccordianComponent
        openNext={() => openNext(3)}
        openPrev={() => openPrev(3)}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name="Audio"
        oaId={3}
        click={() => openAcc(3)}
      >
        <div className="accordian-content">
          <audio controls>
            <source src="/images/Jan-Gan-Man.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <img src="/images/Rectangle 475.png" alt="img" />
          <h3>
            This Module on Language Skills aims to achieve
            <br /> the following objectives:
          </h3>
          <p>
            Welcome to the segment on speed reading before you begin the process
            of improving your reading skills with Princeton Hive Grad you need
            to know where you are starting from reading performance is
            traditionally measured purely in terms of comprehension but most
            people want to be able to deal with the daily reading faster and yet
            to recall it effectively when required. this module is devoted to
            assessing the starting point for measuring your present reading
            speed and post-reading would be caught. it is important to measure
            the letter because if the record is not good immediately after
            reading something it is not likely to improve later. You will find 2
            exercises in the following segment that will enable you to do this
            together with instructions on precisely how to complete them you're
            reading performance will be tested and recorded in hive grade AI
            tool working through 2 exercises taking an average of your results
            and then mocking these on the progress as you read these exercises
            you should try to read them as quickly as you feel you can and still
            take the information in you want after all to see how quickly you
            can read before you try new techniques to develop reading skills
            when you come to the answering questions of the recall test you
            should avoid guessing answers if you do not know them if you guess
            the answers you may be right and this will make you think your
            recall is better than it really is but the same reason you should
            not try to spot questions that is try as you read to memorize
            isolated bits of information on the off chance that they may be
            required. To be honest with yourself as it is possible to be that
            figure will accurately whether or not you have improved their
            reading skills and by how much.
          </p>
        </div>
      </AccordianComponent> */}
      {/* <AccordianComponent
        openNext={() => openNext(4)}
        openPrev={() => openPrev(4)}
        finalAccordian={finalAccordian}
        openAccordian={openAccordian}
        name="Quiz"
        oaId={4}
        click={() => openAcc(4)}
      >
        <p>Quiz</p>
      </AccordianComponent> */}
    </>
  );
};

export default CourseDetailsAccordion;
