import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/uploadVideo.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { aiReportRequestAction } from "../../redux/slice/aiReport/aiReportRequestSlice";
import { uploadVideoSchema } from "../../utils/schema";
import { notify } from "../../utils/utils";

const UploadVideo = () => {
  const [routeFlag, setRouteFlag] = useState(false);
  const [userData, setUserData] = useState(null);
  const { getUser, aiReportRequest } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getUser.success && setUserData(getUser?.userData);
  }, [getUser.success]);

  useEffect(() => {
    if (routeFlag && aiReportRequest.success) {
      notify(
        "File uploded successfully for AI report please wait for 3 to 5 min "
      );
      navigate("/student/craeteAssesment");
    }
  }, [aiReportRequest.success, routeFlag]);

  const { values, errors, touched, handleSubmit, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        video: "",
      },
      validationSchema: uploadVideoSchema,
      onSubmit: (values, action) => {
        console.log(values);

        const formData = new FormData();
        formData.append("videoFile", values.video);
        formData.append("teacherId", userData.teacherId);
        formData.append("studentId", userData.id);
        formData.append("studentUUID", userData.uuid);
        formData.append("assignmentId", localStorage.getItem("assesmentId"));
        formData.append("assignmentName", localStorage.getItem("assesment"));

        dispatch(aiReportRequestAction(formData));
        setRouteFlag(true);
        navigate("/student/dashboard");
        localStorage.removeItem("assesment");
        localStorage.removeItem("assesmentId");
        notify(
          "File uploded successfully for AI report please wait for 3 to 5 min "
        );

        // action.resetForm();
      },
    });

  console.log(userData);

  return (
    <form onSubmit={handleSubmit} className="uploadwrapper">
      <div className="selectfile">
        <input
          type="file"
          accept="video/mp4"
          name="video"
          onChange={(e) => setFieldValue("video", e.currentTarget.files[0])}
          onBlur={handleBlur}
        />
        <p className="filesupport">"Works with Mp4 and Max size 500MB</p>
        {errors.video && touched.video ? (
          <div className="invalid-msg">{errors.video}</div>
        ) : null}
      </div>
      <div className="uploadbtn">
        <Link to="/student/add-video">
          <button type="button" className="btn btn-light">
            Back
          </button>
        </Link>
        <button type="submit" className="btn btncontinue con">
          Upload
        </button>
      </div>
    </form>
  );
};

export default UploadVideo;
