import React from "react";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import "../styles/questionAdded.css";
import classes from "../styles/Modal.module.css";

const QuestionAdded = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      modal={classes.modal}
      content={classes.content}
      backdrop={classes.backdrop}
    >
      <div className="qnaddedwrapper">
        <img src="/images/educator/success.png" alt="success" />
        <p>{props.message}</p>
        <Link to={props.path ? props.path : "#"} onClick={props.onClose}>
          <button type="button" className="btn btn-primary okbtn">
            ok
          </button>
        </Link>
      </div>
    </Modal>
  );
};

export default QuestionAdded;
