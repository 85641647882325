import React from "react";
import { localDateFormat } from "../../utils/utils";

const BlogDetailPage = ({ title, description, imgUrl, createdAt }) => {
  return (
    <div className="blogwhole">
      <p className="blogdate">Blog Posted On {localDateFormat(createdAt)} </p>
      <p className="aipara">{title}</p>
      <img
        className="blogimg"
        src={imgUrl || "/images/footer/blogstudent.svg"}
      />
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};

export default BlogDetailPage;

//   <p className="allparablog">
//     Artificial Intelligence is the new world technology to learn the English
//     language. It plays a game-changer role in enhancing communication
//     skills. Our tools are used by many universities and schools to enhance
//     their programs.
//   </p>

//   <p className="allparablog">
//     Hive steps is the first cloud-based AI tool to improve your reading,
//     writing, listening, and speaking skills. The user interface is simple to
//     understand. It gives a real-time experience and feedback to the user. It
//     is also helpful in clearing exams like IELTS, SAT, GRE, ACT, etc. This
//     course is also for school-going students. Our main motto is to enhance
//     communication skills.
//   </p>

//   <p className="allparablogstrong"> What are the USPs of hive steps? </p>

//   <p className="allparablog">
//     Hive steps hold many advantageous features for enhancing communication
//     skills. Both educators and students benefitted from these features.
//   </p>

//   <ul>
//     <li className="allparablog">
//       Through AI assessment tests generate global ranking
//     </li>
//     <li className="allparablog">Specially designed courses </li>
//     <li className="allparablog"> Effective AI cloud-based LSRW model </li>
//     <li className="allparablog">
//       Training and workshops are organized for educators{" "}
//     </li>
//     <li className="allparablog"> User-friendly interface</li>
//   </ul>
//   <p className="allparablogstrong">
//     How AI is efficient for educators and students?
//   </p>

//   <p className="allparablog">
//     Artificial intelligence allures educators and students towards them. The
//     entire education industry is ready to replace traditional methods of
//     learning language with AI platforms. The student will get their
//     assessment report instantly. Candidates will get an overview of their
//     skills and knowledge and help in understanding the areas of improvement.{" "}
//   </p>

//   <p className="allparablogstrong">
//     {" "}
//     What is the procedure of AI-based learning?
//   </p>
//   <p className="allparablog">
//     We are sharing the journey of AI-based learning tools
//   </p>

//   <ul>
//     <li className="allparablog">
//       {" "}
//       In the very first step, you have to log in with your credentials.
//     </li>
//     <li className="allparablog">Book the assessment </li>
//     <li className="allparablog">Add questions and participants</li>
//     <li className="allparablog"> Check score </li>
//     <li className="allparablog"> Do the needful action</li>
//   </ul>
//   <p className="allparablog">
//     Interested candidates also do the practice before giving the assessment
//     test. Students will get the score and also receive the certificate of
//     completion training. AI tools assist in enhancing communication skills
//     for interested candidates.
//   </p>
//   <p className="allparablog"> How to connect with hive steps?</p>
//   <p className="allparablog">
//     {" "}
//     We are open to connecting with Universities, schools, and other
//     educational institutions. Everyone can approach us in various ways.
//   </p>

//   <ul>
//     <li className="allparablog"> Via call</li>
//     <li className="allparablog"> Via email </li>
//     <li className="allparablog">Via text</li>
//   </ul>
//   <p className="allparablog">
//     You can also connect with us on social media platforms like Instagram,
//     Twitter, Facebook, and LinkedIn. Our sales team is always ready to
//     establish a bond with you. They clear all the questions and doubts
//     promptly.
//   </p>
