import React, { memo } from "react";
import { useState } from "react";

const QuizCard = ({
  qnNumber,
  question,
  choose,
  options,
  correct_answers,
  setShowQuizNo,
  showQuizNo,
  arrayLength,
  score,
  setScore,
}) => {
  const [chosen, setChosen] = useState("");
  const [checked, setChecked] = useState(false);

  const getScore = () => {
    if (correct_answers[0] === chosen) {
      setScore({ ...score, correctAnswers: score.correctAnswers + 1 });
    } else if (correct_answers[0] !== chosen) {
      setScore({ ...score, incorrectAnswers: score.incorrectAnswers + 1 });
    }
  };

  const handleClick = (index) => {
    setChosen(options[index]);
  };

  const borderColor = (index) => {
    // if (checked) {
    //   return borderColor(index);
    // }
    if (
      options[index] === correct_answers[0] &&
      checked &&
      chosen === options[index]
    ) {
      return "#98D8AA";
    } else if (
      options[index] !== correct_answers[0] &&
      checked &&
      chosen === options[index]
    ) {
      return "#F15A59";
    } else if (options[index] === correct_answers[0] && checked) {
      return "#98D8AA";
    } else if (chosen === options[index]) {
      return "#FFC008";
    } else {
      return "#D4D4D4";
    }
  };

  return (
    <div className="quizwarpcon" key={qnNumber}>
      <div className="qnquizwrap">
        <p className="qnnumber">{qnNumber}</p>
        <p>{question}</p>
      </div>
      <div className="qnask">
        <p className="qnparaquiz">{choose}</p>
        <div className="allqn">
          {(score.correctAnswers !== 0 || score.incorrectAnswers !== 0) && (
            <div className="score">
              <p>
                Correct Answers -{" "}
                <span className="correct">{score.correctAnswers}</span>
              </p>

              <p>
                Incorrect Answers -{" "}
                <span className="incorrect">{score.incorrectAnswers}</span>
              </p>
            </div>
          )}
          <div className="options-box">
            {/* option 1 */}
            <div
              className="option"
              onClick={() => !checked && handleClick(0)}
              style={{ borderColor: borderColor(0) }}
            >
              <p>{(options && options[0]) || "NA"}</p>
            </div>
            {/* option 2 */}
            <div
              className="option"
              onClick={() => !checked && handleClick(1)}
              style={{ borderColor: borderColor(1) }}
            >
              <p>{(options && options[1]) || "NA"}</p>
            </div>
            {/* option 3 */}
            <div
              className="option"
              onClick={() => !checked && handleClick(2)}
              style={{ borderColor: borderColor(2) }}
            >
              <p>{(options && options[2]) || "NA"}</p>
            </div>
            {/* option 3 */}
            <div
              className="option"
              onClick={() => !checked && handleClick(3)}
              style={{ borderColor: borderColor(3) }}
            >
              <p>{(options && options[3]) || "NA"}</p>
            </div>
          </div>
          <div className="btns">
            {checked ? (
              <button
                className="continue"
                hidden={showQuizNo >= arrayLength - 1}
                onClick={() =>
                  showQuizNo < arrayLength - 1 && setShowQuizNo(showQuizNo + 1)
                }
              >
                Continue
              </button>
            ) : (
              <button
                className="check"
                disabled={!chosen}
                onClick={() => {
                  chosen && setChecked(true);
                  getScore();
                }}
              >
                Check
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(QuizCard);

{
  /* <div className="quizopt">
    <div>
      <input className="qnparaquiz" type="radio" name="option" />
      <label className="qnparaquiz" for="To minimize labour turover">
        {(options && options[0]) || "NA"}
      </label>
    </div>
    <div>
      <input className="qnparaquiz" type="radio" name="option" />
      <label
        className="qnparaquiz"
        for="To inform about company's progress"
      >
        {(options && options[1]) || "NA"}
      </label>
    </div>
  </div>
  <div className="quizopt">
    <div>
      <input className="qnparaquiz" type="radio" name="option" />
      <label className="qnparaquiz" for="To motivate employee">
        {(options && options[2]) || "NA"}
      </label>
    </div>
    <div className="secquiz">
      <input className="qnparaquiz" type="radio" name="option" />
      <label className="qnparaquiz" for="All the above">
        {(options && options[3]) || "NA"}
      </label>
    </div>
  </div> */
}
