import { useFormik } from "formik";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAssessmentAction } from "../../redux/slice/assessment/createAssessmentSlice";
import { assessmentSchema } from "../../utils/schema";
import { getToken } from "../../utils/utils";
import { IoClose } from "react-icons/io5";

const CreateAssessmentModal = ({ setShowPop, showPop }) => {
  const token = getToken();
  const { getUser } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        name: "",
        aiLevel: "",
        responseType: "",
        purpose: "",
      },
      validationSchema: assessmentSchema,
      onSubmit: (values, action) => {
        const newValues = {
          assessmentName: values.name,
          assessmentResponseType: values.responseType,
          assessmentPurpose: values.purpose,
          assessmentAILevel: values.aiLevel,
          assessmentStatusType: "1",
          studentId: `${getUser.userData?.id}`,
          status: "1",
        };
        console.log(newValues);

        dispatch(createAssessmentAction({ token, values: newValues }));
        if (
          !errors.name &&
          !errors.responseType &&
          !errors.purpose &&
          !errors.aiLevel &&
          !errors.statusType &&
          !errors.statusType
        ) {
          setShowPop(!showPop);
        }
        action.resetForm();
      },
    });
  return (
    <Fragment>
      <div className="space-between">
        <h2>Create Assessment</h2>
        <span className="closeBtn" onClick={() => setShowPop(!showPop)}>
          <IoClose />
        </span>
      </div>
      <form className="row custom-grid" onSubmit={handleSubmit}>
        <div className="col-md-6">
          {/* Name */}
          <label htmlFor="name">
            Assessment Name<span>*</span>
          </label>
          <input
            type="text"
            id="name"
            placeholder="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex="1"
          />
          {errors.name && touched.name ? (
            <div className="invalid-msg">{errors.name}</div>
          ) : null}
        </div>

        {/* Assessment response type */}
        <div className="col-md-6">
          <label htmlFor="assessment">
            Assessment Response type<span>*</span>
          </label>
          <select
            type="text"
            id="assessment"
            name="responseType"
            value={values.responseType}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex="2"
          >
            <option>Select Assessment Response type</option>
            <option value="1">Video</option>
          </select>
          {errors.responseType && touched.responseType ? (
            <div className="invalid-msg">{errors.responseType}</div>
          ) : null}
        </div>

        {/* Purpose */}
        <div className="col-md-6">
          <label htmlFor="purpose">
            Purpose<span>*</span>{" "}
          </label>
          <select
            type="text"
            id="purpose"
            name="purpose"
            value={values.purpose}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex="3"
          >
            <option>Select Purpose</option>
            <option value="1">Speaking</option>
          </select>
          {errors.purpose && touched.purpose ? (
            <div className="invalid-msg">{errors.purpose}</div>
          ) : null}
        </div>

        {/* AI Level */}
        <div className="col-md-6">
          <label htmlFor="ai-level">
            AI Level<span>*</span>
          </label>
          <select
            type="text"
            id="ai-level"
            name="aiLevel"
            value={values.aiLevel}
            onChange={handleChange}
            onBlur={handleBlur}
            tabIndex="4"
          >
            <option>Select Level</option>
            <option value="1">Basic</option>
            <option value="2">Intermediate</option>
            <option value="3">Advance</option>
          </select>

          {errors.aiLevel && touched.aiLevel ? (
            <div className="invalid-msg">{errors.aiLevel}</div>
          ) : null}
        </div>

        {/* AI Level */}
        {/* <div className="col-md-6">
          <label htmlFor="statusType">
            Assessment Status Type<span>*</span>
          </label>
          <select
            type="text"
            id="statusType"
            name="statusType"
            value={values.statusType}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Select Assessment Status Type</option>
            <option value="1">Self</option>
            <option value="2">Assigned</option>
            <option value="3">Completed</option>
            <option value="4">All</option>
          </select>

          {errors.statusType && touched.statusType ? (
            <div className="invalid-msg">{errors.statusType}</div>
          ) : null}
        </div> */}

        {/* Submit */}
        <div className="submit-btn">
          <input type="submit" value="Submit" tabIndex="5" />
        </div>
      </form>
    </Fragment>
  );
};

export default CreateAssessmentModal;
