import React, { useState } from "react";
import "../../styles/rating.css";
import Global from "./Global";
import Country from "./Country";
import Local from "./Local";

const RatingIndex = ({ report }) => {
  const [tab, setTab] = useState(0);

  return (
    <div className="headerbox">
      <div className="headerinner"></div>
      <div className="outerboxhead">
        <div className="headinnerwraprating">
          <div className="reccon">
            <p className="headpara">Comparative Score</p>
          </div>
          <div className="compscorewrap">
            <div className="mapwrap">
              <iframe
                style={{ width: "100%", height: "100%", loading: "lazy" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28016.986418041906!2d77.37098950000001!3d28.626066400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1680765294681!5m2!1sen!2sin"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="ratevidtwrap">
              <div className="create-rating-container">
                <div className="rating-tab">
                  <button
                    type="button"
                    onClick={() => setTab(0)}
                    className={`tab ${tab === 0 && "active"}`}
                  >
                    Global
                  </button>

                  <button
                    type="button"
                    onClick={() => setTab(1)}
                    className={`tab ${tab === 1 && "active"}`}
                  >
                    Country
                  </button>
                  <button
                    type="button"
                    onClick={() => setTab(2)}
                    className={`tab ${tab === 2 && "active"}`}
                  >
                    Local
                  </button>
                </div>
              </div>
              {tab === 0 && <Global report={report} />}
              {tab === 1 && <Country report={report} />}
              {tab === 2 && <Local report={report} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingIndex;
