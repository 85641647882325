import React from "react";

const AccordianComponent = (props) => {
  const left = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        fill="#fff"
      />
    </svg>
  );
  const right = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        fill="#fff"
      />
    </svg>
  );
  return (
    <>
      <div
        className={`accordian-bar ${
          props.openAccordian === props.oaId ? "accordian-bar-active" : ""
        }`}
        onClick={props.click}
        role="button"
      >
        <h2>{props.name}</h2>
      </div>
      {props.openAccordian === props.oaId ? (
        <div className="accordian-box">
          {props.children}
          <div
            className={`d-flex align-items-center justify-content-between accBtns ${props.withQa}`}
          >
            <span
              role="button"
              class={`acc-arrows ${
                props.openAccordian === 0 ? "visHidden" : ""
              }`}
              onClick={props.openPrev}
            >
              {left}
            </span>
            <span
              role="button"
              class={`acc-arrows ${
                props.openAccordian === props.finalAccordian ? "visHidden" : ""
              }`}
              onClick={props.openNext}
            >
              {right}
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AccordianComponent;
