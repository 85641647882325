import React from "react";
import classes from "../../styles/ContactModal.module.css";
import Modal from "../Modal";
import { useFormik } from "formik";
import { contactUsSchema } from "../../utils/schema";
import { useDispatch } from "react-redux";
import { contactUsAction } from "../../redux/slice/common/contactUsSlice";

const ContactModal = (props) => {
  const dispatch = useDispatch();
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        fullName: "",
        email: "",
        mnumber: "",
        message: "",
      },
      validationSchema: contactUsSchema,
      onSubmit: (values, action) => {
        const newValues = {
          ...values,
          fullname: values.fullName,
          phoneNumber: values.mnumber,
        };
        props.onClose();
        dispatch(contactUsAction(newValues));
      },
    });
  return (
    <>
      <Modal
        onClose={props.onClose}
        modal={classes.modal}
        content={classes.content}
        backdrop={classes.backdrop}
      >
        <form className={classes.contactUs} onSubmit={handleSubmit}>
          <div className={classes.heading}>
            <h2>Contact Us</h2>{" "}
            <div className={classes.closebtnContainer}>
              <button className={classes.close} onClick={props.onClose}>
                X
              </button>
            </div>
          </div>
          <div className={classes.inputField}>
            <label htmlFor="name">
              Full name<span>*</span>
            </label>
            <input
              type="text"
              name="fullName"
              id="name"
              placeholder="Full name"
              value={values.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.fullName && touched.fullName ? (
              <div className="invalid-msg">{errors.fullName}</div>
            ) : null}
          </div>
          <div className={classes.inputField}>
            <label htmlFor="email">
              Email<span>*</span>
            </label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <div className="invalid-msg">{errors.email}</div>
            ) : null}
          </div>
          <div className={classes.inputField}>
            <label htmlFor="mnumber">
              Mobile number<span>*</span>
            </label>
            <input
              type="text"
              name="mnumber"
              id="mnumber"
              placeholder="Mobile number"
              value={values.mnumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.mnumber && touched.mnumber ? (
              <div className="invalid-msg">{errors.mnumber}</div>
            ) : null}
          </div>
          <div className={classes.inputField}>
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              name="message"
              id="message"
              placeholder="Message"
              value={values.message}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className={classes.submit}>
            Submit
          </button>
        </form>
      </Modal>
    </>
  );
};

export default ContactModal;
