import { useFormik } from "formik";
import React, { useState } from "react";

const AssessmentType = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const [asmtType, setAsmtType] = useState(1);

  return (
    <div className="asmt-type-box space-between" role="group">
      <div
        className={asmtType === 1 ? "asmt-type active" : "asmt-type inactive"}
        onClick={() => {
          setAsmtType(1);
          setFieldValue("assessmentType", 1);
        }}
      >
        <label>
          <span>Assessment</span>
          <input type="radio" name="assessmentType" value="1" />
          <div className="img center">
            <img src="/images/educator/fileicon.png" alt="file" />
          </div>
        </label>
      </div>

      <div
        className={asmtType === 2 ? "asmt-type active" : "asmt-type inactive"}
        onClick={() => {
          setAsmtType(2);
          setFieldValue("assessmentType", 2);
        }}
      >
        <label>
          <span>
            Practice
            <span>Assessment</span>
          </span>
          <div className="img center">
            <img src="/images/educator/laptopicon.png" alt="assessmentType" />
          </div>{" "}
          <input type="radio" value="2" name="assessmentType" />
        </label>
      </div>
    </div>
  );
};

export default AssessmentType;
