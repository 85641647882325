import React from "react";
import { Link } from "react-router-dom";
import VideoSlider from "./VideoSlider";
import CalendarComponent from "./Calendar";

function RightBar() {
  return (
    <aside className="right-bar">
      {/* <div className="right-bar-section">
        <Link to="#" className="btnfull yellow-btn">
          Scheduled Sessions
        </Link>
      </div> */}
      <div className="right-bar-section shadow-box">
        {/* <img
          src="/images/calendar.png"
          alt="Calendar"
          style={{ width: "100%" }}
        /> */}
        <CalendarComponent />
      </div>
      <VideoSlider />

      <div className="right-bar-section shadow-box">
        <img
          src="/images/gallery.png"
          alt="Gallery"
          style={{ width: "100%" }}
        />
        <Link className="btnfull mt-3" to="/gallery">
          Gallery
        </Link>
      </div>
    </aside>
  );
}

export default RightBar;
