import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container>
        <Link className="navbar-brand navlogo" to="/">
          <img className="logoimg" src="/images/sk.png" />
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="my-lg-0">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="/#aboutSection" className="nav-link">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="/#ourValuesSection" className="nav-link">
                  Values
                </a>
              </li>
              <li className="nav-item">
                <a href="/#journeySection" className="nav-link">
                  Journey
                </a>
              </li>
              <li className="nav-item">
                <a href="/#offeringSection" className="nav-link">
                  Offerings
                </a>
              </li>
              <li className="nav-item">
                <a href="/#whyUsSection" className="nav-link">
                  Why Us?
                </a>
              </li>
              <li className="nav-item">
                <a href="/#faqSection" className="nav-link">
                  FAQs
                </a>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
