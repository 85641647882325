import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

export default function WeightagePieChartForm({ values }) {
  const option = {
    // color: [
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#1158E2"
    //         },
    //         {
    //             offset: 1,
    //             color: "#42B5F2"
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#BC1FD7",
    //         },
    //         {
    //             offset: 1,
    //             color: "#7F1DD1",
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#E8A618",
    //         },
    //         {
    //             offset: 1,
    //             color: "#EB6B36",
    //         }
    //     ]),
    //     new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         {
    //             offset: 0,
    //             color: "#D131BE",
    //         },
    //         {
    //             offset: 1,
    //             color: "#BF1575",
    //         }
    //     ]),
    // ],
    // title: {
    //   text: 'Referer of a Website',
    //   subtext: 'Fake Data',
    //   left: 'center'
    // },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      center: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: [
          { value: values.intro, name: "Intro" },
          { value: values.mainContext, name: "Main Context" },
          { value: values.environment, name: "Environment" },
          { value: values.outro, name: "Outro" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <div style={{ width: "50%", height: "300px" }}>
      <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
    </div>
  );
}
